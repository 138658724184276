import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface PropertyDetailItemProps extends BoxProps {
  label: string;
  value: string | ReactNode | Element;
}

export const PropertyDetailItem = (props: PropertyDetailItemProps) => {
  const {
    label,
    value,
    fontSize = { base: 'md', sm: 'md' },
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps}>
      <Flex w="full" justifyContent={'space-between'} fontSize={fontSize}>
        <Box fontWeight={500}>{label}</Box>
        <Box>{value}</Box>
      </Flex>
    </Box>
  );
};
