import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  identifier: 'E-mail',
};

export const defaultPlaceholders: FormTexts = {
  identifier: 'Váš E-mail',
};

export const defaultErrorsMessage: UseToastOptions = {
  title: 'Niekde nastala chyba',
  description: 'Prosím kontaktujte nás.',
  status: 'error',
  duration: 6000,
  position: 'top',
  isClosable: true,
};

export const defaultSuccessMessage: UseToastOptions = {
  title: 'Skontrolujte si Vašu emailovú schránku.',
  description: 'Prišiel vám email na obnovu hesla.',
  status: 'success',
  duration: 6000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Obnova hesla',
  loadingText: 'Odosielam',
  colorScheme: 'black',
};
