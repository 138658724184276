import { AiOutlineLogin, AiOutlineUserAdd } from 'react-icons/ai';

export const menuRoutes = {
  home: {
    label: 'Brick Reality',
    href: '/',
  },
  properties: {
    label: 'Ponuka nehnuteľností',
    href: '/ponuka-nehnutelnosti',
  },
  realityService: {
    label: 'Realitný servis',
    href: '/realitny-servis',
  },

  financialService: {
    label: 'Finančný servis',
    href: '/financny-servis',
  },
  news: {
    label: 'Novinky a info',
    href: '/novinky',
  },
  contact: {
    label: 'Kontakt',
    href: '/kontakt',
  },
};

export const ctaRoutes = {
  sell: {
    label: 'Predať / prenajať',
    href: '/predat',
  },
  buy: {
    label: 'Kúpiť si / prenajať si',
    href: '/kupit',
  },
  career: {
    label: 'Kariéra',
    href: '/kariera',
  },
};

export const authRoutes = {
  login: {
    label: 'Prihlásenie',
    href: '/auth/prihlasenie',
    icon: <AiOutlineLogin />,
  },
  register: {
    label: 'Registrácia',
    href: '/auth/registracia',
    icon: <AiOutlineUserAdd />,
  },
  // forgotPassword: {
  //   label: 'Zabudnuté heslo',
  //   href: '/auth/zabudnute-heslor',
  //   icon: <AiOutlineUserAdd />,
  // },
};

export const clientZoneRoutes = {
  licitation: {
    label: 'Licitácia nehnuteľností',
    href: '/licitacia',
  },
  licitationGuide: {
    label: 'Ako licitovať nehnuteľnosť',
    href: '/licitacia/ako-licitovat',
  },
  profile: {
    label: 'Profil',
    href: '/licitacia/profil',
  },
};

export const adminRoutes = {
  listing: {
    label: 'Zoznam licitácií',
    href: '/licitacia/zoznam',
  },
};
