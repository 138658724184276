import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { BsCheckCircle } from 'react-icons/bs';
import {
  ButtonLink,
  ButtonLinkProps,
} from '../../Elements/ButtonLink/ButtonLink';
import { Heading } from '../../Elements/Heading/Heading';

export interface SuccessSectionProps extends BoxProps {
  title: string;
  description?: string;
  cta?: ButtonLinkProps;
}

export const SuccessSection = (props: SuccessSectionProps) => {
  const { title, description, cta, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Flex
        flexDir={{ base: 'column' }}
        gridGap={{ base: 2 }}
        alignItems="center"
        textAlign={'center'}
      >
        <Box fontSize="9xl" mb={6}>
          <BsCheckCircle />
        </Box>
        <Heading alignItems="center" textAlign={'center'}>
          {title}
        </Heading>
        <Box mt={{ base: 4, md: 6 }}>{cta && <ButtonLink {...cta} />}</Box>
      </Flex>
    </Box>
  );
};
