import { Box, BoxProps } from '@chakra-ui/react';
import { FaMapMarkerAlt } from 'react-icons/fa';

export interface MapPinProps extends BoxProps {}

export const MapPin = (props: MapPinProps) => {
  const { ...restOfProps } = props;
  return (
    <Box
      _hover={{
        transform: 'scale(1.05)',
        transition: 'all ease-in-out 300ms',
      }}
      {...restOfProps}
    >
      <Box
        cursor={'pointer'}
        color="black.500"
        fontSize={'4xl'}
        style={{ transform: 'translate(-16px, -36px)' }} // 4xl pin
        // style={{ transform: 'translate(-23px, -46px)' }} // 5xl pin
      >
        <FaMapMarkerAlt />
      </Box>
    </Box>
  );
};
