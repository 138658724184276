import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { Underline } from 'libs/ui-components/src/lib/Elements/Underline/Underline';
import React, { ReactNode, useState } from 'react';

export interface UnderlineButtonProps extends BoxProps {
  children: ReactNode;
  isActive?: boolean;
}

export const UnderlineButton = (props: UnderlineButtonProps) => {
  const { children, isActive = false, ...restOfProps } = props;
  const [mouseOn, setMouseOn] = useState(false);

  return (
    <Box {...restOfProps} cursor="pointer">
      <Stack spacing={0}>
        <Box
          fontSize={{ base: 'sm', xl: 'md' }}
          onMouseEnter={() => setMouseOn(true)}
          onMouseLeave={() => setMouseOn(false)}
        >
          {children}
        </Box>

        <Underline
          w={mouseOn || isActive ? 'full' : 0}
          transition={'all ease-out 300ms'}
        />
      </Stack>
    </Box>
  );
};
