import { useIsRouteActive } from '@brick/utils';
import { Box, BoxProps, Stack } from '@chakra-ui/react';
import Link from 'next/link';
import { ReactNode } from 'react';
import { Underline } from '../../../../Elements/Underline/Underline';

export interface MobileMenuLinkProps extends BoxProps {
  href: string;
  label: string | ReactNode;
}

export const MobileMenuLink = (props: MobileMenuLinkProps) => {
  const { href, label, ...restOfProps } = props;
  const isActive = useIsRouteActive(href);
  

  return (
    <Box {...restOfProps}>
      <Stack spacing={0}>
        <Link href={href}>
          <a>
            <Box
              fontSize={{ base: 'md', sm: 'lg' }}
              textTransform={'uppercase'}
              fontWeight={600}
              py={0.5}
            >
              {label}
            </Box>
          </a>
        </Link>

        <Underline
          w={isActive ? 'full' : 0}
          transition={'all ease-out 300ms'}
        />
      </Stack>
    </Box>
  );
};
