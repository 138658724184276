import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import { default as GoogleMapReact, Props } from 'google-map-react';
import { AgentCard, AgentCardProps } from '../../Elements/AgentCard/AgentCard';
import { BannerProps } from '../../Elements/Banner/Banner';
import { BreadcrumbHeader } from '../../Elements/BreadcrumbHeader/BreadcrumbHeader';
import {
  IframeModal,
  IframeModalProps,
} from '../../Elements/IframeModal/IframeModal';
import { ImageSlider } from '../../Elements/ImageSlider/ImageSlider';
import { Map } from '../../Elements/Map/Map';
import { MapPin } from '../../Elements/MapPin/MapPin';
import { TextWithHeading } from '../../Elements/TextWithHeading/TextWithHeading';
import { Container } from '../../Layout/Container/Container';
import { PrintAndShare } from '../../SmartComponents/PrintAndShareSection/PrintAndShare';
import {
  PropertyDetail,
  PropertyDetailProps,
} from './components/PropertyDetail';

export interface PropertyDetailSectionProps extends BoxProps {
  description?: string;
  title: string;
  propertyDetail: PropertyDetailProps;
  images: string[];
  gMapsEmbedLink?: string;
  agent?: AgentCardProps;
  banner?: BannerProps;
  iframes?: IframeModalProps[];
  showOverlay?: boolean;
  googleMapReactProps?: Props;
}

export const PropertyDetailSection = (props: PropertyDetailSectionProps) => {
  const {
    description,
    title,
    images,
    propertyDetail,
    gMapsEmbedLink,
    agent,
    banner,
    iframes,
    showOverlay,
    googleMapReactProps,
    ...restOfProps
  } = props;

  return (
    <Box {...restOfProps}>
      <BreadcrumbHeader
        subtitle={title}
        mb={{ base: 0, md: 8 }}
        shareTitle="Zdieľajte nehnuteľnosť:"
      />
      <Container>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gridGap={{ base: 6, lg: 10 }}
        >
          {/* CONTENT LEFT */}
          <Flex
            flexDir={'column'}
            w={{ base: 'full', md: '70%' }}
            gridGap={{ base: 8, md: 8 }}
          >
            <ImageSlider
              showOverlay={showOverlay}
              images={images}
              mx={{ base: -4, md: 0 }}
              priority
            />
            <PropertyDetail {...propertyDetail} />
            {description && (
              <TextWithHeading
                content={description}
                heading={{
                  title: 'PODROBNÝ POPIS',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                }}
              />
            )}
            {gMapsEmbedLink ? (
              <Map
                h={{ base: 56, sm: 64, md: 80 }}
                mx={{ base: -4, md: 0 }}
                gMapsEmbedLink={gMapsEmbedLink}
              />
            ) : (
              <Box
                w={{ base: '109%', md: 'full' }}
                h={{ base: 56, sm: 64, md: 80 }}
                mx={{ base: -4, md: 0 }}
              >
                <GoogleMapReact {...googleMapReactProps}>
                  <Box
                    // @ts-ignore
                    lat={googleMapReactProps?.defaultCenter?.lat}
                    lng={googleMapReactProps?.defaultCenter?.lng}
                  >
                    <MapPin />
                  </Box>
                </GoogleMapReact>
              </Box>
            )}
            <PrintAndShare shareTitle="Zdieľajte nehnuteľnosť:" />
          </Flex>
          {/* CONTENT RIGHT */}
          <Stack
            className="no-print"
            flex={1}
            gridGap={{ base: 5 }}
            style={{ pageBreakBefore: 'always' }}
          >
            {agent && <AgentCard {...agent} />}
            {/* {banner && <Banner {...banner} h={400} />} */}
            {iframes && iframes.map((i) => <IframeModal key={i.url} {...i} />)}
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
};
