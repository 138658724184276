import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';
import { Button, ButtonProps } from '../Button/Button';

export interface ButtonLinkProps extends LinkProps {
  buttonProps?: ButtonProps;
  external?: boolean;
  children: ReactNode;
}

export const ButtonLink = (props: ButtonLinkProps) => {
  const { buttonProps, children, external = false, ...restOfProps } = props;

  return (
    <Link {...restOfProps}>
      <a
        target={external ? '_blank' : '_self'}
        rel={external ? 'noopener noreferrer' : ''}
      >
        <Button {...buttonProps}>{children}</Button>
      </a>
    </Link>
  );
};
