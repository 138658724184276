import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  fullName: 'Meno a priezvisko',
  email: 'E-mail',
  phone: 'Mobil',
  district: 'Okres pôsobnosti',
  birthDate: 'Dátum narodenia',
  age: 'Vek',
  education: 'Najvyššie ukončené vzdelanie',
  experience: 'Prax v realitnej činnosti',
};

export const defaultPlaceholders: FormTexts = {
  fullName: 'meno a priezvisko',
  email: 'e-mail',
  phone: 'mobil',
  district: 'vyber zo zoznamu',
  birthDate: 'deň.mesiac.rok',
  age: 'vek',
  education: 'vyber zo zoznamu',
  experience: 'vyber zo zoznamu',
};
export const defaultSuccessMessage: UseToastOptions = {
  title: 'Ďakujeme za tvoju prihlášku.',
  description: 'Na formulár ti odpovieme do 5 pracovných dní .',
  status: 'success',
  duration: 9000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Odoslať formulár',
  loadingText: 'Odosielam',
  colorScheme: 'black',
};
