import {
  Box,
  BoxProps,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Flex,
  forwardRef,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface CheckboxProps extends ChakraCheckboxProps {
  label?: ReactNode;
  labelId?: string;
  info?: string;
  labelProps?: BoxProps;
}

export const Checkbox = forwardRef<CheckboxProps, 'input'>((props, ref) => {
  const { label, labelProps, labelId = 'label-id', ...restOfProps } = props;

  return (
    <Flex
      color="body.500"
      fontWeight={400}
      fontSize={'sm'}
      gridGap={2}
      flexDir={{ base: 'column', md: 'row' }}
    >
      <Flex gridGap={2} color="grey.500" alignItems={'flex-start'} mt={-1}>
        <ChakraCheckbox
          aria-labelledby={labelId}
          colorScheme="black"
          bg="white"
          rounded="md"
          borderColor={'"grey.400"'}
          mt={1.5}
          ref={ref}
          {...restOfProps}
        />
        <Box id={labelId} as="label" pt={1} {...labelProps}>
          {label}
        </Box>
      </Flex>
    </Flex>
  );
});
