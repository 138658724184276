import { Box, BoxProps, Flex, Skeleton } from '@chakra-ui/react';
import { Container } from '../../Layout/Container/Container';
import { TimerNote, TimerNoteProps } from './components/TimerNote';
import { TimerSubtitle, TimerSubtitleProps } from './components/TimerSubtitle';
import { Timer, TimerProps } from './Timer';

export interface TimerSectionProps extends BoxProps {
  timer: TimerProps;
  title?: string;
  subtitle?: TimerSubtitleProps;
  note?: TimerNoteProps;
}

export const TimerSection = (props: TimerSectionProps) => {
  const { timer, title, subtitle, note, ...restOfProps } = props;

  return (
    <Box {...restOfProps} bg="grey.100" w="full">
      <Container py={{ base: 6, md: 10 }}>
        <Flex flexDir="column" alignItems={'center'} gridGap={3}>
          <Box
            textTransform="uppercase"
            fontSize={{ base: 'lg', md: '3xl' }}
            height={{ base: 7, md: 12 }}
            fontWeight={500}
          >
            {title || (
              <Skeleton
                height={{ base: 7, md: 12 }}
                width={{ base: 56, md: 96 }}
              />
            )}
          </Box>
          <Timer {...timer} mb={{ base: 4 }} mt={{ base: 2 }} />
          {subtitle ? (
            <TimerSubtitle {...subtitle} h={{ base: 4, md: 6 }} />
          ) : (
            <Skeleton width={{ base: 56, md: 64 }} h={{ base: 4, md: 6 }} />
          )}
          {note ? (
            <TimerNote {...note} />
          ) : (
            <Skeleton
              width={{ base: 72, sm: 520, md: 600 }}
              h={{ base: 14, md: 8 }}
            />
          )}
        </Flex>
      </Container>
    </Box>
  );
};
