import { CareerForm } from '@brick/forms';
import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { FormProps } from 'libs/forms/src/lib/forms/CareerForm/model/form-model';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';
import { FormInfo, FormInfoProps } from './FormInfo';

// @ts-ignore

export interface CareerFormSectionProps extends BoxProps {
  heading: SectionHeadingProps;
  formInfoItems: FormInfoProps[];
  formProps: FormProps;
}

export const CareerFormSection = (props: CareerFormSectionProps) => {
  const { heading, formInfoItems, formProps, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Container>
        <Stack spacing={{ base: 6, md: 10 }}>
          <SectionHeading
            {...heading}
            alignItems="center"
            textAlign={'center'}
          />
          <CareerForm {...formProps} />
          <Stack spacing={{ base: 6, md: 4 }}>
            {formInfoItems.map((item, i) => (
              <FormInfo {...item} key={i} />
            ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
