import { LinkModel } from '@brick/model';
import { Box, BoxProps, HStack, Stack, Text } from '@chakra-ui/react';
import { ButtonLink } from 'libs/ui-components/src/lib/Elements/ButtonLink/ButtonLink';
import { Heading } from 'libs/ui-components/src/lib/Elements/Heading/Heading';
import Image from 'next/image';

export interface ServiceBannerProps extends BoxProps {
  title: string;
  description?: string;
  image: string;
  cta: LinkModel[];
}

export const ServiceBanner = (props: ServiceBannerProps) => {
  const { title, description, cta, image, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      minH={{
        base: 280,
        sm: 220,
        md: 248,
        lg: 228,
        xl: 240,
      }}
      position="relative"
    >
      {/* BG IMAGE */}
      <Box position="absolute" h="full" w="full">
        <Box position="relative" h="full" w="full">
          <Image
            src={image}
            quality={48}
            alt={'banner'}
            layout="fill"
            objectFit="cover"
          />
        </Box>
      </Box>
      {/* CONTENT */}
      <Stack
        position="absolute"
        color="white"
        justifyContent={'center'}
        h="full"
        py={6}
        px={{ base: 2, md: 4, lg: 4 }}
        textAlign={'center'}
        spacing={2}
      >
        <Heading>{title}</Heading>
        <Text fontSize={{ base: 'sm', sm: 'sm', lg: 'md' }}>{description}</Text>
        <HStack justifyContent={'center'} pt={{ base: 2, md: 1 }}>
          {cta.map((c) => (
            <ButtonLink href={c.href} external={c.external} key={c.href}>
              {c.label}
            </ButtonLink>
          ))}
        </HStack>
      </Stack>
    </Box>
  );
};
