import { Button } from '@brick/ui-components';
import { Flex, Stack, useBreakpointValue, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { GdprAgreement } from '../../components/Agreements/GdprAgreement';
import { FormControlGroup } from '../../components/FormControls/FormControlGroup';
import { validation } from '../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
} from './const/defaults';
import {
  SubscribeFormProps,
  SubscribeFormValues,
} from './model/subscribe-form';

export const SubscribeForm = (props: SubscribeFormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitMutation,
  } = props;

  const toast = useToast();
  const methods = useForm<SubscribeFormValues>({});

  const { mutateAsync, isLoading } = submitMutation();
  const isBase = useBreakpointValue({ base: true, sm: false });

  const onSubmit = async (data: SubscribeFormValues) => {
    const { newsletterGdprAgreement, ...subscribeFormSubmissionInput } = data;
    await mutateAsync({ email: subscribeFormSubmissionInput.email });
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }}>
          <Flex
            flexDir={{ base: 'column', sm: 'row' }}
            gridGap={{ base: 4, lg: 7 }}
          >
            <FormControlGroup
              name="email"
              label={labels.email}
              placeholder={placeholders.email}
              validation={validation.email}
            />
            {isBase && <GdprAgreement name="newsletterGdprAgreement" />}
            <Flex mb={'1px'} justifyContent={'center'}>
              <Button
                type="submit"
                size={'lg'}
                alignSelf={'flex-end'}
                colorScheme={submitButton.colorScheme}
                isLoading={isLoading}
                loadingText={submitButton.loadingText}
              >
                {submitButton.text}
              </Button>
            </Flex>
          </Flex>
          {!isBase && <GdprAgreement name="newsletterGdprAgreement" />}
        </Stack>
      </form>
    </FormProvider>
  );
};
