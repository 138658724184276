import { Box, BoxProps } from '@chakra-ui/react';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { ContentRenderer } from '../ContentRenderer/ContentRenderer';

// @ts-ignore

export interface TextWithHeadingProps extends BoxProps {
  heading: SectionHeadingProps;
  content?: string;
  color?: string;
}

export const TextWithHeading = (props: TextWithHeadingProps) => {
  const { heading, content, color = 'black.500', ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <SectionHeading {...heading} />
      {content && (
        <ContentRenderer color={color} mt={4} fontSize={'sm'} useTickForUl>
          {content}
        </ContentRenderer>
      )}
    </Box>
  );
};
