import {
  forwardRef,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react';
import React from 'react';

export interface SelectProps extends ChakraSelectProps {}

export const Select = forwardRef<SelectProps, 'input'>((props, ref) => {
  return (
    <ChakraSelect
      ref={ref}
      borderColor={'"grey.400"'}
      color="grey.500"
      bg="white"
      h={12}
      errorBorderColor={'red.600'}
      _focus={{ borderColor: 'primary' }}
      _placeholder={{
        color: 'grey.500',
      }}
      {...props}
    ></ChakraSelect>
  );
});
