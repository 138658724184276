import { LinkModel } from '@brick/model';
import { Box, BoxProps, HStack, Stack } from '@chakra-ui/react';
import Link from 'next/link';
import { MdChevronRight } from 'react-icons/md';

export interface FooterColumnProps extends BoxProps {
  title: string;
  items: LinkModel[];
}

export const FooterColumn = (props: FooterColumnProps) => {
  const { title, items, ...restOfProps } = props;

  return (
    <Box {...restOfProps}>
      <Box fontWeight={600} mb={4}>
        {title}
      </Box>
      <Stack ml={-1} mt={0.5} spacing={1.5}>
        {items.map((item, i) => (
          <Link href={item.href} key={i}>
            <a
              target={item.external ? '_blank' : '_self'}
              rel={item.external ? 'noreferrer' : 'same-origin'}
            >
              <HStack
                spacing={0}
                color="black"
                _hover={{
                  color: '#000',
                  transform: 'scale(1.01)',
                  transition: 'all ease-in-out 300ms',
                }}
              >
                <Box>
                  <MdChevronRight />
                </Box>
                <Box>{item.label}</Box>
              </HStack>
            </a>
          </Link>
        ))}
      </Stack>
    </Box>
  );
};
