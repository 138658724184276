import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react-markdown/lib/react-markdown';

export interface HeaderMobileIconProps extends BoxProps {
  icon: ReactNode;
}

export const HeaderMobileIcon = (props: HeaderMobileIconProps) => {
  const { icon, ...restOfProps } = props;
  return (
    <Box
      fontSize={{ base: '3xl', sm: '3xl' }}
      display={{ md: 'none' }}
      {...restOfProps}
    >
      {icon}
    </Box>
  );
};
