import { Button } from '@brick/ui-components';
import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GdprAgreement } from '../../components/Agreements/GdprAgreement';
import { FormControlGroup } from '../../components/FormControls/FormControlGroup';
import { FormControlGroupCheckbox } from '../../components/FormControls/FormControlGroupCheckbox';
import { FormControlGroupSelect } from '../../components/FormControls/FormControlGroupSelect';
import { FormControlGroupTextarea } from '../../components/FormControls/FormControlGroupTextarea';
import { validation } from '../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  defaultSuccessMessage,
  NEW_BUILDING_ID,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const FinancialServiceOrderForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitMutation,
    propertyCategoryOptions,
  } = props;

  const toast = useToast();
  const methods = useForm<FormValues>({});

  const { mutateAsync, isLoading } = submitMutation();

  const onSubmit = async (data: FormValues) => {
    const { isNewBuilding, ...restOfData } = data;
    let submissionInput: any = { ...restOfData };
    if (isNewBuilding) {
      submissionInput['propertyCondition'] = NEW_BUILDING_ID;
    }
    await mutateAsync({ input: submissionInput });
    toast(defaultSuccessMessage);
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }}>
          <SimpleGrid
            columns={{ md: 2, lg: 4 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4 }}
          >
            <FormControlGroup
              name="fullName"
              label={labels.fullName}
              placeholder={placeholders.fullName}
              validation={validation.required}
            />
            <FormControlGroup
              name="email"
              label={labels.email}
              placeholder={placeholders.email}
              validation={validation.email}
            />
            <FormControlGroup
              name="phone"
              label={labels.phone}
              placeholder={placeholders.phone}
              validation={validation.phone}
            />
            <FormControlGroup
              name="mortgage"
              label={labels.mortgage}
              placeholder={placeholders.mortgage}
              validation={validation.streetAndNumber}
            />
            <GridItem colSpan={{ lg: 3 }}>
              <FormControlGroupTextarea
                name="note"
                label={labels.note}
                placeholder={placeholders.note}
                validation={validation.required}
              />
            </GridItem>
            <GridItem colStart={{ lg: 4 }} rowStart={{ base: 5, md: 'auto' }}>
              <Flex gridGap={{ base: 4 }} flexDir={{ base: 'column' }}>
                <FormControlGroupSelect
                  name="propertyCategory"
                  options={propertyCategoryOptions}
                  label={labels.propertyCategory}
                  placeholder={placeholders.propertyCategory}
                  validation={validation.required}
                />
                <FormControlGroupCheckbox
                  labelNode={labels.isNewBuilding}
                  name="isNewBuilding"
                />
              </Flex>
            </GridItem>
          </SimpleGrid>
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            gridGap={{ base: 4, xl: 7 }}
            alignItems={'center'}
          >
            <GdprAgreement order={{ md: 1 }} />
            <Box>
              <Button
                type="submit"
                colorScheme={submitButton.colorScheme}
                isLoading={isLoading}
                loadingText={submitButton.loadingText}
                alignSelf={{ base: 'center', md: 'flex-end' }}
              >
                {submitButton.text}
              </Button>
            </Box>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  );
};
