import { Box, BoxProps, Flex } from '@chakra-ui/react';
import Image from 'next/image';
import { ReactNode } from 'react';

export interface ImageContentCardProps extends BoxProps {
  image: string;
  children: ReactNode | ReactNode[];
  showBorder?: boolean;
  priority?: boolean;
}

export const ImageContentCard = (props: ImageContentCardProps) => {
  const {
    image,
    children,
    showBorder = true,
    priority = false,
    ...restOfProps
  } = props;
  return (
    <Flex
      flexDir={{ md: 'row', base: 'column' }}
      alignItems="center"
      {...restOfProps}
    >
      {image && (
        <Box
          w={{ base: 'full', md: '50%' }}
          border={showBorder ? '1px' : 0}
          borderColor="grey.100"
          h={{ base: 400, sm: 580 }}
          bg="white"
          position="relative"
          boxShadow="xl"
        >
          <Image
            src={image}
            quality={100}
            layout="fill"
            objectFit="cover"
            priority={priority}
            alt="image content"
          />
        </Box>
      )}
      <Box
        width={{ md: '60%', base: '100%' }}
        border="1px"
        borderColor="grey.100"
        borderLeftWidth={6}
        borderLeftColor="primary.500"
        ml={{ md: -14 }}
        zIndex={10}
        bg="white"
        h={'min-content'}
        boxShadow="lg"
      >
        <Box p={{ base: 4, md: 6, lg: 8 }}>{children}</Box>
      </Box>
    </Flex>
  );
};
