import { SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AgreementTextSection } from '../../../components/Agreements/AgreementTextSection';
import { SubmitSection } from '../../../components/SubmitSection/SubmitSection';
import { defaultErrorsToast } from '../../../const/toasts';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  licitationTermsContent,
  vopContent,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const LicitationAgreementForm = memo((props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    defaultValues = {},
    submitFormMutation,
  } = props;

  const methods = useForm<FormValues>({ defaultValues });
  const toast = useToast();
  const { mutateAsync, isLoading } = submitFormMutation();

  const onSubmit = async (input: FormValues) => {
    try {
      const data = await mutateAsync({ input });
    } catch (e) {
      toast(defaultErrorsToast);
      return;
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 6 }}>
          <SimpleGrid columns={1} rowGap={{ base: 8, md: 2, lg: 12 }}>
            <AgreementTextSection
              text={vopContent}
              sectionHeading={{
                title: 'VŠEOBECNÉ OBCHODNÉ PODMIENKY',
                description: 'BRICK reality, s. r. o. (ďalej len „VOP“)',
              }}
              name="vopAgreement"
              label={labels.vopAgreement}
            />
            <AgreementTextSection
              sectionHeading={{
                title: 'LICITAČNÉ PODMIENKY',
                description: ' BRICK reality, s. r. o. (ďalej len „LP“)',
              }}
              text={licitationTermsContent}
              name="licitationAgreement"
              label={labels.licitationAgreement}
            />
          </SimpleGrid>
          <SubmitSection
            submitButton={submitButton}
            isLoading={isLoading}
            indentNote={false}
            buttonWidth={{ base: 'full', md: 'auto' }}
          />
        </Stack>
      </form>
    </FormProvider>
  );
});
