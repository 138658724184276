import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import {
  CollageImages,
  CollageImagesProps,
} from '../../Elements/CollageImages/CollageImages';
import { ContentRenderer } from '../../Elements/ContentRenderer/ContentRenderer';
import { Logo } from '../../Elements/Logo/Logo';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';

// @ts-ignore

export interface CareerSectionProps extends BoxProps {
  images: CollageImagesProps;
  heading: SectionHeadingProps;
  content: string;
}

export const CareerSection = (props: CareerSectionProps) => {
  const { images, heading, content, ...rest } = props;

  const objects = {
    title: (
      <SectionHeading {...heading} alignItems="flex-start" textAlign={'left'} />
    ),
    logo: <Logo logoWidth={200} logoHeight={16} />,
    content: <ContentRenderer pt={4}>{content}</ContentRenderer>,
    images: <CollageImages {...images} />,
  };

  const { ...restOfProps } = props;

  return (
    // @ts-ignore
    <Box {...restOfProps}>
      <Container position="relative">
        {objects.title}
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', lg: 'flex-start' }}
          gridGap={{ base: 0, md: 8 }}
        >
          <Stack
            pt={7}
            spacing={0}
            alignItems={{ base: 'center', md: 'flex-start' }}
          >
            {objects.logo}
            {objects.content}
          </Stack>
          <Box pt={7}>{objects.images}</Box>
        </Flex>
      </Container>
    </Box>
  );
};
