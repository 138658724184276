import { SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlGroup } from '../../../components/FormControls/FormControlGroup';
import { SubmitSection } from '../../../components/SubmitSection/SubmitSection';
import { defaultErrorsToast, settingsSavedToast } from '../../../const/toasts';
import { errors, validation } from '../../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  note,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const ChangePasswordForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    defaultValues = {},
    submitFormMutation,
  } = props;

  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const toast = useToast();
  const { mutateAsync, isLoading } = submitFormMutation();

  const onSubmit = async (input: FormValues) => {
    // check password match
    if (input.newPassword != input.newPasswordConfirmation) {
      methods.setError('newPasswordConfirmation', errors.passwordsDoNotMatch);
      return;
    }

    try {
      const data = await mutateAsync({ input: input });
      if (data?.changePassword) {
        toast({ ...settingsSavedToast, title: 'Zmena hesla úspšená' });
      } else {
        toast({
          ...defaultErrorsToast,
          title: 'Zadali ste nesprávne aktuálne heslo',
          description: 'Skúste to ešte raz.',
        });
      }
    } catch (e) {
      toast(defaultErrorsToast);
      return;
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 6 }}>
          <SimpleGrid
            columns={{ md: 1, lg: 1 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4, md: 2, lg: 4 }}
          >
            <FormControlGroup
              name="currentPassword"
              type="password"
              label={labels.currentPassword}
              placeholder={placeholders.currentPassword}
              // validation={validation.required}
            />
            <FormControlGroup
              name="newPassword"
              type="password"
              label={labels.newPassword}
              placeholder={placeholders.newPassword}
              validation={validation.password}
            />
            <FormControlGroup
              name="newPasswordConfirmation"
              type="password"
              label={labels.newPasswordConfirmation}
              placeholder={placeholders.newPasswordConfirmation}
              validation={validation.required}
            />
          </SimpleGrid>
          <SubmitSection
            submitButton={submitButton}
            isLoading={isLoading}
            note={note}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};
