import { LayoutItems } from '@brick/ui-components';
import { createContext, ReactNode, useContext } from 'react';

export interface LayoutItemsProviderProps {
  children: ReactNode | ReactNode[];
  layoutItems: LayoutItems;
}

const LayoutItemsContext = createContext<LayoutItems>({});

export const useLayoutItems = () => {
  return useContext(LayoutItemsContext);
};

export const LayoutItemsProvider = (props: LayoutItemsProviderProps) => {
  const { children, layoutItems } = props;
  return (
    <LayoutItemsContext.Provider value={layoutItems}>
      {children}
    </LayoutItemsContext.Provider>
  );
};
