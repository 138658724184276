import {
  forwardRef,
  Input as ChakraInput,
  InputGroup,
  InputProps as ChakraInputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface InputProps extends ChakraInputProps {
  rightElement?: ReactNode;
}

export const Input = forwardRef<InputProps, 'input'>((props, ref) => {
  const { rightElement, ...restOfProps } = props;
  return (
    <InputGroup>
      <ChakraInput
        ref={ref}
        borderColor={'grey.400'}
        // color="grey.500"
        bg="white"
        h={12}
        errorBorderColor={'red.600'}
        _focus={{ borderColor: 'primary' }}
        _placeholder={{
          color: 'grey.500',
        }}
        {...restOfProps}
      ></ChakraInput>
      {rightElement && (
        <InputRightElement h="full">{rightElement}</InputRightElement>
      )}
    </InputGroup>
  );
});
