import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';
import Image from 'next/image';

export interface HeroImageProps extends BoxProps {
  titleLines: string[];
  image: string;
}

export const HeroImage = (props: HeroImageProps) => {
  const { titleLines, image, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      minH={{
        base: 220,
        sm: 280,
        md: 340,
        lg: 380,
        xl: 380,
      }}
      position="relative"
    >
      {/* BG IMAGE */}
      <Box position="absolute" h="full" w="full">
        <Image
          src={image}
          layout="fill"
          objectFit="cover"
          quality={100}
          priority
        />
      </Box>
      {/* CONTENT */}
      <Stack
        position="absolute"
        w="full"
        h="full"
        justifyContent={'center'}
        textAlign={'center'}
        color="white"
        py={6}
        px={{ base: 2, md: 4, lg: 6 }}
        spacing={{ base: 0, md: 1, lg: 2 }}
        fontWeight={700}
        textTransform={'uppercase'}
      >
        <Text
          as="h2"
          fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}
        >
          {titleLines[0]}
        </Text>
        <Text
          as="h2"
          fontSize={{ base: 'lg', sm: '3xl', md: '4xl', lg: '5xl' }}
          color="primary.500"
        >
          {titleLines[1]}
        </Text>
        <Text
          as="h2"
          fontSize={{ base: 'lg', sm: '3xl', md: '4xl', lg: '5xl' }}
        >
          {titleLines[2]}
        </Text>
      </Stack>
    </Box>
  );
};
