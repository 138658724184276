import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface TimerDigitProps extends BoxProps {
  children: ReactNode | ReactNode[];
}

export const TimerDigit = (props: TimerDigitProps) => {
  const { children, bg = 'black.500', ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      bg={bg}
      boxShadow={'md'}
      fontSize={{ base: '5xl', sm: '7xl', md: '8xl' }}
      px={{ base: '10px', sm: 3, md: 6, lg: 8 }}
      lineHeight={{ base: 1.3 }}
      fontWeight={{ base: 400, md: 500 }}
      rounded={'md'}
      color="white"
    >
      {children}
    </Box>
  );
};
