import { FormSubmitButton } from '../../../../model/forms';
import { FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  bidAmount: 'Cena vrátane prihodenia',
  authorizationCode: 'Autorizačný kód',
};

export const defaultPlaceholders: FormTexts = {
  bidAmount: 'min. cena ',
  authorizationCode: 'zadajte autorizačný kód',
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Prihodiť',
  loadingText: 'Prihodiť',
  colorScheme: 'black',
};
