import { Box, BoxProps, GridItem, SimpleGrid } from '@chakra-ui/react';
import { IconButton } from '../../Elements/IconButton/IconButton';
import { SvgIcon } from '../../Elements/SvgIcon/SvgIcon';
import { ShareLinks } from '../ShareLinks/ShareLinks';
import pdfIcon from './assets/pdf.svg';
import printerIcon from './assets/printer.svg';
import { PrintAndShareItem } from './PrintAndShareItem';

export interface PrintAndShareProps extends BoxProps {
  shareTitle?: string;
}

export const PrintAndShare = (props: PrintAndShareProps) => {
  const { shareTitle = 'Zdieľanie:', ...restOfProps } = props;
  return (
    <Box className="no-print" {...restOfProps}>
      <SimpleGrid columns={{ base: 2, md: 4 }} rowGap={{ base: 4, md: 0 }}>
        <PrintAndShareItem>
          <IconButton
            label={
              <Box color="black.500" fontWeight={500} pl={1} fontSize="md">
                Stiahnuť PDF
              </Box>
            }
            icon={<SvgIcon src={pdfIcon} size={24} />}
            onClick={() => window.print()}
          />
        </PrintAndShareItem>
        <PrintAndShareItem borderLeft={'none'}>
          <IconButton
            label={
              <Box color="black.500" fontWeight={500} pl={1} fontSize="md">
                Vytlačiť
              </Box>
            }
            icon={<SvgIcon src={printerIcon} size={24} />}
            onClick={() => window.print()}
          />
        </PrintAndShareItem>
        <GridItem colSpan={2}>
          <PrintAndShareItem borderLeft={{ md: 'none' }}>
            <ShareLinks title={shareTitle} />
          </PrintAndShareItem>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
