import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface IconTextProps extends BoxProps {
  icon: ReactNode;
  text: string;
}

export const IconText = (props: IconTextProps) => {
  const { icon, text, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Flex flexDir={'row'}>
        <Box padding={3} pl={0}>
          {icon}
        </Box>
        <Text color="grey.500" fontSize={{ base: 'sm', md: 'md' }} pt={4}>
          {text}
        </Text>
      </Flex>
    </Box>
  );
};
