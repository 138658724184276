import { Box, BoxProps, Stack } from '@chakra-ui/react';

export interface TimerSeparatorProps extends BoxProps {}

export const TimerSeparator = (props: TimerSeparatorProps) => {
  const { ...restOfProps } = props;
  const size = { base: '5px', sm: '8px', md: '9px' };

  return (
    <Box
      {...restOfProps}
      fontSize="8xl"
      color="grey.300"
      pb={{ base: 7, md: 10 }}
    >
      <Stack spacing={{ base: 2, sm: 3, md: 4 }}>
        <Box h={size} w={size} bg="grey.400" rounded="sm"></Box>
        <Box h={size} w={size} bg="grey.400" rounded="sm"></Box>
      </Stack>
    </Box>
  );
};
