import { TimerResult } from 'react-timer-hook';
import { TimerNumberProps } from '../components/TimerNumber';
import { TimerLabels } from '../Timer';

export const mapNumberToDigits = (n: number) => {
  const nStr = String(n);
  const digits = nStr.split('');
  if (digits.length === 1) {
    digits.unshift('0');
  }
  return digits;
};

export const getTimerTotalRemainingSec = (timer: TimerResult) =>
  timer.seconds +
  timer.minutes * 60 +
  timer.hours * 3600 +
  timer.days * 3600 * 24;

export const isTimerAboutToFinish = (
  timer: TimerResult,
  aboutToFinishSec: number
) => {
  const timerTotal = getTimerTotalRemainingSec(timer);
  if (timerTotal === 0) {
    return false;
  }
  if (timerTotal <= aboutToFinishSec) {
    return true;
  }
  return false;
};

export const getTimerItems = (
  timer: TimerResult,
  labels: TimerLabels,
  aboutToFinishSec?: number
) => {
  const isAboutToFinish =
    aboutToFinishSec && isTimerAboutToFinish(timer, aboutToFinishSec);
  const timerItems: TimerNumberProps[] = [
    {
      label: labels.hours,
      digits: mapNumberToDigits(timer.hours),
      digitColor:
        isAboutToFinish && (timer.hours != 0 || timer.days > 0)
          ? 'primary.500'
          : 'black.500',
    },
    {
      label: labels.minutes,
      digits: mapNumberToDigits(timer.minutes),
      digitColor:
        isAboutToFinish && (timer.minutes != 0 || timer.hours > 0)
          ? 'primary.500'
          : 'black.500',
    },
    {
      label: labels.seconds,
      digits: mapNumberToDigits(timer.seconds),
      digitColor:
        isAboutToFinish && (timer.seconds != 0 || timer.minutes > 0)
          ? 'primary.500'
          : 'black.500',
    },
  ];

  if (timer.days != 0) {
    timerItems.unshift({
      label: labels.days,
      digits: mapNumberToDigits(timer.days),
    });
  }

  return timerItems;
};
