import { Box, BoxProps } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';

export interface CollageImagesProps extends BoxProps {
  images: string[];
}

export const CollageImages = React.memo((props: CollageImagesProps) => {
  const { images, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      w={{ base: 348, sm: 438 }}
      h={283}
      position="relative"
    >
      <Box
        width={191}
        height={217}
        zIndex={-1}
        position="absolute"
        top={0}
        left={0}
      >
        <Image src={images[1]} quality={60} layout="fill" objectFit="cover" />
      </Box>
      <Box
        width={272}
        height={181}
        zIndex={-2}
        position="absolute"
        top={53}
        left={{ base: 75, sm: 165 }}
      >
        <Image src={images[2]} quality={60} layout="fill" objectFit="cover" />
      </Box>
      <Box width={200} height={132} position="absolute" top={150} left={30}>
        <Image src={images[0]} quality={60} layout="fill" objectFit="cover" />
      </Box>
    </Box>
  );
});
