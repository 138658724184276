import { Box, BoxProps } from '@chakra-ui/react';
import Image, { ImageProps } from 'next/image';
import { memo } from 'react';
export interface ImageSliderItemProps extends BoxProps {
  imageProps: ImageProps;
  priority?: boolean;
}

export const ImageSliderItem = memo(
  (props: ImageSliderItemProps) => {
    const { imageProps, priority = false, ...restOfProps } = props;

    return (
      <Box
        h={{ base: 220, sm: 340, md: 320, lg: 380, xl: 420 }}
        position={'relative'}
        {...restOfProps}
      >
        <Image
          layout="fill"
          objectFit="cover"
          loading="eager"
          objectPosition={'100% 100%'}
          priority={priority}
          alt="slider image"
          {...imageProps}
        />
      </Box>
    );
  },
  () => false
);
