import { SubscribeForm } from '@brick/forms';
import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import { Logo } from '../../Elements/Logo/Logo';
import { SocialAndContactLinks } from '../../Elements/SocialAndContactLinks/SocialAndContactLinks';
import { Container } from '../Container/Container';
import { useLayoutItems } from '../LayoutItemsProvider';
import { FooterBottom } from './components/FooterBottom';
import { FooterColumn } from './components/FooterColumn';

export interface FooterProps extends BoxProps {}

export const Footer = (props: FooterProps) => {
  const { ...restOfProps } = props;
  const items = useLayoutItems().footer;

  return (
    <Box className="no-print" {...restOfProps}>
      <Box>
        <Box bg="grey.100" py={14}>
          <Container>
            <Flex
              flexDir={{ base: 'column', md: 'row' }}
              gridGap={{ base: 4, md: 10, lg: 20 }}
            >
              {/* FOOTER LEFT */}
              <Stack w={{ md: '40%' }} spacing={2}>
                <Logo />
                <Box fontSize="sm" color="grey.500" maxW={60}>
                  Každý klient je pre nás v prvom rade človek, ktorému chceme
                  zabezpečiť spokojné bývanie a odborné služby.
                </Box>
                {items?.socialLinks && items.contactLinks && (
                  <SocialAndContactLinks
                    socialLinks={items.socialLinks}
                    contactLinks={items.contactLinks}
                    showNote={false}
                  />
                )}
              </Stack>

              {/* FOOTER RIGHT */}
              <Stack spacing={10} mt={4} w="full">
                <Flex
                  w="full"
                  gridGap={{ base: 10, md: 2 }}
                  flexDir={{ base: 'column', md: 'row' }}
                  justifyContent={'space-between'}
                >
                  {items?.aboutLinks && (
                    <FooterColumn
                      title={'BRICK reality'}
                      items={items?.aboutLinks}
                    />
                  )}
                  {items?.importantLinks && (
                    <FooterColumn
                      title={'Dôležité linky'}
                      items={items?.importantLinks}
                    />
                  )}
                  {items?.documentLinks && (
                    <FooterColumn
                      title={'Dokumenty na stiahnutie'}
                      items={items?.documentLinks}
                    />
                  )}
                </Flex>
                <SubscribeForm submitMutation={items?.subscribeFormMutation} />
              </Stack>
            </Flex>
          </Container>
        </Box>
      </Box>

      <FooterBottom />
    </Box>
  );
};
