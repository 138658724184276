import { Box, BoxProps, HStack, Stack } from '@chakra-ui/react';
import { TimerDigit } from './TimerDigit';

export interface TimerNumberProps extends BoxProps {
  digits: Array<number | string>;
  label?: string;
  digitColor?: string;
}

export const TimerNumber = (props: TimerNumberProps) => {
  const { digits, label, digitColor = 'black.500', ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Stack alignItems={'center'} spacing={{ base: 1.5, md: 3 }}>
        <HStack spacing={{ base: 1, sm: 2 }}>
          {digits.map((d, i) => (
            <TimerDigit key={i} bg={digitColor}>
              {d}
            </TimerDigit>
          ))}
        </HStack>
        {label && (
          <Box
            textTransform={'uppercase'}
            fontSize={{ base: 'sm', sm: 'md', md: 'xl' }}
            fontWeight={{ base: 500, sm: 600 }}
            color="black.500"
          >
            {label}
          </Box>
        )}
      </Stack>
    </Box>
  );
};
