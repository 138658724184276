import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import dot from 'dot-object';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Textarea, TextareaProps } from '../Inputs/Textarea';

export interface FormControlGroupTextareaProps extends FormControlProps {
  label?: string;
  placeholder?: string;
  validation?: any;
  labelProps?: FormLabelProps;
  textareaProps?: TextareaProps;
  name: string;
}

export const FormControlGroupTextarea = (
  props: FormControlGroupTextareaProps
) => {
  const { label, placeholder, validation, name, labelProps, textareaProps } =
    props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl {...props} isInvalid={dot.pick(name, errors)}>
      {label && (
        <FormLabel
          htmlFor={name}
          color="grey.500"
          fontSize="sm"
          fontWeight={400}
          mb={1}
          {...labelProps}
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        placeholder={`${validation?.required?.value && '*'}${placeholder}`}
        {...textareaProps}
        {...register(name, validation || {})}
      />
      <FormErrorMessage color="red.600">
        {dot.pick(name, errors)?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
