import { Box, BoxProps, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface IconItemProps extends BoxProps {
  icon?: ReactNode | string;
  label: string;
}

export const IconItem = (props: IconItemProps) => {
  const { icon = '✓', label, fontSize = 'sm', ...restOfProps } = props;
  return (
    <Box {...restOfProps} fontSize={fontSize}>
      <HStack spacing={1}>
        <Box fontSize={'md'}>{icon}</Box>
        <Box>{label}</Box>
      </HStack>
    </Box>
  );
};
