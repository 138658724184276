import { Box, BoxProps, HStack } from '@chakra-ui/react';
import areaIcon from './assets/area.svg';
import docIcon from './assets/doc.svg';
import doorIcon from './assets/door.svg';
import mapIcon from './assets/map.svg';
import { PropertyAttribute } from './PropertyAtribute';

export interface PropertyAttributesProps extends BoxProps {
  area: string;
  rooms: string;
  locality: string;
  offer: string;
  iconSize?: number;
}

export const PropertyAttributes = (props: PropertyAttributesProps) => {
  const { area, rooms, locality, offer, iconSize = 48, ...restOfProps } = props;
  const attributeIconMapItems = [
    {
      icon: areaIcon,
      label: area,
    },
    {
      icon: doorIcon,
      label: rooms,
    },
    {
      icon: mapIcon,
      label: locality,
    },
    {
      icon: docIcon,
      label: offer,
    },
  ];

  return (
    <Box {...restOfProps}>
      <HStack spacing={0}>
        {attributeIconMapItems.map((item, i) => (
          <PropertyAttribute {...item} key={i} iconSize={iconSize} />
        ))}
      </HStack>
    </Box>
  );
};
