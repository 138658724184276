import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import {
  ContactCtaHeading,
  ContactCtaHeadingProps,
} from '../../Elements/ContactCtaHeading/ContactCtaHeading';
import { Container } from '../../Layout/Container/Container';

export interface ContactCtaHeadingSectionProps extends BoxProps {
  contactCtaHeading: ContactCtaHeadingProps;
}

export const ContactCtaHeadingSection = (
  props: ContactCtaHeadingSectionProps
) => {
  const { contactCtaHeading, ...restOfProps } = props;
  return (
    <Box {...restOfProps} >
      <Container>
        <ContactCtaHeading {...contactCtaHeading} />
      </Container>
    </Box>
  );
};
