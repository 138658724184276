import { ConsultationForm, Option } from '@brick/forms';
import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import {
  ContactCtaHeading,
  ContactCtaHeadingProps,
} from '../../Elements/ContactCtaHeading/ContactCtaHeading';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';
import { PerkCardProps } from '../PerksSection/PerkCard';
import { PerksSlider } from '../PerksSection/PerksSlider';

// @ts-ignore

export interface ConsultationFormSectionProps extends BoxProps {
  heading: SectionHeadingProps;
  perks: PerkCardProps[];
  contactCtaHeading: ContactCtaHeadingProps;
  submitFormMutation: any;
  actionOptions: Option[];
}

export const ConsultationFormSection = (
  props: ConsultationFormSectionProps
) => {
  const {
    heading,
    perks,
    contactCtaHeading,
    submitFormMutation,
    actionOptions,
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps}>
      <Container>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gridGap={{ base: 8, md: 4, lg: 6, xl: 8 }}
        >
          {/* LEFT */}
          <Stack w={{ md: '60%' }}>
            <SectionHeading
              {...heading}
              alignItems={{ base: 'center', md: 'flex-start' }}
              textAlign={{ base: 'center', md: 'left' }}
            />
            <PerksSlider
              perks={perks}
              settings={{
                slidesToShow: 2,
                responsive: [
                  {
                    breakpoint: 1040,
                    settings: {
                      slidesToShow: 1,
                      dots: true,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 2,
                      dots: true,
                    },
                  },
                  {
                    breakpoint: 560,
                    settings: {
                      slidesToShow: 1,
                      dots: true,
                    },
                  },
                ],
              }}
            />
            <ContactCtaHeading {...contactCtaHeading} />
          </Stack>
          {/* RIGHT */}
          <Box
            bg="primary.500"
            rounded={{ base: 'none', md: 'lg' }}
            mx={{ base: -4, md: 0 }}
          >
            <Box p={{ base: 7, md: 5, lg: 7 }}>
              <Stack
                alignItems={'center'}
                spacing={0}
                fontSize={'xl'}
                fontWeight={600}
                mb={4}
                textAlign="center"
              >
                <Box color="black.500" mb={-1}>
                  Chcete predať alebo prenajať?
                </Box>
                <Box color="white">Dohodnite si konzultáciu</Box>
              </Stack>
              <ConsultationForm
                submitFormMutation={submitFormMutation}
                actionOptions={actionOptions}
              />
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
