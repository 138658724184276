import {
  BoxProps,
  FormControl,
  FormControlProps,
  FormErrorMessage,
} from '@chakra-ui/react';
import dot from 'dot-object';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, CheckboxProps } from '../Inputs/Checkbox';

export interface FormControlGroupCheckboxProps extends FormControlProps {
  labelNode: ReactNode;
  validation?: any;
  labelProps?: BoxProps;
  checkBoxProps?: CheckboxProps;
  labelId?: string;
  name: string;
}

export const FormControlGroupCheckbox = (
  props: FormControlGroupCheckboxProps
) => {
  const { labelNode, validation, name, labelProps, checkBoxProps, labelId } =
    props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl isInvalid={dot.pick(name, errors)}>
      <Checkbox
        label={labelNode}
        labelId={labelId || name}
        labelProps={labelProps}
        {...checkBoxProps}
        {...register(name, validation || {})}
      />
      <FormErrorMessage color="red.600">
        {dot.pick(name, errors)?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
