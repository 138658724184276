import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface HeadingSecondaryProps extends BoxProps {
  children: ReactNode;
}

export const HeadingSecondary = (props: HeadingSecondaryProps) => {
  const { children, ...restOfProps } = props;
  return (
    <Box fontWeight={500} fontSize={{ base: 'md', sm: 'lg' }} {...restOfProps}>
      {children}
    </Box>
  );
};
