import { Button } from '@brick/ui-components';
import { SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GdprAgreement } from '../../components/Agreements/GdprAgreement';
import { FormControlGroup } from '../../components/FormControls/FormControlGroup';
import { FormControlGroupTextarea } from '../../components/FormControls/FormControlGroupTextarea';
import { validation } from '../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  defaultSuccessMessage,
} from './const/defaults';
import { ContactFormProps, ContactFormValues } from './model/contact-form';

export const ContactForm = (props: ContactFormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitMutation,
  } = props;

  const toast = useToast();
  const methods = useForm<ContactFormValues>({});

  const { mutateAsync, isLoading } = submitMutation();

  const onSubmit = async (data: ContactFormValues) => {
    await mutateAsync({ input: data });
    toast(defaultSuccessMessage);
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }}>
          <SimpleGrid columns={{ md: 1 }} gap={{ base: 4, md: 4 }}>
            <FormControlGroup
              name="fullName"
              label={labels.fullName}
              placeholder={placeholders.fullName}
              validation={validation.required}
            />
            {/* <FormControlGroup
              name="phone"
              label={labels.phone}
              placeholder={placeholders.phone}
              validation={validation.phone}
            /> */}
            <FormControlGroup
              name="email"
              label={labels.email}
              placeholder={placeholders.email}
              validation={validation.email}
            />

            <FormControlGroup
              name="subject"
              label={labels.subject}
              placeholder={placeholders.subject}
              validation={validation.required}
            />
          </SimpleGrid>
          <FormControlGroupTextarea
            name="message"
            label={labels.message}
            placeholder={placeholders.message}
            validation={validation.required}
          />
          <Stack spacing={{ base: 4, md: 3 }}>
            <GdprAgreement inline={false} />
            <Button
              type="submit"
              colorScheme={submitButton.colorScheme}
              isLoading={isLoading}
              loadingText={submitButton.loadingText}
              alignSelf={{ base: 'center', md: 'flex-end' }}
            >
              {submitButton.text}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
