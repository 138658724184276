import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import dot from 'dot-object';
import { useFormContext } from 'react-hook-form';
import { Input, InputProps } from '../Inputs/Input';

export interface FormControlGroupProps extends FormControlProps {
  label?: string;
  placeholder?: string;
  type?: string;
  validation?: any;
  labelProps?: FormLabelProps;
  inputProps?: InputProps;
  name: string;
}

export const FormControlGroup = (props: FormControlGroupProps) => {
  const {
    label,
    placeholder,
    validation,
    name,
    type = 'text',
    inputProps,
    labelProps,
    ...restOfProps
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl {...restOfProps} isInvalid={dot.pick(name, errors)}>
      {label && (
        <FormLabel
          htmlFor={name}
          color="grey.500"
          fontSize="sm"
          fontWeight={400}
          mb={1}
          {...labelProps}
        >
          {label}
        </FormLabel>
      )}
      <Input
        type={type}
        placeholder={`${validation?.required?.value ? '*' : ''}${placeholder}`}
        {...inputProps}
        {...register(name, validation || {})}
      />
      <FormErrorMessage color="red.600">
        {dot.pick(name, errors)?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
