import { Box, SimpleGrid } from '@chakra-ui/react';
import {
  MortgageCalculatorResultItem,
  MortgageCalculatorResultItemProps,
} from './MortgageCalculatorResultsItem';

export interface MortgageCalculatorResultsProps {
  results: MortgageCalculatorResultItemProps[];
}

export const MortgageCalculatorResults = (
  props: MortgageCalculatorResultsProps
) => {
  const { results, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <SimpleGrid
        w="full"
        columns={{ base: 2, sm: 3, lg: 6 }}
        rowGap={{ base: 4 }}
      >
        {results.map((r, i) => (
          <MortgageCalculatorResultItem {...r} divider />
        ))}
      </SimpleGrid>
    </Box>
  );
};
