import { useRouter } from 'next/router';

export const useSocialShareLinks = (domain = 'https://brickreality.sk') => {
  const router = useRouter();
  const route = router.asPath;
  const url = `${'https://brickreality.sk'}${route}`;

  return {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    twitter: `https://twitter.com/intent/tweet?url=${url}`,
    google: `https://plus.google.com/share?url=h${url}`,
    mail: `mailto:?subject=Brick reality&body=Inzerát z portálu Brick REALITY: ${url}`,
  };
};
