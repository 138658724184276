import { Box, BoxProps, Flex, HStack, Skeleton, Stack } from '@chakra-ui/react';

export interface PropertyAgentBannerProps extends BoxProps {}

export const PropertyAgentBannerSkeleton = (
  props: PropertyAgentBannerProps
) => {
  const { ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      _hover={{ boxShadow: 'xl', transition: 'all ease-in-out 100ms' }}
      cursor="pointer"
      boxShadow={'md'}
      py={{ base: 4, md: 6 }}
      px={{ base: 4, md: 8 }}
      borderWidth={'1px'}
      borderColor={'grey.100'}
    >
      <HStack
        flexDir={{ base: 'column', md: 'row' }}
        gridGap={{ base: 4, md: 6 }}
      >
        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
          gridGap={{ base: 4, md: 6 }}
          flex={1}
        >
          <Skeleton
            bg="grey.500"
            position="relative"
            height={{ base: 44, sm: 64 }}
            w={{ base: 'full', md: '36%' }}
          />
          <Box flex={1}>
            <Flex flexDir="column" justifyContent={'space-between'} h="full">
              <Skeleton height={10} />
              <Skeleton height={4} mb={4} w={72} />
              <Stack w={72}>
                <Skeleton height={4} />
                <Skeleton height={4} />

                <Skeleton height={4} />
              </Stack>
              <HStack spacing={8} mt={5}>
                <Box width={14}>
                  <Skeleton height={14} />
                  <Skeleton height={3} mt={1} />
                </Box>
                <Box width={14}>
                  <Skeleton height={14} />
                  <Skeleton height={3} mt={1} />
                </Box>
                <Box width={14}>
                  <Skeleton height={14} />
                  <Skeleton height={3} mt={1} />
                </Box>
                <Box width={14}>
                  <Skeleton height={14} />
                  <Skeleton height={3} mt={1} />
                </Box>
              </HStack>
            </Flex>
          </Box>
        </Flex>
        <Skeleton width={48} height={{ base: 44, sm: 64 }} />
      </HStack>
    </Box>
  );
};
