import { Box, Flex } from '@chakra-ui/react';
import { InfoPopover } from '../../../Elements/InfoPopover/InfoPopover';
import { PropertyPriceLabel } from '../../PropertyPriceLabel';
import {
  PropertyDetailItem,
  PropertyDetailItemProps,
} from './PropertyDetailItem';
import { PropertyInstallmentInfo } from './PropertyInstallmentInfo';

export interface PropertyDetailItemsProps {
  locality: string;
  realityType: string;
  installment?: string;
  price: string;
}

export const PropertyDetailItems = (props: PropertyDetailItemsProps) => {
  const { locality, realityType, installment, price, ...restOfProps } = props;

  const propertyDetailItems: PropertyDetailItemProps[] = [
    {
      label: 'Lokalita:',
      value: locality,
    },
    {
      label: 'Typ nehnuteľnosti:',
      value: realityType,
    },
  ];

  if (installment) {
    propertyDetailItems.push({
      label: 'Splátka hypotéky:',
      value: (
        <Flex alignItems={'flex-end'}>
          <Box>{installment}</Box>
          <InfoPopover
            body={<PropertyInstallmentInfo />}
            header={'Mesačná splátka vypočítaná na základe:'}
          />
        </Flex>
      ),
    });
  }

  propertyDetailItems.push({
    label: 'Cena:',
    value: <PropertyPriceLabel label={price} />,
  });

  return (
    <Box {...restOfProps}>
      <Flex
        justifyContent={'space-between'}
        flexDir={'column'}
        h={installment ? 'full' : 'auto'}
        gridGap={4}
      >
        {propertyDetailItems.map((i) => (
          <PropertyDetailItem {...i} key={i.label} />
        ))}
      </Flex>
    </Box>
  );
};
