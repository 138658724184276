import { Button } from '@brick/ui-components';
import { Box, Flex, SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlGroup } from '../../../components/FormControls/FormControlGroup';
import { validation } from '../../../const/validation/validation';
import {
  defaultErrorsMessage,
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  defaultSuccessMessage,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const ResetPasswordForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitFormMutation,
    code,
  } = props;
  const methods = useForm<FormValues>({});
  const toast = useToast();
  const { mutateAsync, isLoading } = submitFormMutation();
  const router = useRouter();

  const onSubmit = async (data: FormValues) => {
    try {
      await mutateAsync({ ...data, code });
      methods.reset();
      toast(defaultSuccessMessage);
      router.push('/auth/prihlasenie');
    } catch {
      toast(defaultErrorsMessage);
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }} maxW={'md'} mx="auto">
          <SimpleGrid
            columns={{ md: 1, lg: 1 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4, md: 2, lg: 4 }}
          >
            <FormControlGroup
              name="password"
              type="password"
              label={labels.password}
              placeholder={placeholders.password}
              validation={validation.required}
            />
            <FormControlGroup
              name="passwordConfirmation"
              type="password"
              label={labels.passwordConfirmation}
              placeholder={placeholders.passwordConfirmation}
              validation={validation.required}
            />
          </SimpleGrid>
          <Flex
            justifyContent={'space-between'}
            gridGap={{ base: 4, md: 2 }}
            flexDir={{ base: 'column', md: 'row' }}
            alignItems="center"
          >
            <Box></Box>
            <Box width={{ base: 'full', md: 'auto' }}>
              <Button
                type="submit"
                width={'full'}
                colorScheme={submitButton.colorScheme}
                isLoading={isLoading}
                loadingText={submitButton.loadingText}
                alignSelf={{ base: 'center', md: 'flex-end' }}
              >
                {submitButton.text}
              </Button>
            </Box>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  );
};
