import { Layout } from '@brick/ui-components';
import { layoutItems } from 'apps/client/content/layout-items';
import { performClientSideLogout } from 'apps/client/lib/features/auth/utils/auth.utils';
import { useIsCurrentUserAgent } from 'apps/client/lib/features/user/hooks/useIsCurrentUserAgent';
import { adminRoutes, clientZoneRoutes } from 'apps/client/lib/routes/routes';
import { ReactNode } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { isLoggedIn } from '../Licitation/Providers/UserProvider';
import { Seo, SeoProps } from '../Shared/Seo/Seo';

export interface LayoutPopulatedProps {
  children: ReactNode | ReactNode[];
  seo?: SeoProps;
}

export const LayoutPopulated = (props: LayoutPopulatedProps) => {
  const { children, seo, ...restOfProps } = props;

  const isUserLoggedIn = isLoggedIn();
  const isAgent = useIsCurrentUserAgent();

  if (isUserLoggedIn) {
    let clientZoneLinks = Object.values(clientZoneRoutes);
    if (isAgent) {
      clientZoneLinks = [...Object.values(adminRoutes), ...clientZoneLinks];
    }
    layoutItems.clientZoneLinks = clientZoneLinks;
    layoutItems.headerTop.userRoutes = [
      {
        label: 'Odhlásiť sa',
        onClick: () => performClientSideLogout(),
        icon: <AiOutlineLogout />,
      },
    ];
  }

  

  return (
    <Layout layoutItems={layoutItems} {...restOfProps}>
      <Seo {...seo} />
      {children}
    </Layout>
  );
};
