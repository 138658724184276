import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';
import { BsInbox } from 'react-icons/bs';

export interface NoDataNoticeProps extends BoxProps {
  text?: string;
  entityName?: string;
}

export const NoDataNotice = (props: NoDataNoticeProps) => {
  const {
    text = 'Je nám ľúto no danému filtru nevyhovujú žiadne ',
    entityName = 'dáta.',
    ...restOfProps
  } = props;

  return (
    <Stack
      textAlign={'center'}
      alignItems={'center'}
      py={{ base: 16, md: 28 }}
      spacing={{ base: 0, md: 2 }}
      fontWeight={600}
      color="grey.300"
      {...restOfProps}
    >
      <Box fontSize={{ base: '6xl', md: '7xl' }}>
        <BsInbox />
      </Box>
      <Text fontSize={{ base: 'xl', md: '3xl' }}>
        {text}
        {entityName}
      </Text>
    </Stack>
  );
};
