import { UseToastOptions } from '@chakra-ui/react';
import {
  SubscribeFormSubmitButton,
  SubscribeFormTexts,
} from '../model/subscribe-form';

export const defaultLabels: SubscribeFormTexts = {
  email: 'Chcete vedieť o výhodných ponukách a novinkách?',
};

export const defaultPlaceholders: SubscribeFormTexts = {
  email: 'sem prosím zadajte Váš e-mail',
};

export const defaultSuccessMessage: UseToastOptions = {
  title: 'Teraz Vám už nič neujde!',
  description: 'Budeme Vás informovať o výhodných ponukách a novinkách.',
  status: 'success',
  duration: 9000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: SubscribeFormSubmitButton = {
  text: 'Odoberať novinky',
  loadingText: 'Odosielam',
  colorScheme: 'black',
};
