import { LinkModel } from '@brick/model';
import { stripHtml } from '@brick/utils';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { ContentRenderer } from '../../Elements/ContentRenderer/ContentRenderer';
import { IconButton } from '../../Elements/IconButton/IconButton';
import { ImageContentCard } from '../../Elements/ImageContentCard/ImageContentCard';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';

// @ts-ignore

export interface AgentDetailSectionProps extends BoxProps {
  heading: SectionHeadingProps;
  image: string;
  fullName: string;
  title: string;
  description?: string;
  linkItems: LinkModel[];
  about: string;
}

export const AgentDetailSection = (props: AgentDetailSectionProps) => {
  const {
    heading,
    image,
    fullName,
    title,
    description,
    linkItems,
    about,
    ...restOfProps
  } = props;

  const aboutIsEmpty = !stripHtml(about);

  return (
    <Box {...restOfProps}>
      <Container>
        <SectionHeading {...heading} />
        <ImageContentCard image={image} pt={10} priority>
          <Box fontSize={{ base: 'xl', md: '2xl' }} fontWeight={600}>
            {fullName}
          </Box>
          <Text color="grey.500" pb={2} fontSize={{ base: 'sm', md: 'md' }}>
            {title}
          </Text>
          <Text pb={2} fontSize={{ base: 'sm', lg: 'md' }}>
            {description}
          </Text>
          <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            gridGap={{ base: 3, lg: 6 }}
            pb={4}
            pt={1}
          >
            {linkItems.map((item) => (
              <IconButton
                {...item}
                key={item.href}
                label={
                  <Box color="black.500" fontWeight={400} pl={0.5}>
                    {item.label}
                  </Box>
                }
              />
            ))}
          </Flex>
          {!aboutIsEmpty && (
            <Box>
              <Box fontSize="xl" fontWeight={500} mb={-2} mt={1}>
                Niečo o mne
              </Box>
              <ContentRenderer fontSize="md" mt={2}>
                {about}
              </ContentRenderer>
            </Box>
          )}
        </ImageContentCard>
      </Container>
    </Box>
  );
};
