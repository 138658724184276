import { SocialLink } from '@brick/model';
import { useSocialShareLinks } from './useSocialShareLinks';

export const useSocialShareItems = (domain = 'https://brickreality.sk') => {
  const socialShareLinks = useSocialShareLinks();
  const socialShareItems: SocialLink[] = [
    {
      type: 'facebook',
      href: socialShareLinks.facebook,
      external: true,
    },

    {
      type: 'linkedin',
      href: socialShareLinks.linkedin,
      external: true,
    },
    {
      type: 'mail',
      href: socialShareLinks.mail,
      external: true,
    },
  ];
  return socialShareItems;
};