import { UseToastOptions } from '@chakra-ui/react';

export const defaultErrorsToast: UseToastOptions = {
  title: 'Niekde nastala chyba.',
  description: 'Ste pripojený na internet?',
  status: 'error',
  duration: 4000,
  position: 'top',
  isClosable: true,
};

export const defaultSuccessToast: UseToastOptions = {
  title: 'Hotovo!',
  // description: 'Operácia sa podarila.',
  status: 'success',
  duration: 4000,
  position: 'top',
  isClosable: true,
};

export const settingsSavedToast: UseToastOptions = {
  title: 'Nastavenia uložené',
  // description: "",
  status: 'success',
  duration: 4000,
  position: 'top',
  isClosable: true,
};
