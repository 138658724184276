import { Box, BoxProps, Flex, HStack, Text } from '@chakra-ui/react';
import Image from 'next/image';
import {
  TinyAgentCard,
  TinyAgentCardProps,
} from '../../Elements/TinyAgentCard/TinyAgentCard';

export interface TestimonialSliderItemProps extends BoxProps {
  image?: string;
  title: string;
  content: string;
  subtitle?: string;
  agent?: TinyAgentCardProps;
}

export const TestimonialSliderItem = (props: TestimonialSliderItemProps) => {
  const { image, title, subtitle, agent, content, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      boxShadow={'lg'}
      py={{ base: 4, md: 6 }}
      px={{ base: 4, md: 8 }}
      borderWidth={'1px'}
      borderColor={'grey.100'}
    >
      <HStack
        flexDir={{ base: 'column', md: 'row' }}
        gridGap={{ base: 4, md: 6 }}
      >
        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
          gridGap={{ base: 4, md: 6 }}
          flex={1}
        >
          {image && (
            <Box
              bg="grey.500"
              position="relative"
              h={{ base: 44, sm: 64, md: 'auto' }}
              w={{ base: 'full', md: '36%' }}
            >
              <Image
                src={image}
                layout="fill"
                quality={20}
                objectFit="cover"
                objectPosition={'100% 100%'}
              />
            </Box>
          )}
          <Box flex={1}>
            <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight={500}>
              {title}
            </Text>
            {subtitle && (
              <Box color="grey.500" fontSize={{ base: 'sm', md: 'md' }}>
                {subtitle}
              </Box>
            )}
            <Box pt={{ base: 1, xl: 2 }} fontSize={{ base: 'sm', md: 'md' }}>
              {content}
            </Box>
          </Box>
        </Flex>
        {agent && <TinyAgentCard {...agent} w={{ base: 'full', md: 48 }} />}
      </HStack>
    </Box>
  );
};
