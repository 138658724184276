import { Box, BoxProps } from '@chakra-ui/react';

export interface PropertyPriceLabelProps extends BoxProps {
  label: string;
}

export const PropertyPriceLabel = (props: PropertyPriceLabelProps) => {
  const { label, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      px={{ base: 6, sm: 4 }}
      minW={{ base: 28 }}
      py={1}
      fontSize={{ base: 'xl', md: 'lg' }}
      fontWeight={600}
      color="white"
      textAlign={'center'}
      bg="primary.500"
    >
      {label}
    </Box>
  );
};
