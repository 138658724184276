import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ContentRenderer } from '../../../Elements/ContentRenderer/ContentRenderer';
import { IconText } from '../../../Elements/IconText/IconText';
import { SectionHeading } from '../../../Elements/SectionHeading/SectionHeading';
import { TickList } from '../../../Elements/TickList/TickList';
import { Container } from '../../../Layout/Container/Container';

export interface CareerRequirementProps extends FlexProps {
  heading: string;
  icon: ReactNode;
  description: string;
  listItems?: string[];
  content: string;
}

export const CareerRequirement = (props: CareerRequirementProps) => {
  const { heading, icon, description, listItems, content, ...restOfProps } =
    props;
  return (
    <Box {...restOfProps}>
      <Container>
        <SectionHeading title={heading} />
        <IconText icon={icon} text={description} mb={{ base: 4, md: 0 }} />
        <Flex flexDir={{ base: 'column', md: 'row' }}>
          {listItems && listItems.length > 0 && (
            <Box w={{ base: 'full', md: 1 / 4 }}>
              {listItems && <TickList items={listItems} />}
            </Box>
          )}
          <ContentRenderer
            flex={1}
            fontSize={{ base: 'sm', md: 'md' }}
            pt={{ base: 4, md: 0 }}
          >
            {content}
          </ContentRenderer>
        </Flex>
      </Container>
    </Box>
  );
};
