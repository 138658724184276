import { Container, SectionHeading } from '@brick/ui-components';
import { BoxProps } from '@chakra-ui/react';
import { MortgageCalculator } from './MortgageCalculator';

export interface MortgageCalculatorSectionProps extends BoxProps {}

export const MortgageCalculatorSection = (
  props: MortgageCalculatorSectionProps
) => {
  return (
    <Container id="hypotekarna-kalkulacka">
      <SectionHeading
        title={'Hypotekárna kalkulačka'}
        // description="Hypotekárna kalkulačka slúži na It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        pb={6}
      />
      <MortgageCalculator />
    </Container>
  );
};
