import { LinkModel, SocialLink } from '@brick/model';
import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { IconButtonLink } from '../IconButtonLink/IconButtonLink';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../SectionHeading/SectionHeading';
import { Socials } from '../Socials/Socials';

// @ts-ignore

export interface SocialAndContactLinksProps extends BoxProps {
  heading?: SectionHeadingProps;
  socialLinks: SocialLink[];
  contactLinks: LinkModel[];
  showNote?: boolean;
}

export const SocialAndContactLinks = (props: SocialAndContactLinksProps) => {
  const {
    socialLinks,
    contactLinks,
    heading,
    showNote = true,
    ...restOfProps
  } = props;

  return (
    <Box {...restOfProps}>
      {heading && (
        <SectionHeading
          {...heading}
          mb={{ base: 4, md: 6 }}
          textAlign="left"
          alignItems={'flex-start'}
        />
      )}
      {socialLinks && (
        <Socials socialLinks={socialLinks} title="Sociálne siete:" pt={1} />
      )}
      <Stack spacing={3} pt={4}>
        {contactLinks?.map((link) => {
          return (
            <IconButtonLink
              key={link.href}
              href={link.href}
              buttonProps={{
                label: link.label,
                note: showNote ? link.note : undefined,
                icon: (
                  <Box color="grey.500" fontSize={'3xl'} h={9} w={9}>
                    {link.icon}
                  </Box>
                ),
                color: 'black.500',
                fontWeight: 600,
                fontSize: 'md',
              }}
            />
          );
        })}
      </Stack>
    </Box>
  );
};
