import { Cta, LinkModel, SocialLink } from '@brick/model';
import { ClientZoneHeader } from '@brick/ui-components';
import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { LayoutItemsProvider } from './LayoutItemsProvider';

export interface LayoutItems {
  headerTop?: {
    userRoutes?: Array<LinkModel | Cta>;
    ctaRoutes?: LinkModel[];
    contactLinks?: LinkModel[];
    socialLinks?: SocialLink[];
  };
  menuRoutes?: LinkModel[];
  footer?: {
    socialLinks?: SocialLink[];
    contactLinks?: LinkModel[];
    aboutLinks?: LinkModel[];
    importantLinks?: LinkModel[];
    documentLinks?: LinkModel[];
    subscribeFormMutation?: any;
  };
  clientZoneLinks?: LinkModel[];
}

export interface LayoutProps extends BoxProps {
  layoutItems: LayoutItems;
  children: ReactNode | ReactNode[];
}

export const Layout = (props: LayoutProps) => {
  const { layoutItems, children, ...restOfProps } = props;

  return (
    <Box color="black.500" fontFamily={'Roboto'} {...restOfProps}>
      <LayoutItemsProvider layoutItems={layoutItems}>
        <Header />
        {layoutItems.clientZoneLinks && (
          <ClientZoneHeader
            id="sth"
            links={layoutItems.clientZoneLinks}
            display={{ base: 'none', md: 'flex' }}
          />
        )}
        <Box></Box>
        <Box minH={400} as="main">
          {children}
        </Box>
        <Footer />
      </LayoutItemsProvider>
    </Box>
  );
};
