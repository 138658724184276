import { useToast } from '@chakra-ui/toast';
import { defaultErrorsToast } from 'libs/forms/src/lib/const/toasts';
import { useMutation, UseQueryOptions } from 'react-query';
import { newsLetterSignUpApi } from '../api/news-letter-sign-up.api';
import { NEWSLETTER_EMAIL_LIST_KEY } from '../const/query-keys';

export const useNewsLetterSignUpMutation = (
  options: UseQueryOptions<any> = {}
) => {
  const toast = useToast();
  return useMutation(NEWSLETTER_EMAIL_LIST_KEY, newsLetterSignUpApi, {
    onSuccess: () => {
      toast({
        title: 'Teraz Vám už nič neujde!',
        description: 'Budeme Vás informovať o výhodných ponukách a novinkách.',
        status: 'success',
        duration: 9000,
        position: 'top',
        isClosable: true,
      });
    },
    onError: () => {
      toast(defaultErrorsToast);
    },
    ...options,
  });
};
