import { Stack, useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlGroupCheckbox } from '../../../components/FormControls/FormControlGroupCheckbox';
import { SubmitSection } from '../../../components/SubmitSection/SubmitSection';
import { defaultSuccessToast } from '../../../const/toasts';
import {
  defaultErrorsMessage,
  defaultLabels,
  defaultSubmitButton,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const DeleteAccountForm = (props: FormProps) => {
  const {
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitFormMutation,
    onMutate,
  } = props;
  const methods = useForm<FormValues>({});
  const toast = useToast();
  const router = useRouter();
  const { mutateAsync, isLoading } = submitFormMutation();

  const onSubmit = async (data: FormValues) => {
    try {
      toast({
        ...defaultSuccessToast,
        title: 'Váš profil bol úspešne odstránený.',
        duration: 6000,
      });
      await mutateAsync();
      onMutate();
      router.push('/auth/ucet-odstraneny');
    } catch {
      toast(defaultErrorsMessage);
    }
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }}>
          <FormControlGroupCheckbox
            name="agreement"
            labelNode={labels.agreement}
            validation={{
              required: {
                value: true,
                message: 'Prosím potvrďte.',
              },
            }}
          />
          <SubmitSection
            submitButton={submitButton}
            isLoading={isLoading}
            note="Kliknutím na tlačidlo “Zmazať profil“ nenávratne odstránite svoje osobné údaje z portálu brickreality.sk."
          />
        </Stack>
      </form>
    </FormProvider>
  );
};
