import { Button } from '@brick/ui-components';
import { Box, Flex, SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import Link from 'next/link';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlGroup } from '../../../components/FormControls/FormControlGroup';
import { validation } from '../../../const/validation/validation';
import {
  defaultErrorsMessage,
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const LoginForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitFormMutation,
  } = props;
  const methods = useForm<FormValues>({});
  const toast = useToast();
  const { mutateAsync, isLoading } = submitFormMutation();

  const onSubmit = async (input: FormValues) => {
    try {
      const data = await mutateAsync(input);
      methods.reset();
    } catch (e) {
      // if (e.message === 'Your account email is not confirmed') {
      //   toast({
      //     title: 'Vaša emailová adresa nie je potvrdená.',
      //     description: 'Prosím potvrdte nasjkôr Váš email.',
      //     status: 'error',
      //     duration: 4000,
      //     position: 'top',
      //     isClosable: true,
      //   });
      // } else {
      // }
      toast(defaultErrorsMessage);
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }} maxW={'md'} mx="auto">
          <SimpleGrid
            columns={{ md: 1, lg: 1 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4, md: 2, lg: 4 }}
          >
            <FormControlGroup
              name="identifier"
              label={labels.identifier}
              placeholder={placeholders.identifier}
              validation={validation.email}
            />
            <FormControlGroup
              name="password"
              type="password"
              label={labels.password}
              placeholder={placeholders.password}
              validation={validation.required}
            />
          </SimpleGrid>
          <Flex justifyContent={'space-between'} alignItems="center">
            <Flex fontSize={'sm'} flexDir={{ base: 'column', sm: 'row' }}>
              <Box color="grey.500">Zabudli ste heslo? </Box>
              <Box
                color="primary.500"
                pl={{ base: 'none', sm: 1 }}
                fontWeight={700}
              >
                <Link href={'/auth/zabudnute-heslo'}>
                  <a> Pre zmenu hesla kliknite tu.</a>
                </Link>
              </Box>
            </Flex>
            <Button
              type="submit"
              colorScheme={submitButton.colorScheme}
              isLoading={isLoading}
              loadingText={submitButton.loadingText}
              alignSelf={{ base: 'center', md: 'flex-end' }}
            >
              {submitButton.text}
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  );
};
