import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  password: 'Nové heslo',
  passwordConfirmation: 'Potvrdenie hesla',
};

export const defaultPlaceholders: FormTexts = {
  password: 'nové heslo (min. 6 znakov)',
  passwordConfirmation: 'zopakujete heslo',
};

export const defaultErrorsMessage: UseToastOptions = {
  title: 'Niekde nastala chyba.',
  description: 'Prosím kontaktujte nás.',
  status: 'error',
  duration: 6000,
  position: 'top',
  isClosable: true,
};

export const defaultSuccessMessage: UseToastOptions = {
  title: 'Vaše heslo bolo úspešne zmenené.',
  description: 'Pokračujte prihlásením.',
  status: 'error',
  duration: 6000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Zmeniť heslo',
  loadingText: 'Odosielam',
  colorScheme: 'black',
};
