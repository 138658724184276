import { BoxProps, Container as ChakraContainer } from '@chakra-ui/react';
import { memo, ReactNode } from 'react';

export interface ContainerProps extends BoxProps {
  children: ReactNode[] | ReactNode;
}

export const Container = memo((props: ContainerProps) => {
  const { children, h = 'full', ...restOfProps } = props;
  return (
    <ChakraContainer maxW="container.xl" h={h} {...restOfProps}>
      {children}
    </ChakraContainer>
  );
});
