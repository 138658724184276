import {
  Box,
  BoxProps,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { Logo } from 'libs/ui-components/src/lib/Elements/Logo/Logo';
import { FiMenu, FiX } from 'react-icons/fi';
import { ClientZoneHeader } from '../../ClientZoneHeader/ClientZoneHeader';
import { useLayoutItems } from '../../LayoutItemsProvider';
import { HeaderTopLink } from '../HeaderTop/components/HeaderTopLink/HeaderTopLink';
import { HeaderMobileIcon } from './components/HeaderMobileIcon';
import { MobileMenuLink } from './components/MobileMenuLink';

export interface MobileMenuProps extends BoxProps {}

export const MobileMenu = (props: MobileMenuProps) => {
  const { ...restOfProps } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { headerTop, menuRoutes, clientZoneLinks } = useLayoutItems();

  return (
    <Box {...restOfProps}>
      <HeaderMobileIcon icon={<FiMenu />} onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="right" size={'full'} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          display="flex"
          flexDir="column"
          justifyContent={'flex-start'}
        >
          <Box
            h={'68px'}
            w="full"
            display={'flex'}
            alignItems="center"
            justifyContent={'space-between'}
            px={4}
          >
            <Logo />
            <HeaderMobileIcon icon={<FiX />} onClick={onClose} />
          </Box>

          <Box overflowY={'scroll'} overflowX="hidden">
            {/* CLIENT ZONE ROUTES */}
            {clientZoneLinks && <ClientZoneHeader links={clientZoneLinks} />}
            <Box
              flexDir={'column'}
              gridGap={6}
              justifyContent={'flex-start'}
              mt={clientZoneLinks ? '10%' : '8%'}
              alignItems={'center'}
              display="flex"
              flex={1}
            >
              {/* CTA ROUTES */}
              {headerTop?.ctaRoutes && (
                <Flex
                  gridGap={4}
                  flexDir="column"
                  w="full"
                  alignItems={'center'}
                >
                  {headerTop.ctaRoutes.map((r, i) => (
                    <HeaderTopLink
                      label={String(r.label)}
                      href={r.href}
                      key={i}
                    />
                  ))}
                </Flex>
              )}
              <Divider w={2 / 3} mx="auto" />
              {/* MAIN MENU ROUTES */}
              {menuRoutes && (
                <Flex
                  w="full"
                  gridGap={6}
                  flexDir="column"
                  alignItems={'center'}
                >
                  {menuRoutes.map((route) => (
                    <MobileMenuLink {...route} key={route.href} />
                  ))}
                </Flex>
              )}
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
