import { LinkModel } from '@brick/model';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Container } from '../Container/Container';

export interface ClientZoneHeaderProps extends BoxProps {
  links: LinkModel[];
}

export const ClientZoneHeader = (props: ClientZoneHeaderProps) => {
  const { links, maxW = 'container.xl', ...restOfProps } = props;
  const router = useRouter();

  return (
    <Box>
      <Box
        bg="grey.100"
        py={4}
        fontSize={{ base: 'sm', md: 'xs', lg: 'sm' }}
        w="full"
        {...restOfProps}
      >
        <Container maxW={maxW}>
          <Flex>
            <Box w={{ md: 1 / 3, xl: 3 / 10 }}></Box>
            <Flex
              flexDir={{ base: 'column', md: 'row' }}
              gridGap={{ base: 4, md: 6 }}
              justifyContent={'flex-start'}
              alignItems={{ base: 'center', md: 'flex-end' }}
              w="full"
            >
              {links.map((l) => (
                <Link href={l.href} key={l.href}>
                  <a>
                    <Box
                      textTransform={'uppercase'}
                      color={
                        l.href === router.route ||
                        (l.href === '/licitacia' &&
                          router.query['licitationId'])
                          ? 'primary.500'
                          : 'black.500'
                      }
                      fontWeight={500}
                    >
                      {l.label}
                    </Box>
                  </a>
                </Link>
              ))}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
