import { Box, BoxProps, Flex } from '@chakra-ui/react';
import {
  ButtonLink,
  ButtonLinkProps,
} from '../../Elements/ButtonLink/ButtonLink';
import { Heading } from '../../Elements/Heading/Heading';

export interface PageNotFoundSectionProps extends BoxProps {
  title?: string;
  description?: string;
  cta?: ButtonLinkProps;
}

export const PageNotFoundSection = (props: PageNotFoundSectionProps) => {
  const {
    title = 'Je nám ľúto no daná stránka nebola nájdená',
    description,
    cta = {
      children: 'Prejsť na úvodnú stránku',
      href: '/',
    },
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps}>
      <Flex
        flexDir={{ base: 'column' }}
        gridGap={{ base: 4 }}
        alignItems="center"
        textAlign={'center'}
      >
        <Box
          fontSize={{ base: '6em', md: '7.2rem' }}
          fontWeight={800}
          h={{ base: 28, md: 36 }}
        >
          404
        </Box>
        <Heading alignItems="center" textAlign={'center'}>
          {title}
        </Heading>
        <Box mt={{ base: 4, md: 6 }}>{cta && <ButtonLink {...cta} />}</Box>
      </Flex>
    </Box>
  );
};
