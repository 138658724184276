import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface HeadingProps extends ChakraHeadingProps {
  children: ReactNode;
}

export const Heading = (props: HeadingProps) => {
  const { children, color = 'balck.500', ...restOfProps } = props;
  return (
    <ChakraHeading
      color={color}
      as="h1"
      fontWeight={600}
      fontSize={{ base: '2xl', sm: '2xl', md: '2xl', xl: '3xl' }}
      {...restOfProps}
    >
      {children}
    </ChakraHeading>
  );
};
