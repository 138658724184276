import { useIsRouteActive } from '@brick/utils';
import { Box, BoxProps, Stack } from '@chakra-ui/react';
import Link from 'next/link';
import { ReactNode, useState } from 'react';
import { Underline } from '../../../../Elements/Underline/Underline';

export interface HeaderMiddleLinkProps extends BoxProps {
  href: string;
  label: string | ReactNode;
}

export const HeaderMiddleLink = (props: HeaderMiddleLinkProps) => {
  const { href, label, ...restOfProps } = props;
  const [mouseOn, setMouseOn] = useState(false);
  const isActive = useIsRouteActive(href);

  return (
    <Box {...restOfProps}>
      <Stack spacing={0}>
        <Link href={href}>
          <a>
            <Box
              fontSize={{ base: 'xs', xl: 'sm' }}
              textTransform={'uppercase'}
              fontWeight={600}
              py={0.5}
              onMouseEnter={() => setMouseOn(true)}
              onMouseLeave={() => setMouseOn(false)}
            >
              {label}
            </Box>
          </a>
        </Link>

        <Underline
          w={mouseOn || isActive ? 'full' : 0}
          transition={'all ease-out 300ms'}
        />
      </Stack>
    </Box>
  );
};
