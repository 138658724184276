import { PropertyCardProps } from '@brick/model';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import {
  ButtonLink,
  ButtonLinkProps,
} from '../../Elements/ButtonLink/ButtonLink';
import { ChevronSlider } from '../../Elements/ChevronSlider/ChevronSlider';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';
import { PropertyCard } from '../../Property/PropertyCard';
import { PropertyList } from '../../Property/PropertyList';

// @ts-ignore

export interface FeaturedPropertiesSliderProps extends BoxProps {
  properties: PropertyCardProps[];
  heading: SectionHeadingProps;
  cta: ButtonLinkProps;
}

export const FeaturedPropertiesSlider = (
  props: FeaturedPropertiesSliderProps
) => {
  const { properties, heading, cta, ...restOfProps } = props;

  return (
    <Box
      bg={'grey.100'}
      w="full"
      py={{ base: 8, md: 12 }}
      className="no-print"
      {...restOfProps}
    >
      <Container>
        <SectionHeading
          {...heading}
          alignItems={'center'}
          textAlign={'center'}
        />

        {properties.length > 3 ? (
          <>
            <Box mx={{ base: -3, md: -10 }}>
              <ChevronSlider w="full">
                {properties.map((p, i) => (
                  <Box key={i} w="full">
                    <PropertyCard {...p} py={5} px={{ base: 3, md: 3 }} />
                  </Box>
                ))}
              </ChevronSlider>
            </Box>

            <Flex justifyContent={'center'} w="full" pt={4}>
              <ButtonLink {...cta} />
            </Flex>
          </>
        ) : (
          <Box mt={10} mb={4}>
            <PropertyList properties={properties} />
          </Box>
        )}
      </Container>
    </Box>
  );
};
