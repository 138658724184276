import { Option, PropertyEvaluationOrderForm } from '@brick/forms';
import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import {
  ImageFormHeader,
  ImageFormHeaderProps,
} from '../../Elements/ImageFormHeader/ImageFormHeader';
import { Container } from '../../Layout/Container/Container';

export interface PropertyEvaluationSectionProps extends BoxProps {
  formHeader: ImageFormHeaderProps;
  submitMutation: any;
  propertyCategoryOptions: Option[];
}

export const PropertyEvaluationSection = (
  props: PropertyEvaluationSectionProps
) => {
  const {
    formHeader,
    submitMutation,
    propertyCategoryOptions,
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps}>
      <ImageFormHeader {...formHeader} mb={6} />
      <Container>
        <PropertyEvaluationOrderForm
          submitMutation={submitMutation}
          propertyCategoryOptions={propertyCategoryOptions}
        />
      </Container>
    </Box>
  );
};
