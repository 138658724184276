import { Box, BoxProps } from '@chakra-ui/react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

export interface ChevronSliderArrowProps extends BoxProps {
  type?: 'prev' | 'next';
}

export const ChevronSliderArrow = (props: ChevronSliderArrowProps) => {
  const { type = 'next', w = 8, ...restOfProps } = props;
  return (
    <Box
      w={w}
      fontSize="4xl"
      cursor="pointer"
      color="black.500"
      zIndex={10}
      _hover={{ color: 'black.600' }}
      {...restOfProps}
    >
      {type === 'next' ? <BsChevronRight /> : <BsChevronLeft />}
    </Box>
  );
};
