import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { BlogList } from '../../Blog/BlogList/BlogList';
import { BlogCardProps } from '../../Blog/BlogList/components/BlogCard';
import {
  ButtonLink,
  ButtonLinkProps,
} from '../../Elements/ButtonLink/ButtonLink';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';

// @ts-ignore

export interface FeaturedBlogsProps extends BoxProps {
  heading: SectionHeadingProps;
  blogs?: BlogCardProps[];
  cta: ButtonLinkProps;
}

export const FeaturedBlogs = (props: FeaturedBlogsProps) => {
  const { heading, blogs, cta, ...restOfProps } = props;

  return (
    <Box {...restOfProps} w="full">
      <Container>
        <Stack spacing={{ base: 5, md: 6 }}>
          <SectionHeading {...heading} />
          <BlogList blogs={blogs} />
          <Box alignSelf="center" pt={2}>
            <ButtonLink
              {...cta}
              buttonProps={{
                colorScheme: 'black',
              }}
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
