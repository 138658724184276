import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';

export interface ButtonProps extends ChakraButtonProps {}

export const Button = (props: ButtonProps) => {
  const { children, colorScheme = 'primary', ...restOfProps } = props;
  return (
    <ChakraButton
      colorScheme={colorScheme}
      fontWeight={'medium'}
      borderRadius={0}
      {...restOfProps}
    >
      {children}
    </ChakraButton>
  );
};
