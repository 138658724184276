import { Box, BoxProps, HStack, ResponsiveValue } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface ImageSliderDotsProps extends BoxProps {
  dots: ReactNode;
  size?: ResponsiveValue<string | number>;
  setSlide: any;
}

// much simpler alternative to custom dots component is just adding custom slick css theme
// it works just fine, except you have to use it inside whole app
// I didn't manage to set it up with css modules or any other component specific css

// using this component can have an impact on performance I am afraid

export const ImageSliderDots = (props: ImageSliderDotsProps) => {
  const {
    size = { base: '6px', md: '8px' },
    dots,
    setSlide,
    ...restOfProps
  } = props;
  return (
    <HStack
      justifyContent={'center'}
      spacing={{ base: 1, md: 1.5 }}
      mb={12}
      {...restOfProps}
    >
      {/* @ts-ignore */}
      {dots.map((dot, i) => {
        return (
          <Box
            onClick={() => setSlide(i)}
            key={i}
            cursor={'pointer'}
            bg={
              dot?.props?.className === 'slick-active' ? 'primary.500' : 'white'
            }
            h={size}
            w={size}
          ></Box>
        );
      })}
    </HStack>
  );
};
