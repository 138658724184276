import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  agreement: 'Áno, chcem zmazať svoj profil',
};

// export const defaultPlaceholders: FormTexts = {};

export const defaultErrorsMessage: UseToastOptions = {
  title: 'Niekde nastala chyba.',
  description: 'Prosím kontaktujte nás',
  status: 'error',
  duration: 4000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Zmazať profil',
  loadingText: 'Vymazávam',
  colorScheme: 'black',
};
