import { SocialLink } from '@brick/model';
import { Box, BoxProps, HStack } from '@chakra-ui/react';
import React from 'react';
import { SocialIcons } from '../SocialIcons/SocialIcons';

export interface SocialsProps extends BoxProps {
  socialLinks: SocialLink[];
  title: string;
}

export const Socials = (props: SocialsProps) => {
  const { socialLinks, title, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <HStack mb={-1} spacing={3}>
        <Box fontWeight={500}>{title}</Box>
        <SocialIcons socialLinks={socialLinks} pb={1} />
      </HStack>
    </Box>
  );
};
