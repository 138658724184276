import { HeadingSecondary } from '@brick/ui-components';
import {
  Box,
  BoxProps,
  LinkBox,
  LinkOverlay,
  Table as ChakraTable,
  TableCaption,
  Tbody,
  Tfoot,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { NoDataNotice } from '../Elements/NoDataNotice/NoDataNotice';
import { TableSkeleton } from './TableSkeleton';

export interface Cell {
  label: string | ReactNode;
  shortLabel?: string | ReactNode;
  isNumeric?: boolean;
  hideOnMobile?: boolean;
}

export interface Row {
  cells: Cell[];
  strong?: boolean;
  active?: boolean;
  link?: {
    href: string;
    external?: boolean;
  };
}

export interface TableProps extends BoxProps {
  header: Row;
  rows: Row[];
  footer?: Row;
  caption?: string;
  expectedRows?: number;
  noDataText?: string;
  isLoading?: boolean;
}

export const Table = (props: TableProps) => {
  const {
    rows,
    header,
    footer,
    caption,
    expectedRows = 6,
    noDataText = 'Nanašli sa žiadne dáta',
    isLoading = false,
    ...restOfProps
  } = props;

  if (isLoading) {
    return <TableSkeleton rows={expectedRows} />;
  }

  if (!rows || rows?.length === 0) {
    return <NoDataNotice entityName="" text={noDataText} mx="auto" />;
  }

  const [isSm] = useMediaQuery('(max-width: 48em)');

  return (
    <Box w="full" fontSize={{ base: '0.75em', md: 'md' }} {...restOfProps}>
      <ChakraTable variant="unstyled">
        <Thead>
          <Tr>
            {header?.cells.map((cell, i) => (
              <Th
                isNumeric={cell.isNumeric}
                display={
                  cell.hideOnMobile
                    ? { base: 'none', sm: 'table-cell' }
                    : 'table-cell'
                }
                fontFamily="Roboto"
                letterSpacing={'normal'}
                pt={1.5}
                pb={{ base: 0, md: 3 }}
                pl={i === 0 ? 0 : { base: 2, md: 6 }}
                pr={{ base: 2, md: 6 }}
                key={i}
              >
                <HeadingSecondary
                  textTransform={'none'}
                  fontSize={{ base: 'sm', md: 'lg' }}
                >
                  {isSm ? cell.shortLabel || cell.label : cell.label}
                </HeadingSecondary>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows?.map((row, i) => {
            const cells = row.cells.map((cell, j) => (
              <LinkBox
                as="td"
                py={3}
                key={j}
                px={{ base: 1, md: 6 }}
                display={
                  cell.hideOnMobile
                    ? { base: 'none', sm: 'table-cell' }
                    : 'table-cell'
                }
              >
                {row.link ? (
                  <LinkOverlay href={row.link?.href}>{cell.label}</LinkOverlay>
                ) : (
                  cell.label
                )}
              </LinkBox>
            ));

            const rowElement = (
              <Tr
                borderTopWidth={i === 0 ? 0 : 1}
                borderTopColor="grey.400"
                fontWeight={row.strong ? 600 : 400}
                _hover={{
                  bg: row.link
                    ? 'primary.500'
                    : row.active
                    ? 'primary.500'
                    : 'white',
                  transition: 'all ease-in-out 200ms',
                }}
                bg={row.active ? 'primary.500' : 'white'}
                w="full"
                color={
                  row.active ? 'white' : row.strong ? 'black.500' : 'grey.500'
                }
                key={i}
              >
                {cells}
              </Tr>
            );

            return rowElement;
          })}
        </Tbody>
        {footer && (
          <Tfoot>
            <Tr>
              {footer.cells.map((cell, i) => (
                <Th isNumeric={cell.isNumeric} key={i}>
                  <HeadingSecondary textTransform={'none'}>
                    {isSm ? cell.shortLabel || cell.label : cell.label}
                  </HeadingSecondary>
                </Th>
              ))}
            </Tr>
          </Tfoot>
        )}
        {caption && <TableCaption>{caption}</TableCaption>}
      </ChakraTable>
    </Box>
  );
};
