import { Box, BoxProps, Flex, HStack } from '@chakra-ui/react';
import { IconButton } from '../../../Elements/IconButton/IconButton';
import { IconButtonLink } from '../../../Elements/IconButtonLink/IconButtonLink';
import { Logo } from '../../../Elements/Logo/Logo';
import { Container } from '../../Container/Container';
import { useLayoutItems } from '../../LayoutItemsProvider';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { HeaderMiddleLink } from './components/HeaderMiddleLink';

export interface HeaderMiddleProps extends BoxProps {}

export const HeaderMiddle = (props: HeaderMiddleProps) => {
  const { ...restOfProps } = props;
  const { menuRoutes, headerTop } = useLayoutItems();
  let loginLink;
  if (headerTop?.userRoutes && headerTop?.userRoutes.length > 0) {
    loginLink = headerTop.userRoutes[0];
  }

  return (
    <Box boxShadow={'md'} h={{ base: '68px', md: '78px' }} {...restOfProps}>
      <Container>
        <Flex alignItems={'center'} h="full" w="full" justify={'space-between'}>
          <Box className="header-left" w={{ base: 1 / 3, xl: 3 / 10 }}>
            <Logo />
          </Box>
          {menuRoutes && (
            <Flex
              className="header-right"
              h="full"
              w="full"
              justify={'space-between'}
              display={{ base: 'none', md: 'flex' }}
              alignItems={'center'}
            >
              <Flex justify={'space-between'} w="full" gridGap={3}>
                {menuRoutes.map((route) => (
                  <HeaderMiddleLink {...route} key={route.href} />
                ))}
              </Flex>
            </Flex>
          )}
          <Flex gridGap={4} display={{ base: 'flex', md: 'none' }}>
            {/* USER ROUTES */}
            {headerTop?.userRoutes && (
              <HStack spacing={2}>
                {headerTop.userRoutes.map((cta, i) => {
                  if ('onClick' in cta) {
                    return <IconButton {...cta} key={i} />;
                  }
                  if ('href' in cta) {
                    return (
                      <IconButtonLink
                        key={i}
                        external={cta.external}
                        href={cta.href}
                        buttonProps={{
                          icon: cta.icon,
                          label: cta.label,
                        }}
                      />
                    );
                  }
                })}
              </HStack>
            )}

            <MobileMenu display={{ md: 'none' }} />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
