import { UseToastOptions } from '@chakra-ui/react';
import {
  ContactFormSubmitButton,
  ContactFormTexts,
} from '../model/contact-form';

export const defaultLabels: ContactFormTexts = {
  fullName: 'Meno a priezvisko',
  phone: 'Telefón',
  email: 'Email',
  subject: 'Predmet Vašej správy',
  message: 'Vaša správa',
};

export const defaultPlaceholders: ContactFormTexts = {
  fullName: 'meno a priezvisko',
  phone: 'telefón',
  email: 'e-mail',
  subject: 'predmet Vašej správy',
  message: 'obsah Vašej správy...',
};

export const defaultSuccessMessage: UseToastOptions = {
  title: 'Ďakujeme za Váš email.',
  description:
    'Budeme Vás kontaktovať v priebehu niekoľkých dní. Ak je to súrne prosím kontaktujte nás telefonicky.',
  status: 'success',
  duration: 14000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: ContactFormSubmitButton = {
  text: 'Odoslať správu',
  loadingText: 'Odosielam',
  colorScheme: 'black',
};
