import { Button, Note } from '@brick/ui-components';
import { Box, BoxProps, Flex, ResponsiveValue, Stack } from '@chakra-ui/react';
import { FormSubmitButton } from '../../forms/CareerForm/model/form-model';

export interface SubmitSectionProps extends BoxProps {
  submitButton: FormSubmitButton;
  isLoading?: boolean;
  note?: string;
  indentNote?: boolean;
  buttonWidth?: ResponsiveValue<string>;
}

export const SubmitSection = (props: SubmitSectionProps) => {
  const {
    submitButton,
    note,
    isLoading,
    indentNote = true,
    buttonWidth = { base: 'full', sm: 32 },
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps}>
      <Stack>
        <Flex
          justifyContent={'space-between'}
          alignItems="center"
          gridGap={4}
          flexDir={{ base: 'column', sm: 'row', md: 'row', xl: 'row' }}
        >
          <Box alignSelf={{ base: 'flex-start', md: 'center' }}>
            {note && (
              <Note text={note} ml={{ base: 0, xl: indentNote ? -8 : 0 }} />
            )}
          </Box>
          <Box
            alignSelf={{ base: 'center', md: 'flex-end' }}
            w={{ base: 'full', sm: 'auto' }}
          >
            <Button
              type="submit"
              colorScheme={submitButton.colorScheme}
              isLoading={isLoading}
              loadingText={submitButton.loadingText}
              w={buttonWidth}
            >
              {submitButton.text}
            </Button>
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
};
