import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import dot from 'dot-object';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Select, SelectProps } from '../Inputs/Select';

export interface Option {
  value: number | string;
  text: string;
}
export interface FormControlGroupSelectProps extends FormControlProps {
  label?: string;
  placeholder?: string;
  validation?: any;
  labelProps?: FormLabelProps;
  options: Option[];
  selectProps?: SelectProps;
  name: string;
}

export const FormControlGroupSelect = (props: FormControlGroupSelectProps) => {
  const {
    options,
    label,
    placeholder,
    validation,
    name,
    labelProps,
    selectProps,
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl isInvalid={dot.pick(name, errors)}>
      {label && (
        <FormLabel
          htmlFor={name}
          color="grey.500"
          fontSize="sm"
          fontWeight={400}
          mb={1}
          {...labelProps}
        >
          {label}
        </FormLabel>
      )}
      <Select
        placeholder={`${validation?.required?.value ? '*' : ''}${placeholder}`}
        {...selectProps}
        {...register(name, validation || {})}
      >
        {options &&
          options.map((o, i) => (
            <option value={o.value} key={i}>
              {o.text}
            </option>
          ))}
      </Select>
      <FormErrorMessage color="red.600">
        {dot.pick(name, errors)?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
