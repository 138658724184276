import { PropertyCardProps } from '@brick/model';
import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ChevronSliderArrow } from '../../Elements/ChevronSlider/components/ChevronSliderArrow';
import { Container } from '../../Layout/Container/Container';
import { HeroPropertySliderItem } from './HeroProertySliderItem';

const settings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 8000,
  infinite: true,
  arrows: false, // arrows are handled completely custom
  dots: false,
};

export interface HeroPropertySliderProps extends BoxProps {
  properties: PropertyCardProps[];
}

export const HeroPropertySlider = (props: HeroPropertySliderProps) => {
  const { properties, ...restOfProps } = props;
  const sliderRef = React.createRef<Slider>();
  const showArrows = properties.length > 1;

  return (
    <Box position={'relative'} {...restOfProps}>
      <Container position="relative" px={{ base: 0, md: 4 }}>
        {showArrows && (
          <ChevronSliderArrow
            position="absolute"
            top={{ base: '18%', sm: '24%', md: '50%' }}
            transform={'translateY(-50%)'}
            left={{ base: 0, sm: 4, md: 12 }}
            color={'white'}
            _hover={{ color: '#ffffffcc' }}
            type="prev"
            onClick={() => sliderRef?.current?.slickPrev()}
          />
        )}
        <Slider {...settings} ref={sliderRef}>
          {properties.map((p, i) => (
            <Box h={{ base: 220, sm: 300, md: 440 }} key={i}>
              <HeroPropertySliderItem {...p} imageQuality={i === 0 ? 80 : 40} />
            </Box>
          ))}
        </Slider>
        {showArrows && (
          <ChevronSliderArrow
            position="absolute"
            top={{ base: '18%', sm: '24%', md: '50%' }}
            transform={'translateY(-50%)'}
            right={{ base: 0, sm: 4, md: 12 }}
            color={'white'}
            _hover={{ color: '#ffffffcc' }}
            type="next"
            onClick={() => sliderRef?.current?.slickNext()}
          />
        )}
      </Container>
    </Box>
  );
};
