import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { SvgIcon } from '../../Elements/SvgIcon/SvgIcon';

export interface PropertyAttributeProps extends BoxProps {
  icon: string;
  label: string;
  iconSize?: number;
}

export const PropertyAttribute = (props: PropertyAttributeProps) => {
  const { icon, label, iconSize = 48, ...restOfProps } = props;
  return (
    <Box {...restOfProps} w={'full'}>
      <Stack spacing={1} alignItems={'center'} textAlign="center">
        <SvgIcon src={icon} size={iconSize} />
        <Box fontSize={'xs'} h={10}>
          {label}
        </Box>
      </Stack>
    </Box>
  );
};
