import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { ServiceBanner, ServiceBannerProps } from './ServiceBanner';

export interface ServicesBannersProps extends BoxProps {
  banners: [ServiceBannerProps, ServiceBannerProps];
}

export const ServicesBanners = (props: ServicesBannersProps) => {
  const { banners, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <SimpleGrid columns={{ md: 2 }} gridGap={{ base: 6, lg: 8 }}>
        <ServiceBanner {...banners[0]} />
        <ServiceBanner {...banners[1]} />
      </SimpleGrid>
    </Box>
  );
};
