import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import {
  TextWithHeading,
  TextWithHeadingProps,
} from '../../Elements/TextWithHeading/TextWithHeading';
import { Container } from '../../Layout/Container/Container';

export interface TextSectionProps extends BoxProps {
  textWithHeading: TextWithHeadingProps;
}

export const TextSection = (props: TextSectionProps) => {
  const { textWithHeading, ...restOfProps } = props;
  return (
    <Box {...restOfProps} mb={-4}>
      <Container>
        <TextWithHeading {...textWithHeading} />
      </Container>
    </Box>
  );
};
