import { Box, BoxProps, ResponsiveValue } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import logo from './assets/brick-logo.png';

export interface LogoProps extends BoxProps {
  href?: string;
  logoWidth?: ResponsiveValue<number>;
  logoHeight?: ResponsiveValue<number>;
}

export const Logo = (props: LogoProps) => {
  const {
    href = '/',
    logoWidth = { base: 94, md: 140 },
    logoHeight = { base: '32px', md: '48px' },
    ...restOfProps
  } = props;
  return (
    <Box height={logoHeight} width={logoWidth} position="relative">
      <Link href={href}>
        <a>
          <Image
            src={logo}
            alt="brick logo"
            layout="fill"
            objectFit="contain"
            priority
          />
        </a>
      </Link>
    </Box>
  );
};
