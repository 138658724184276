import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { Settings } from 'react-slick';
import { ChevronSlider } from '../../Elements/ChevronSlider/ChevronSlider';
import { PerkCard, PerkCardProps } from './PerkCard';

export interface PerksSliderProps extends BoxProps {
  perks: PerkCardProps[];
  settings?: Settings;
}

export const PerksSlider = (props: PerksSliderProps) => {
  const { perks, settings, ...restOfProps } = props;
  return (
    <Box {...restOfProps} mx={{ base: -4, md: 0 }}>
      <ChevronSlider
        settings={{
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
              },
            },
            {
              breakpoint: 520,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
              },
            },
          ],
          ...settings,
        }}
      >
        {perks.map((perk, i) => (
          <Box key={i} py={5} px={{ base: 3, md: 4 }}>
            <PerkCard {...perk} />
          </Box>
        ))}
      </ChevronSlider>
    </Box>
  );
};
