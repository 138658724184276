import { PropertyCardProps } from '@brick/model';
import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import { Banner, BannerProps } from '../Elements/Banner/Banner';
import { PropertyCard } from './PropertyCard';

export interface PropertyListProps extends BoxProps {
  properties: PropertyCardProps[];
  banner?: BannerProps;
  bannerIndex?: number;
}

export const PropertyList = (props: PropertyListProps) => {
  const { properties, banner, bannerIndex = 5, ...restOfProps } = props;
  const propertiesBeforeBanner = properties.slice(0, bannerIndex);
  const propertiesAfterBanner = properties.slice(bannerIndex);

  return (
    <Box {...restOfProps}>
      <SimpleGrid
        columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
        spacing={{ base: 8 }}
      >
        {propertiesBeforeBanner.map((p, i) => (
          <PropertyCard {...p} key={i} />
        ))}
        {banner && propertiesBeforeBanner.length >= bannerIndex && (
          <Banner {...banner} h={500} />
        )}
        {propertiesAfterBanner.map((p, i) => (
          <PropertyCard {...p} key={i} />
        ))}
      </SimpleGrid>
    </Box>
  );
};
