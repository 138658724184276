import { Box, BoxProps } from '@chakra-ui/react';
import { ChevronSlider } from '../../Elements/ChevronSlider/ChevronSlider';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';
import {
  TestimonialSliderItem,
  TestimonialSliderItemProps,
} from './TestimonialSliderItem';

// @ts-ignore

export interface TestimonialsSliderSectionProps extends BoxProps {
  heading: SectionHeadingProps;
  testimonials: TestimonialSliderItemProps[];
}

export const TestimonialsSliderSection = (
  props: TestimonialsSliderSectionProps
) => {
  const { heading, testimonials, ...restOfProps } = props;

  if (testimonials.length === 0) {
    return <Box></Box>;
  }
  return (
    <Box {...restOfProps}>
      <Container>
        {heading && <SectionHeading {...heading} />}
        <ChevronSlider
          settings={{
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            responsive: [
              {
                breakpoint: 100,
                settings: { slidesToScroll: 1, slidesToShow: 1 },
              },
            ],
          }}
          mx={{ base: -4, xl: -12 }}
        >
          {testimonials.map((t, i) => (
            <Box px={4} py={6} key={i}>
              <TestimonialSliderItem {...t} />
            </Box>
          ))}
        </ChevronSlider>
      </Container>
    </Box>
  );
};
