import { Box, BoxProps, Text } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Heading } from '../Heading/Heading';
import { TickList } from '../TickList/TickList';

export interface ContentRendererProps extends BoxProps {
  children?: string;
  useTickForUl?: boolean;
}

export const ContentRenderer = (props: ContentRendererProps) => {
  const {
    children,
    color,
    textAlign,
    fontSize = 'sm',
    useTickForUl,
    ...restOfProps
  } = props;

  return (
    <Box color={color || 'black.500'} fontSize={fontSize} {...restOfProps}>
      {children && (
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            h1: ({ children }) => (
              <Heading as="h1" color={color || 'secondary.500'}>
                {children}
              </Heading>
            ),
            h2: ({ children }) => (
              <Text
                fontSize={{ base: 'lg', md: 'xl' }}
                fontWeight={500}
                as="h2"
                mt={6}
                mb={{ base: 1, md: 0 }}
              >
                {children}
              </Text>
            ),
            h3: ({ children }) => (
              <Text
                as="h3"
                fontSize="md"
                fontWeight="bold"
                color={color || 'secondary.500'}
              >
                {children}
              </Text>
            ),
            h4: ({ children }) => (
              <Text as="h4" fontSize="md" fontWeight="bold">
                {children}
              </Text>
            ),
            p: ({ children, ...props }) => {
              return (
                <Box
                  as="p"
                  my={{ base: 0, md: 0 }}
                  minH={6}
                  style={{ whiteSpace: 'pre-wrap' }}
                  textAlign={textAlign}
                >
                  {children}
                </Box>
              );
            },
            strong: ({ children }) => (
              <Text as="strong" fontWeight="500" color={color || 'primary.500'}>
                {children}
              </Text>
            ),
            ul: ({ children }) => (
              <TickList
                icon={useTickForUl ? '✓' : '•'}
                color={useTickForUl ? 'grey.500' : 'black.500'}
                items={children
                  // @ts-ignore
                  .filter((child) => child?.props)
                  // @ts-ignore
                  .map((child) => child?.props?.children[0])}
              />
            ),
          }}
        >
          {children}
        </ReactMarkdown>
      )}
    </Box>
  );
};
