import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { TickList } from '../../../Elements/TickList/TickList';
import {
  PropertyAttributes,
  PropertyAttributesProps,
} from '../../PropertyAttributes/PropertyAttributes';
import {
  PropertyDetailItems,
  PropertyDetailItemsProps,
} from './PropertyDetailItems';

export interface PropertyDetailProps extends BoxProps {
  propertyDetailItems: PropertyDetailItemsProps;
  propertyAttributes: PropertyAttributesProps;
  listItems: string[];
}

export const PropertyDetail = (props: PropertyDetailProps) => {
  const { propertyDetailItems, propertyAttributes, listItems, ...restOfProps } =
    props;
  return (
    <Box {...restOfProps}>
      <SimpleGrid columns={{ md: 2 }} gridGap={{ base: 6 }}>
        <Box>
          {listItems && <TickList items={listItems} />}
          <PropertyAttributes
            {...propertyAttributes}
            maxW={96}
            ml={-2}
            pt={4}
          />
        </Box>
        <PropertyDetailItems {...propertyDetailItems} />
      </SimpleGrid>
    </Box>
  );
};
