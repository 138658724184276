import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export interface MortgageCalculatorResultItemProps extends BoxProps {
  label: string;
  value: string;
  divider?: boolean;
}

export const MortgageCalculatorResultItem = (
  props: MortgageCalculatorResultItemProps
) => {
  const { label, value, divider = true, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      borderLeftColor={'primary.500'}
      borderLeftWidth={divider ? 3 : 0}
      pl={3}
    >
      <Box>{value}</Box>
      <Box fontWeight={600}>{label}</Box>
    </Box>
  );
};
