import { Box, BoxProps, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface IconButtonProps extends BoxProps {
  icon?: ReactNode;
  label?: string | ReactNode;
  note?: string;
  color?: string;
  iconColor?: string;
}

export const IconButton = (props: IconButtonProps) => {
  const {
    icon,
    label,
    note,
    color = 'grey.500',
    iconColor,
    fontSize = 'sm',
    ...restOfProps
  } = props;

  return (
    <Box
      color={color ? color : 'grey.500'}
      cursor="pointer"
      fontSize={fontSize}
      _hover={{
        color: 'black',
        transform: 'scale(1.01)',
        transition: 'all ease-in-out 200ms',
      }}
      {...restOfProps}
    >
      <HStack spacing={1}>
        <Box
          fontSize={{ base: 'xl', xl: 'xl' }}
          color={iconColor ? iconColor : 'grey.500'}
        >
          {icon}
        </Box>
        <Box>{label}</Box>
        {note && (
          <Box
            fontSize={{ base: 'xs', md: 'sm' }}
            color="grey.500"
            fontWeight={400}
          >
            {note}
          </Box>
        )}
      </HStack>
    </Box>
  );
};
