import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export interface UnderlineProps extends BoxProps {}

export const Underline = (props: UnderlineProps) => {
  const { ...restOfProps } = props;
  return (
    <Box
      bg="primary.500"
      h={{ base: '2px', md: '3px' }}
      w="full"
      style={{ boxShadow: '1px 1px 1px #43424220' }}
      {...restOfProps}
    ></Box>
  );
};
