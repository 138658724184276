import { SectionHeading } from '@brick/ui-components';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { Container } from '../../Layout/Container/Container';
import { ShareLinks } from '../../SmartComponents/ShareLinks/ShareLinks';

export interface BreadcrumbHeaderProps extends BoxProps {
  title?: string;
  subtitle?: string;
  shareTitle?: string;
}

export const BreadcrumbHeader = (props: BreadcrumbHeaderProps) => {
  const {
    title,
    subtitle,
    shareTitle = 'Zdieľanie: ',
    maxW = 'container.xl',
    ...restOfProps
  } = props;
  return (
    <Box py={title ? 6 : 5} bg="grey.100" w="full" {...restOfProps}>
      <Container maxW={maxW}>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gridGap={3}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          w="full"
        >
          <Box fontSize={'lg'} fontWeight={500}>
            {title ? (
              <SectionHeading title={title} description={subtitle} />
            ) : (
              <Box>{subtitle}</Box>
            )}
          </Box>
          <ShareLinks
            className="no-print"
            alignSelf={'center'}
            color="grey.500"
            pb={'3px'}
            title={shareTitle}
          />
        </Flex>
      </Container>
    </Box>
  );
};
