import {
  forwardRef,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';
import React from 'react';

export interface TextareaProps extends ChakraTextareaProps {}

export const Textarea = forwardRef<TextareaProps, 'input'>((props, ref) => {
  return (
    <ChakraTextarea
      ref={ref}
      borderColor={'"grey.400"'}
      color="grey.500"
      bg="white"
      rows={4}
      _placeholder={{
        color: 'grey.500',
      }}
      errorBorderColor={'red.600'}
      _focus={{ borderColor: 'primary' }}
      {...props}
    ></ChakraTextarea>
  );
});
