import { LinkModel as LinkModel } from '@brick/model';
import { Box, BoxProps, HStack, Stack, Text } from '@chakra-ui/react';
import Link from 'next/link';

export interface ContactCtaHeadingProps extends BoxProps {
  title: string;
  link: LinkModel;
  showBorder?: boolean;
}

export const ContactCtaHeading = (props: ContactCtaHeadingProps) => {
  const { title, link, showBorder, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Stack
        textAlign={'center'}
        alignItems={'center'}
        // border
        maxW={'container.lg'}
        borderBottomWidth={showBorder ? 1 : 0}
        borderColor={'"grey.400"'}
        mx={'auto'}
        px={{ md: 14 }}
        pb={showBorder ? { base: 4, md: 6 } : 0}
      >
        <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight={500}>
          {title}
        </Text>
        <HStack alignItems={'flex-start'}>
          <Link href={link.href}>
            <a>
              <Box
                color="primary.500"
                fontSize={{ base: 'xl', md: '2xl' }}
                fontWeight={500}
              >
                {link.label}
              </Box>
            </a>
          </Link>
          <Box fontSize={{ base: 'xs', md: 'sm' }} pt={1} color="grey.500">
            {link.note}
          </Box>
        </HStack>
      </Stack>
    </Box>
  );
};
