import { Box, BoxProps } from '@chakra-ui/react';
import Link from 'next/link';
import { ReactNode } from 'react';
import { validation } from '../../const/validation/validation';
import { FormControlGroupCheckbox } from '../FormControls/FormControlGroupCheckbox';
import { CheckboxProps } from '../Inputs/Checkbox';

export interface GdprAgreementProps extends BoxProps {
  agreementText?: string | ReactNode;
  inline?: boolean;
  name?: string;
  linkColor?: string;
  checkboxProps?: CheckboxProps;
}

export const GdprAgreement = (props: GdprAgreementProps) => {
  const {
    agreementText,
    inline = true,
    name = 'gdprAgreement',
    linkColor = 'primary.500',
    checkboxProps,
    ...restOfProps
  } = props;

  return (
    <Box>
      <FormControlGroupCheckbox
        checkBoxProps={checkboxProps}
        labelNode={
          agreementText || (
            <Box {...restOfProps}>
              Udeľujem súhlas na spracovanie osobných údajov pre informačné
              účely.{' '}
              <Link href="/files/ochrana-osobnych-udajov.pdf">
                <a rel={'noopener noreferrer'} target={'_blank'}>
                  <Box
                    color={linkColor}
                    fontWeight={700}
                    display={{
                      base: inline ? 'inline' : 'block',
                      lg: inline ? 'inline' : 'block',
                    }}
                  >
                    Ochrana osobných údajov
                  </Box>
                </a>
              </Link>
            </Box>
          )
        }
        name={name}
        validation={validation.termsAgreement}
      />
    </Box>
  );
};
