import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';
import { SvgIcon } from '../../Elements/SvgIcon/SvgIcon';
import { TickList } from '../../Elements/TickList/TickList';

export interface PerkCardProps extends BoxProps {
  icon: string;
  title: string;
  description: string;
  items: string[];
}

export const PerkCard = (props: PerkCardProps) => {
  const { icon, title, description, items, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      boxShadow="lg"
      borderWidth={'1px'}
      h={{ base: 320, md: 'auto' }}
      borderColor={'grey.100'}
    >
      <Stack alignItems={'center'} textAlign="center" p={3} py={6} spacing={4}>
        <Stack alignItems={'center'}>
          <SvgIcon src={icon} />
          <Text
            fontSize={{ base: '2xl', md: 'lg' }}
            fontWeight={600}
            color="primary.500"
          >
            {title}
          </Text>
        </Stack>
        <Text fontSize="sm">{description}</Text>
        <TickList items={items} />
      </Stack>
    </Box>
  );
};
