import { Table, TableProps } from '@brick/ui-components';
import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { ButtonLink, ButtonLinkProps } from '../Elements/ButtonLink/ButtonLink';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../Elements/SectionHeading/SectionHeading';

// @ts-ignore

export interface TableSectionProps extends BoxProps {
  heading: SectionHeadingProps;
  table: TableProps;
  cta?: ButtonLinkProps;
}

export const TableSection = (props: TableSectionProps) => {
  const { heading, table, cta, ...restOfProps } = props;
  return (
    <Box w="full" {...restOfProps}>
      <Stack w="full" spacing={{ base: 6, md: 6 }} alignItems="center">
        <Box alignSelf={'flex-start'}>
          <SectionHeading {...heading} alignItems="flex-start" />
        </Box>
        <Box w="full">
          <Table w="full" {...table} />
        </Box>
        {cta && table?.rows && table.rows.length > 0 && (
          <Box>
            <ButtonLink {...cta} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
