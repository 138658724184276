import {
  Box,
  Flex,
  FormLabel,
  FormLabelProps,
  forwardRef,
  RangeSlider as ChakraRangeSlider,
  RangeSliderFilledTrack,
  RangeSliderProps as ChakraRangeSliderProps,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface RangeSliderProps extends ChakraRangeSliderProps {
  label?: string;
  labelProps?: FormLabelProps;
  unit?: string;
  range?: [number, number];
  name: string;
}

const calculateFinalRnage = (
  initialRange: [number, number],
  percentageRange: [number, number]
) => {
  const diff = initialRange[1] - initialRange[0];
  return [
    Math.round(initialRange[0] + (percentageRange[0] * diff) / 100),
    Math.round(initialRange[0] + (percentageRange[1] * diff) / 100),
  ] as [number, number];
};

export const RangeSlider = forwardRef<RangeSliderProps, 'input'>(
  (props, ref) => {
    const {
      label,
      labelProps,
      unit = '',
      name,
      range = [0, 100],
      ...restOfProps
    } = props;
    const { setValue: setFilterFormValue, getValues } = useFormContext();
    const initialValueStr = getValues(name)?.split('-');

    const initialValue: [number, number] = initialValueStr
      ? [Number(initialValueStr[0]), Number(initialValueStr[1])]
      : range;

    const [value, setValue] = useState<[number, number]>(initialValue);

    const handleChange = (percentageRange: [number, number]) => {
      setValue(calculateFinalRnage(range, percentageRange));
      setFilterFormValue(name, `${value[0]}-${value[1]}`);
    };

    const diff = range[1] - range[0];

    return (
      <Box>
        <FormLabel
          color="grey.500"
          fontSize="sm"
          fontWeight={400}
          mb={1}
          {...labelProps}
        >
          {label}
        </FormLabel>
        <Flex
          flexDir={'column'}
          justifyContent={'center'}
          borderWidth={'1px'}
          borderColor={'"grey.400"'}
          color="grey.500"
          rounded="md"
          bg="white"
          h={12}
        >
          <Box px={4} pt={1}>
            <ChakraRangeSlider
              ref={ref}
              aria-label={['min', 'max']}
              defaultValue={[
                ((initialValue[0] - range[0]) / diff) * 100,
                ((initialValue[1] - range[0]) / diff) * 100,
              ]}
              step={0.01}
              onChange={handleChange}
              colorScheme="primary"
              {...restOfProps}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} bg="primary.500" boxSize={4} />
              <RangeSliderThumb index={1} bg="primary.500" boxSize={4} />
            </ChakraRangeSlider>
          </Box>
        </Flex>
        <Flex
          justifyContent={'space-between'}
          pt={1}
          color="grey.500"
          fontSize={'xs'}
        >
          <Box>
            {value[0]} {unit}
          </Box>
          <Box>
            {value[1]} {unit}
          </Box>
        </Flex>
      </Box>
    );
  }
);
