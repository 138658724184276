import { Box, BoxProps } from '@chakra-ui/react';
import { HeaderMiddle } from './HeaderMiddle/HeaderMiddle';
import { HeaderTop } from './HeaderTop/HeaderTop';

export interface HeaderProps extends BoxProps {}

export const Header = (props: HeaderProps) => {
  const { ...restOfProps } = props;
  return (
    <Box {...restOfProps} className="no-print">
      <HeaderTop display={{ base: 'none', md: 'block' }} />
      <HeaderMiddle />
    </Box>
  );
};
