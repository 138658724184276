import { Button } from '@brick/ui-components';
import { Box, SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlGroup } from '../../components/FormControls/FormControlGroup';
import { validation } from '../../const/validation/validation';
import { MortgageCalculatorResults } from './components/MortgageCalculatorResults';
import { MortgageCalculatorResultItemProps } from './components/MortgageCalculatorResultsItem';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  defaultValues,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';
import { getMortgageResults } from './utils/getMortgageResults';

export const MortgageCalculator = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
  } = props;

  const toast = useToast();
  const [results, setResults] = useState<
    undefined | MortgageCalculatorResultItemProps[]
  >();
  const methods = useForm<FormValues>({ defaultValues: defaultValues });

  const onSubmit = async (data: FormValues) => {
    // toast(defaultSuccessMessage);
    const results = getMortgageResults(data);
    setResults(results);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }}>
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4 }}
            bg="grey.100"
            p={6}
          >
            <FormControlGroup
              name="propertyValue"
              label={labels.propertyValue}
              placeholder={placeholders.propertyValue}
              validation={validation.integer}
            />
            <FormControlGroup
              name="amount"
              label={labels.amount}
              placeholder={placeholders.amount}
              validation={validation.integer}
            />
            <FormControlGroup
              name="dueYears"
              label={labels.dueYears}
              placeholder={placeholders.dueYears}
              validation={validation.integer}
            />
            <FormControlGroup
              name="interest"
              label={labels.interest}
              placeholder={placeholders.interest}
              validation={validation.float}
            />
            <FormControlGroup
              name="fees"
              label={labels.fees}
              placeholder={placeholders.fees}
              validation={validation.integer}
            />
            <FormControlGroup
              name="fixationPeriod"
              label={labels.fixationPeriod}
              placeholder={placeholders.fixationPeriod}
              validation={validation.integer}
            />
          </SimpleGrid>

          <Button
            type="submit"
            colorScheme={submitButton.colorScheme}
            loadingText={submitButton.loadingText}
            alignSelf={{ base: 'center', md: 'flex-end' }}
          >
            {submitButton.text}
          </Button>
          {results && (
            <Box pt={2}>
              <MortgageCalculatorResults results={results} />
            </Box>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
