import { useIsRouteActive } from '@brick/utils';
import { Box, BoxProps } from '@chakra-ui/react';
import Link from 'next/link';

export interface HeaderTopLinkProps extends BoxProps {
  href: string;
  label: string;
}

export const HeaderTopLink = (props: HeaderTopLinkProps) => {
  const { href, label, ...restOfProps } = props;
  const isActive = useIsRouteActive(href);

  return (
    <Box
      {...restOfProps}
      fontSize={{ base: 'md', md: 'xs', xl: 'sm' }}
      textTransform={'uppercase'}
      color={isActive ? 'primary.500' : 'black.500'}
      _hover={{ color: 'primary', transition: 'all ease-in-out 200ms' }}
    >
      <Link href={href}>
        <a>{label}</a>
      </Link>
    </Box>
  );
};
