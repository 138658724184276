import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import {
  TextWithHeading,
  TextWithHeadingProps,
} from '../../Elements/TextWithHeading/TextWithHeading';
import { Container } from '../../Layout/Container/Container';

export interface TextColumnsSectionProps extends BoxProps {
  columns: TextWithHeadingProps[];
}

export const TextColumnsSection = (props: TextColumnsSectionProps) => {
  const { columns, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Container>
        <SimpleGrid columns={{ md: 2 }} gridGap={{ base: 8, md: 28 }}>
          {columns.map((c, i) => (
            <TextWithHeading {...c} key={i} />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};
