import { BoxProps, Flex, Skeleton, Stack } from '@chakra-ui/react';

export interface BlogCardSkeletonProps extends BoxProps {}

export const BlogCardSkeleton = (props: BlogCardSkeletonProps) => {
  const { ...restOfProps } = props;
  return (
    <Flex
      flexDir={{ base: 'column', lg: 'row' }}
      gridGap={{ base: 4, md: 6 }}
      w="full"
      boxShadow={'md'}
      borderWidth={'1px'}
      borderColor={'grey.100'}
      {...restOfProps}
    >
      <Skeleton
        position="relative"
        h={{ base: 60, sm: 80, md: 'auto' }}
        w={{ base: 'full', md: '32%' }}
      />
      <Flex
        py={{ base: 4, md: 6 }}
        pr={{ base: 4, md: 8 }}
        flexDir="column"
        justifyContent={'space-between'}
        h="full"
        flex={1}
      >
        <Skeleton height={10} w={'72%'} />
        <Skeleton height={2} mb={4} w={72} mt={2} />
        <Stack>
          <Skeleton height={4} />
          <Skeleton height={4} />
          <Skeleton height={4} />
          <Skeleton height={4} />
        </Stack>
      </Flex>
    </Flex>
  );
};
