import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { ButtonLink, ButtonLinkProps } from '../ButtonLink/ButtonLink';
import { SectionHeading } from '../SectionHeading/SectionHeading';

export interface BannerProps extends BoxProps {
  image: string;
  title: string;
  description: string;
  cta: ButtonLinkProps;
}

export const Banner = (props: BannerProps) => {
  const { image, title, description, cta, h = 'full' } = props;
  return (
    <Box
      textAlign="center"
      alignContent="center"
      width="full"
      height={h}
      position="relative"
    >
      <Image
        src={image}
        layout="fill"
        alt="banner"
        quality={90}
        objectFit="cover"
      />
      <Flex
        padding={7}
        justifyContent="center"
        flexDir={'column'}
        h="full"
        position={'absolute'}
      >
        <SectionHeading
          color="white"
          title={title}
          textAlign="center"
          alignItems="center"
        />
        <Text color="white" fontSize="lg" fontWeight={500} pt={2}>
          {description}
        </Text>
        <Box mt={8}>
          <ButtonLink href={cta.href} external={cta.external}>
            {cta.children}
          </ButtonLink>
        </Box>
      </Flex>
    </Box>
  );
};
