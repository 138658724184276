import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  fullName: 'Meno a priezvisko',
  phone: 'Tel. č.',
  email: 'E-mail',
  action: 'O akú službu máte záujem?',
};

export const defaultPlaceholders: FormTexts = {
  fullName: 'meno a priezvisko',
  phone: 'váš telefonický kontakt',
  email: 'váš e-mail',
  action: 'vyberte si prosím zo zoznamu',
};

export const defaultSuccessMessage: UseToastOptions = {
  title: 'Ďakujeme za Vašu objednávku.',
  description: 'Budeme Vás kontaktovať čo najskôr.',
  status: 'success',
  duration: 9000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Odoslať formulár',
  loadingText: 'Odosielam',
  colorScheme: 'black',
};
