import { MortgageCalculatorResultItemProps } from '../components/MortgageCalculatorResultsItem';
import { FormValues } from '../model/form-model';

export const DEFAULT_INPUT_VALUES = {
  propertyValue: 0,
  amount: 0,
  dueYears: 30,
  interest: '2.5',
  fees: 0,
  fixationPeriod: 0,
};

export const getInstalemntAmount = (input: FormValues) => {
  const interest = Number(input.interest.replace(',', '.'));
  const monthlyInterest = interest / 12 / 100;
  const installmentsNumber = input.dueYears * 12;
  const numerator = input.amount * monthlyInterest;
  const denominator = 1 - (1 / (1 + monthlyInterest)) ** installmentsNumber;
  const monthlyInstallment = numerator / denominator;

  return monthlyInstallment.toFixed(2);
};

export const getMortgageResults = (input: FormValues) => {
  const interest = Number(input.interest.replace(',', '.'));

  const monthlyInterest = interest / 12 / 100;
  const installmentsNumber = input.dueYears * 12;
  const numerator = input.amount * monthlyInterest;
  const denominator = 1 - (1 / (1 + monthlyInterest)) ** installmentsNumber;
  const monthlyInstallment = numerator / denominator;
  const total = monthlyInstallment * installmentsNumber;
  const totalWithFees = total + Number(input.fees);
  const totalInterest = total - input.amount;
  const ltv = (input.amount / input.propertyValue) * 100;
  // https://stackoverflow.com/questions/11804659/annual-percent-rate-need-advice
  // rpmn = apm = annual percentage rate
  let fv =
    (Number(input.amount) + Number(input.fees)) *
    (1 + interest / 100 / 12) ** 12;
  const rpmn = ((fv - input.amount) / input.amount) * 100;

  const results: MortgageCalculatorResultItemProps[] = [
    {
      label: 'RPMN',
      value: `${rpmn.toFixed(2)} %`,
    },
    {
      label: 'LTV',
      value: `${ltv.toFixed(2)} %`,
    },
    {
      label: 'Mesačná splátka',
      value: `${monthlyInstallment.toFixed(2)} €`,
    },
    {
      label: 'Počet splátok',
      value: `${installmentsNumber}`,
    },
    {
      label: 'Úroky celkom',
      value: `${totalInterest
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`,
    },
    {
      label: 'Zaplatené celkom',
      value: `${totalWithFees
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`,
    },
  ];

  return results;
};
