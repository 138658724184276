import Link, { LinkProps } from 'next/link';
import { IconButton, IconButtonProps } from '../IconButton/IconButton';

export interface IconButtonLinkProps extends LinkProps {
  buttonProps: IconButtonProps;
  external?: boolean;
  ariaLabel?: string;
}

export const IconButtonLink = (props: IconButtonLinkProps) => {
  const { buttonProps, ariaLabel, external = false, ...restOfProps } = props;

  return (
    <Link {...restOfProps}>
      <a
        target={external ? '_blank' : '_self'}
        rel={external ? 'noreferrer' : 'same-origin'}
        aria-label={ariaLabel}
      >
        <IconButton {...buttonProps} />
      </a>
    </Link>
  );
};
