import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  firstName: 'Meno',
  lastName: 'Priezvisko',
  email: 'E-mail',
  phone: 'Tel. č.',
  birthDate: 'Váš dátum narodenia',
  address: 'Poštová adresa',
  password: 'Heslo',
  confirmPassword: 'Potvrdenie hesla',
};

export const defaultPlaceholders: FormTexts = {
  firstName: 'meno',
  lastName: 'priezvisko',
  email: 'email',
  phone: 'tel. č.',
  birthDate: 'dátum narodenia (deň, mesiac, rok)',
  address: 'poštová adresa',
  password: 'heslo (min 8 znakov)',
  confirmPassword: 'zopakujte heslo',
};

export const defaultErrorsMessage: UseToastOptions = {
  title: 'Zadaný email už existuje.',
  status: 'error',
  duration: 4000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Registrovať sa',
  loadingText: 'Overujem email',
  colorScheme: 'black',
};
