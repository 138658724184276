import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Heading } from '../Heading/Heading';
import { Underline } from '../Underline/Underline';

// @ts-ignore

export interface SectionHeadingProps extends BoxProps {
  // @ts-ignore
  title: string | ReactNode;
  subtitle?: string;
  description?: string;
  descriptionProps?: BoxProps;
}

export const SectionHeading = (props: SectionHeadingProps) => {
  const {
    title,
    subtitle,
    description,
    alignItems = { base: 'center', sm: 'flex-start' },
    textAlign = { base: 'center', sm: 'left' },
    descriptionProps,
    ...restOfProps
  } = props;
  return (
    <Stack
      alignItems={alignItems}
      textAlign={textAlign}
      spacing={{ base: 1, md: 2 }}
      {...restOfProps}
    >
      <Heading>{title}</Heading>
      {subtitle && (
        <Box
          fontSize={{ base: 'sm', sm: 'md', md: 'xl' }}
          fontWeight={500}
          display={{ base: 'none', sm: 'bloc' }}
        >
          {subtitle}
        </Box>
      )}

      <Underline w={28} />

      {description && (
        <Box pt={1.5}>
          <Text
            as="p"
            fontSize={{ base: 'sm', xl: 'md' }}
            color="grey.500"
            {...descriptionProps}
          >
            {description}
          </Text>
        </Box>
      )}
    </Stack>
  );
};
