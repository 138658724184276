import { Button } from '@brick/ui-components';
import {
  Box,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { GdprAgreement } from '../../components/Agreements/GdprAgreement';
import { FormControlGroup } from '../../components/FormControls/FormControlGroup';
import { FormControlGroupSelect } from '../../components/FormControls/FormControlGroupSelect';
import { validation } from '../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  defaultSuccessMessage,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const ConsultationForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitFormMutation,
    actionOptions,
  } = props;

  const toast = useToast();
  const methods = useForm<FormValues>({});

  const { mutateAsync, isLoading } = submitFormMutation();

  const onSubmit = async (data: FormValues) => {
    await mutateAsync({ input: data });
    toast(defaultSuccessMessage);
    methods.reset();
  };

  const agreementInline = useBreakpointValue({ base: false, md: true });

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }}>
          <SimpleGrid
            columns={{ md: 1, lg: 1 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4, md: 2, lg: 4 }}
          >
            <FormControlGroup
              name="fullName"
              label={labels.fullName}
              placeholder={placeholders.fullName}
              validation={validation.required}
              labelProps={{ color: 'white' }}
              inputProps={{ borderColor: 'white' }}
            />
            <FormControlGroup
              name="phone"
              label={labels.phone}
              placeholder={placeholders.phone}
              validation={validation.phone}
              labelProps={{ color: 'white' }}
              inputProps={{ borderColor: 'white' }}
            />
            <FormControlGroup
              name="email"
              label={labels.email}
              placeholder={placeholders.email}
              validation={validation.email}
              labelProps={{ color: 'white' }}
              inputProps={{ borderColor: 'white' }}
            />

            <FormControlGroupSelect
              options={actionOptions}
              name="action"
              label={labels.action}
              placeholder={placeholders.action}
              validation={validation.required}
              labelProps={{ color: 'white' }}
              selectProps={{ borderColor: 'white' }}
            />
          </SimpleGrid>

          <GdprAgreement
            color="white"
            linkColor="black.500"
            fontWeight={500}
            inline={agreementInline}
            checkboxProps={{ border: 'none' }}
          />
          <Box alignSelf={'center'}>
            <Button
              type="submit"
              colorScheme={submitButton.colorScheme}
              isLoading={isLoading}
              loadingText={submitButton.loadingText}
              alignSelf={{ base: 'center', md: 'flex-end' }}
            >
              {submitButton.text}
            </Button>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};
