import { Box, BoxProps } from '@chakra-ui/react';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';
import {
  ServicesBanners,
  ServicesBannersProps,
} from './components/ServicesBanners';

// @ts-ignore

export interface ServicesBannersSectionProps extends BoxProps {
  sectionHeading: SectionHeadingProps;
  servicesBanners: ServicesBannersProps;
}

export const ServicesBannersSection = (props: ServicesBannersSectionProps) => {
  const { sectionHeading, servicesBanners, ...restOfProps } = props;
  return (
    <Box {...restOfProps} w="full" className="no-print">
      <Container>
        <SectionHeading
          {...sectionHeading}
          textAlign="center"
          alignItems={'center'}
          mb={8}
        />
        <ServicesBanners {...servicesBanners} />
      </Container>
    </Box>
  );
};
