import { FormSubmitButton } from '../../../../model/forms';
import { FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  currentPassword: 'Aktuálne heslo',
  newPassword: 'Nové heslo',
  newPasswordConfirmation: 'Potvrdenie hesla',
};

export const defaultPlaceholders: FormTexts = {
  currentPassword: 'aktuálne heslo',
  newPassword: 'nové heslo (min. 8 znakov)',
  newPasswordConfirmation: 'zopakujte heslo',
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Zmeniť heslo',
  loadingText: 'Ukladám',
  colorScheme: 'black',
};

export const note =
  'Po kliknutí na tlačidlo “Zmeniť heslo” sa zmení Vaše heslo. Pre vykonanie tejto akcie, musíte zadať Vaše aktuálne heslo.';
