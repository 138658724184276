import { LinkModel } from '@brick/model';
import { Box, FlexProps, Stack } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { ButtonLink, ButtonLinkProps } from '../ButtonLink/ButtonLink';
import { IconButton } from '../IconButton/IconButton';
import { Underline } from '../Underline/Underline';

export interface AgentCardProps extends FlexProps {
  image: string;
  fullName: string;
  title?: string;
  linkItems: LinkModel[];
  cta?: ButtonLinkProps;
  priority?: boolean;
}

export const AgentCard = (props: AgentCardProps) => {
  const {
    image,
    fullName,
    title,
    linkItems,
    cta,
    priority = false,
    ...restOfProps
  } = props;
  return (
    <Box
      boxShadow="lg"
      padding={4}
      py={6}
      border="1px"
      borderColor="grey.100"
      w="full"
      {...restOfProps}
    >
      <Stack alignItems={'center'} spacing={4}>
        {image && (
          <Box w={'full'} h={{ base: 48, sm: 80, md: 48 }} position="relative">
            <Image
              src={image}
              layout="fill"
              objectFit={'contain'}
              priority={priority}
            />
          </Box>
        )}
        <Stack textAlign={'center'} w="full">
          <Box>
            <Box fontSize={'xl'} fontWeight={500}>
              {fullName}
            </Box>
            <Box fontSize={'md'} color="grey.500">
              {title}
            </Box>
          </Box>
          <Underline />
        </Stack>
        <Stack
          spacing={3}
          px={3}
          pb={1}
          w="full"
          alignItems={{ sm: 'center', md: 'flex-start' }}
        >
          {linkItems.map((item, i) => (
            <Link href={item.href} key={i}>
              <a>
                <IconButton
                  {...item}
                  label={
                    <Box color="black.500" fontWeight={300} pl={1}>
                      {item.label}
                    </Box>
                  }
                />
              </a>
            </Link>
          ))}
        </Stack>
        {cta && (
          <ButtonLink
            {...cta}
            buttonProps={{
              colorScheme: 'black',
            }}
          />
        )}
      </Stack>
    </Box>
  );
};
