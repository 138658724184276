import {
  Box,
  BoxProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

export interface InfoPopoverProps extends BoxProps {
  header?: string | ReactNode;
  body: string | ReactNode;
  footer?: string | ReactNode;
}

export const InfoPopover = (props: InfoPopoverProps) => {
  const { body, header, footer, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Popover placement="bottom" closeOnBlur={true}>
        {/* TRIGGER */}
        <PopoverTrigger>
          <Box pt={1} pb={'5px'} px={2} cursor={'pointer'}>
            <FaInfoCircle />
          </Box>
        </PopoverTrigger>
        {/* POPOVER */}
        <PopoverContent
          // rounded={'none'}
          color="white"
          bg="#000000dd"
          borderColor="black"
          fontSize="sm"
        >
          <PopoverHeader pt={2} fontWeight="bold" border="0">
            {header}
          </PopoverHeader>
          <PopoverArrow bg={'#000000dd'} />
          <PopoverCloseButton />
          <PopoverBody pb={4} pt={2}>
            {body}
          </PopoverBody>
          {footer && <PopoverFooter>{footer}</PopoverFooter>}
        </PopoverContent>
      </Popover>
    </Box>
  );
};
