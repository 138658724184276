import Link, { LinkProps } from 'next/link';
import { Note, NoteProps } from '../Note/Note';

export interface NoteLinkProps extends LinkProps {
  noteProps: NoteProps;
  external?: boolean;
}

export const NoteLink = (props: NoteLinkProps) => {
  const { href, noteProps, external, ...restOfProps } = props;
  return (
    <Link href={href} {...restOfProps}>
      <a
        target={external ? '_blank' : '_self'}
        rel={external ? 'noreferrer' : 'same-origin'}
      >
        <Note {...noteProps} fontWeight={700} />
      </a>
    </Link>
  );
};
