import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { BlogCard, BlogCardProps } from './components/BlogCard';
import { BlogCardSkeleton } from './components/BlogCardSkeleton';

export interface BlogListProps extends BoxProps {
  blogs?: BlogCardProps[];
}

export const BlogList = (props: BlogListProps) => {
  const { blogs, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Stack spacing={8}>
        {blogs ? (
          blogs?.map((blog, i) => <BlogCard {...blog} key={i} />)
        ) : (
          <>
            <BlogCardSkeleton w="full" />
            <BlogCardSkeleton w="full" />
            <BlogCardSkeleton w="full" />
          </>
        )}
      </Stack>
    </Box>
  );
};
