import { beep } from '@brick/utils';
import { SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlGroup } from '../../../components/FormControls/FormControlGroup';
import { SubmitSection } from '../../../components/SubmitSection/SubmitSection';
import { defaultErrorsToast, settingsSavedToast } from '../../../const/toasts';
import { validation } from '../../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const BiddingForm = memo((props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    defaultValues = {},
    settings,
    note,
    submitFormMutation,
  } = props;

  const methods = useForm<FormValues>({ defaultValues });
  const toast = useToast();
  const { mutateAsync, isLoading } = submitFormMutation();
  const { licitationId, ...numberInputSettings } = settings;

  const onSubmit = async (data: FormValues) => {
    const bidAmountAsNumber = Number(data.bidAmount.split(',').join(''));
    methods.reset();
    const input = {
      licitationId: licitationId,
      bidAmount: bidAmountAsNumber,
      authorizationCode: data.authorizationCode,
    };

    try {
      const data = await mutateAsync({ input: input });
      if (data?.createBid) {
        toast({ ...settingsSavedToast, title: 'Prihodenie úspešné!' });
        beep();
      } else {
        toast(defaultErrorsToast);
      }
    } catch (e) {
      // @ts-ignore
      if (e.message === 'Bid amount is too low!') {
        toast({
          ...defaultErrorsToast,
          title: 'Hodnota prihodenia je príliš nízka!',
          description: `Minimálna hodnota prihodenia je ${numberInputSettings.min.toLocaleString()}  €`,
        });
        // @ts-ignore
      } else if (e.message === 'Bid amount is too high!') {
        toast({
          ...defaultErrorsToast,
          title: 'Hodnota prihodenia je príliš vysoká!',
          description: `Maximálna hodnota prihodenia je ${numberInputSettings.max.toLocaleString()}  €`,
        });
        // @ts-ignore
      } else if (e.message === 'Licitation has ended!') {
        toast({
          ...defaultErrorsToast,
          title: 'Licitácia už skončila.',
          description: 'Prosím kontaktujte nás v prípade otázok.',
        });
        // @ts-ignore
      } else if (e.message === 'Wrong authorization code!') {
        toast({
          ...defaultErrorsToast,
          title: 'Nesprávny autorizačný kód.',
          description: 'Prosím kontaktujte nás v prípade otázok.',
        });
        // @ts-ignore
      } else if (e.message === 'You can not outbid yourself!') {
        toast({
          ...defaultErrorsToast,
          title: 'Posledné prihodenie je Vaše.',
          description: 'Nemôžete prebiť sám seba.',
        });
      } else {
        toast(defaultErrorsToast);
      }
      return;
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 6 }}>
          <SimpleGrid
            columns={{ md: 2 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4, md: 2, lg: 4 }}
          >
            <FormControlGroup
              name="bidAmount"
              label={labels.bidAmount}
              // inputProps={{}}
              labelProps={{
                fontSize: 'lg',
                fontWeight: 500,
                color: 'black.500',
                mb: 1.5,
              }}
              placeholder={`${
                placeholders.bidAmount
              } ${numberInputSettings.defaultValue.toLocaleString()} €`}
              validation={{
                ...validation.required,
                max: {
                  value: numberInputSettings.max,
                  message: `Maximálna hodnota prihodenia je ${numberInputSettings.max} €. Sumu napíšte bez medzery!`,
                },
                min: {
                  value: numberInputSettings.min,
                  message: `Minimálna hodnota prihodenia je ${numberInputSettings.min} €. Sumu napíšte bez medzery!`,
                },
                ...validation.float,
                maxLength: {
                  value: 10,
                  message: `Maximálna hodnota prihodenia je ${numberInputSettings.max} €. Sumu napíšte bez medzery!`,
                },
              }}
            />
            <FormControlGroup
              name="authorizationCode"
              label={labels.authorizationCode}
              labelProps={{
                fontSize: 'lg',
                fontWeight: 500,
                color: 'black.500',
                mb: 1.5,
              }}
              placeholder={placeholders.authorizationCode}
              validation={{
                required: {
                  value: true,
                  message: 'Prosím zadajte autorizačný kód.',
                },
                minLength: {
                  value: 4,
                  message: 'Kód obsahuje minimálne 4 znaky.',
                },
              }}
            />
          </SimpleGrid>
          <SubmitSection
            submitButton={submitButton}
            isLoading={isLoading}
            note={note}
            indentNote={false}
          />
        </Stack>
      </form>
    </FormProvider>
  );
});
