import { PropertyCardProps } from '@brick/model';
import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import { PropertyBanner } from '../../Property/PropertyBanner';

export const HeroPropertySliderItem = (props: PropertyCardProps) => {
  const {
    image,
    title,
    items,
    attributes,
    price,
    cta,
    imageQuality = 40,
    ...restOfProps
  } = props;
  // const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Box {...restOfProps}>
      <Box h={{ base: 220, sm: 300, md: 440 }} position={'relative'}>
        <Image
          src={image}
          alt={'hero-slider'}
          layout="fill"
          objectFit="cover"
          quality={imageQuality}
          priority
          objectPosition={'bottom'}
        />
        <PropertyBanner
          display={{ base: 'none', md: 'flex' }}
          title={title}
          items={items}
          attributes={attributes}
          price={price}
          cta={cta}
          position="absolute"
          bottom={-4}
          left={'12%'}
        />
      </Box>
      <Box display={{ base: 'block', md: 'none' }}>
        <PropertyBanner
          w="full"
          title={title}
          items={items}
          attributes={attributes}
          price={price}
          cta={cta}
        />
        .
      </Box>
    </Box>
  );
};
