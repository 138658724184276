import { AspectRatio, Box, BoxProps, Text } from '@chakra-ui/react';
import Link from 'next/link';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Heading } from '../Heading/Heading';
import { TickList } from '../TickList/TickList';

export interface MarkdownRendererProps extends BoxProps {
  children?: string;
  useTickForUl?: boolean;
}

// OLD MARKDOWN RENDERER - KEEP THIS FOR REFERENCE / BACKUP

export const MarkdownRenderer = (props: MarkdownRendererProps) => {
  const {
    children,
    color,
    textAlign,
    fontSize = 'sm',
    useTickForUl,
    ...restOfProps
  } = props;

  return (
    <Box {...restOfProps} color={color || 'black.500'} fontSize={fontSize}>
      {children && (
        <ReactMarkdown
          // for html (iframes)
          rehypePlugins={[rehypeRaw]}
          components={{
            h1: ({ children }) => (
              <Heading as="h1" color={color || 'secondary.500'}>
                {children}
              </Heading>
            ),
            h2: ({ children }) => (
              <Text
                fontSize={{ base: 'lg', md: 'xl' }}
                fontWeight={500}
                as="h2"
                mt={6}
                mb={{ base: 1, md: 0 }}
              >
                {children}
              </Text>
            ),
            h3: ({ children }) => (
              <Text
                as="h3"
                fontSize="md"
                fontWeight="bold"
                color={color || 'secondary.500'}
              >
                {children}
              </Text>
            ),
            h4: ({ children }) => (
              <Text as="h4" fontSize="md" fontWeight="bold">
                {children}
              </Text>
            ),
            p: ({ children }) => {
              // hack for rendering ul copied from raynet
              if (
                children &&
                children.length > 0 &&
                typeof children[0] === 'string' &&
                children[0].includes('•')
              ) {
                const items = children[0].split('•');
                return (
                  <Box py={2}>
                    <Box>{items[0]}</Box>
                    <TickList
                      items={items.slice(1)}
                      icon={useTickForUl ? '✓' : '•'}
                      color={useTickForUl ? 'grey.500' : 'black.500'}
                    />
                  </Box>
                );
              } else {
                return (
                  <Text as="p" my={{ base: 2, md: 2 }} textAlign={textAlign}>
                    {children}
                  </Text>
                );
              }
            },
            // special indent list
            code: ({ children }) => {
              // @ts-ignore
              let items = children[0].split('\n');
              items = items
                .map((i: any) => i.replace('-', '').trim())
                .filter((i: any) => !!i);
              return (
                <TickList
                  color="black.500"
                  ml={4}
                  mt={-1}
                  fontFamily={'Roboto'}
                  icon={'-'}
                  items={items}
                />
              );
            },
            ul: ({ children }) => (
              <TickList
                icon={useTickForUl ? '✓' : '•'}
                color={useTickForUl ? 'grey.500' : 'black.500'}
                items={children
                  // @ts-ignore
                  .filter((child) => child?.props)
                  // @ts-ignore
                  .map((child) => child?.props?.children[0])}
              />
            ),

            strong: ({ children }) => (
              <Text as="strong" fontWeight="500" color={color || 'primary.500'}>
                {children}
              </Text>
            ),
            iframe: (data) => {
              if (!data?.src) {
                return <div></div>;
              }
              return (
                <AspectRatio ratio={1.78} maxWidth={560}>
                  <iframe src={data.src} width={'100%'} />
                </AspectRatio>
              );
            },
            a: (props) => {
              let isExternal = !(
                props?.href?.includes('tca.sk') ||
                props?.href?.includes('tca.vercel.app') ||
                props?.href?.includes('localhost') ||
                (props?.href && props?.href[0] === '#')
              );

              const label = (
                <Text
                  as="strong"
                  fontWeight="500"
                  color={color || 'primary.500'}
                  _hover={{
                    color: 'primary.600',
                    transition: 'all ease-in-out 200ms',
                  }}
                >
                  {props.children}
                </Text>
              );

              if (isExternal) {
                return (
                  <a
                    href={props.href}
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                  >
                    {label}
                  </a>
                );
              } else {
                return (
                  <Link href={props.href || '#'}>
                    <a>{label}</a>
                  </Link>
                );
              }
            },
          }}
        >
          {children}
        </ReactMarkdown>
      )}
    </Box>
  );
};
