import { LinkModel } from '@brick/model';
import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { default as Image } from 'next/image';
import { IconButton } from '../IconButton/IconButton';

export interface TinyAgentCardProps extends BoxProps {
  fullName: string;
  image?: string;
  link?: LinkModel;
}

export const TinyAgentCard = (props: TinyAgentCardProps) => {
  const { fullName, link, image, ...restOfProps } = props;
  return (
    <Box
      boxShadow={{ md: 'lg' }}
      px={{ base: 0, md: 4 }}
      py={{ base: 2, md: 4 }}
      border={{ md: '1px grey.100' }}
      w="full"
      {...restOfProps}
    >
      <Stack alignItems={'center'} spacing={2}>
        {image && (
          <Box w={'full'} h={{ base: 48, sm: 80, md: 36 }} position="relative">
            <Image
              src={image}
              layout="fill"
              objectFit={'contain'}
              quality={80}
            />
          </Box>
        )}
        <Box fontSize={{ base: 'lg', lg: 'xl' }} fontWeight={500}>
          {fullName}
        </Box>

        {link && (
          <IconButton
            {...link}
            label={
              <Box color="black.500" fontWeight={300}>
                {link.label}
              </Box>
            }
          />
        )}
      </Stack>
    </Box>
  );
};
