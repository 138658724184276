import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import React from 'react';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';
import { PerkCardProps } from './PerkCard';
import { PerksGrid } from './PerksGrid';
import { PerksSliderProps } from './PerksSlider';

// @ts-ignore

export interface PerksSectionProps extends BoxProps {
  perks: PerkCardProps[];
  heading: SectionHeadingProps;
}

export const PerksSection = (props: PerksSectionProps) => {
  const { perks, heading, ...restOfProps } = props;

  // avoid importing slider on desktop - using dynamic import
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  let PerksSlider: React.ComponentType<PerksSliderProps> | undefined;
  if (!isDesktop) {
    PerksSlider = dynamic<PerksSliderProps>(() =>
      import('./PerksSlider').then((mod) => mod.PerksSlider)
    );
  }

  return (
    <Box {...restOfProps}>
      <Container>
        <SectionHeading {...heading} mb={{ base: 2, md: 8 }} />
        {PerksSlider ? (
          <PerksSlider perks={perks} />
        ) : (
          <PerksGrid perks={perks} />
        )}
      </Container>
    </Box>
  );
};
