import { SectionHeading, SectionHeadingProps } from '@brick/ui-components';
import { Box, BoxProps } from '@chakra-ui/react';
import { MarkdownRenderer } from 'libs/ui-components/src/lib/Elements/ContentRenderer/MarkdownRenderer';
import { validation } from '../../const/validation/validation';
import { FormControlGroupCheckbox } from '../FormControls/FormControlGroupCheckbox';

export interface AgreementTextSectionProps extends BoxProps {
  sectionHeading: SectionHeadingProps;
  text: string;
  label: string;
  name: string;
}

export const AgreementTextSection = (props: AgreementTextSectionProps) => {
  const { text, sectionHeading, label, name, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <SectionHeading {...sectionHeading} />
      <MarkdownRenderer maxH={800} mb={{ base: 6 }} overflowY="scroll">
        {text}
      </MarkdownRenderer>

      <FormControlGroupCheckbox
        labelNode={label}
        name={name}
        validation={validation.termsAgreement}
      />
    </Box>
  );
};
