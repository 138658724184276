import { Box, BoxProps, Flex, useBreakpointValue } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import 'react-slick';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ChevronSliderArrow } from './components/ChevronSliderArrow';

export interface ChevronSliderProps extends BoxProps {
  children: ReactNode | ReactNode[];
  settings?: Settings;
}

const defaultSettings: Settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 8000,
  infinite: true,
  arrows: false, // arrows are handled completely custom
  dots: false,
  responsive: [
    {
      breakpoint: 1128,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

export const ChevronSlider = (props: ChevronSliderProps) => {
  const { children, ...restOfProps } = props;
  const settings = { ...defaultSettings, ...props.settings };
  const sliderRef = React.createRef<Slider>();

  const [isMobile, setisMobile] = useState(true);
  const isMobileBreakpoint = useBreakpointValue({ base: true, sm: false });

  useEffect(() => {
    setisMobile(!!isMobileBreakpoint);
  }, [isMobileBreakpoint]);

  return (
    <Box {...restOfProps}>
      <Flex alignItems={'center'} justifyContent={'center'}>
        {!isMobile && (
          <ChevronSliderArrow
            type="prev"
            onClick={() => sliderRef?.current?.slickPrev()}
          />
        )}
        <Box overflowY={'hidden'} h="full" pb={{ base: 8, md: 0 }} flex={1}>
          <Slider {...settings} ref={sliderRef}>
            {children}
          </Slider>
        </Box>
        {!isMobile && (
          <ChevronSliderArrow
            type="next"
            onClick={() => sliderRef?.current?.slickNext()}
          />
        )}
      </Flex>
    </Box>
  );
};
