import { PropertyCardProps } from '@brick/model';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { ArrowLink } from '../Elements/ArrowLink/ArrowLink';
import { TickList } from '../Elements/TickList/TickList';
import { PropertyAttributes } from './PropertyAttributes/PropertyAttributes';
import { PropertyPriceLabel } from './PropertyPriceLabel';

export const PropertyCard = (props: PropertyCardProps) => {
  const { title, items, attributes, price, cta, image, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Link href={cta.href}>
        <a>
          <Stack
            w="full"
            bg="white"
            h={520}
            boxShadow="lg"
            spacing={0}
            cursor={'pointer'}
            _hover={{ boxShadow: 'xl', transition: 'all ease-in-out 300ms' }}
          >
            {image && (
              <Box position="relative" h={{ base: 52, sm: 80, md: 440 }}>
                <Image
                  src={image}
                  alt={title}
                  layout="fill"
                  quality={48}
                  objectFit="cover"
                  objectPosition={'100% 100%'}
                />
              </Box>
            )}
            <Stack p={4} spacing={2} justifyContent="space-between" h="full">
              <Text
                fontWeight="600"
                fontSize={{ base: 'lg', md: 'lg' }}
                noOfLines={2}
              >
                {title}
              </Text>
              <TickList items={items} />
              <Box>
                <PropertyAttributes {...attributes} pb={2} pt={2} />
                <Flex
                  w="full"
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <PropertyPriceLabel label={price} />
                  <ArrowLink
                    href={cta.href}
                    label={cta.label}
                    buttonProps={{ color: 'blak.500' }}
                  />
                </Flex>
              </Box>
            </Stack>
          </Stack>
        </a>
      </Link>
    </Box>
  );
};
