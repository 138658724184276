import { Box, BoxProps, Stack } from '@chakra-ui/react';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../../Elements/SectionHeading/SectionHeading';
// @ts-ignore

export interface BillingInfoProps extends BoxProps {
  items: string[];
  heading?: SectionHeadingProps;
}

export const BillingInfo = (props: BillingInfoProps) => {
  const { heading, items, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      {heading && (
        <SectionHeading
          textAlign="left"
          alignItems={'flex-start'}
          {...heading}
          mb={{ base: 4, md: 6 }}
        />
      )}

      <Stack>
        <Box fontWeight={600}>{items[0]}</Box>
        {items.slice(1).map((line) => (
          <Box key={line}>{line}</Box>
        ))}
      </Stack>
    </Box>
  );
};
