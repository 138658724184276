import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconItem } from '../IconItem/IconItem';

export interface TickListProps extends BoxProps {
  icon?: string | ReactNode;
  items: string[];
}

export const TickList = (props: TickListProps) => {
  const { items, icon = '✓', ...restOfProps } = props;
  return (
    <Box color="grey.500" {...restOfProps}>
      <Stack spacing={0}>
        {items.map((i) => (
          <IconItem label={i} icon={icon} key={i} />
        ))}
      </Stack>
    </Box>
  );
};
