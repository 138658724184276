import { SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlGroup } from '../../../components/FormControls/FormControlGroup';
import { SubmitSection } from '../../../components/SubmitSection/SubmitSection';
import { defaultErrorsToast, settingsSavedToast } from '../../../const/toasts';
import { validation } from '../../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const UpdateAccountForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    defaultValues = {},
    submitFormMutation,
  } = props;

  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const toast = useToast();
  const { mutateAsync, isLoading } = submitFormMutation();

  const onSubmit = async (input: FormValues) => {
    try {
      const data = await mutateAsync({ input });
      if (data?.updateUsersPermissionsMe) {
        toast(settingsSavedToast);
        window.location.reload();
      } else {
        toast(defaultErrorsToast);
      }
    } catch (e) {
      toast(defaultErrorsToast);
      return;
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 6 }}>
          <SimpleGrid
            columns={{ md: 1, lg: 1 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4, md: 2, lg: 4 }}
          >
            <FormControlGroup
              name="firstName"
              label={labels.firstName}
              placeholder={placeholders.firstName}
              validation={validation.required}
            />
            <FormControlGroup
              name="lastName"
              label={labels.lastName}
              placeholder={placeholders.lastName}
              validation={validation.required}
            />

            <FormControlGroup
              name="email"
              label={labels.email}
              placeholder={placeholders.email}
              validation={validation.email}
            />
            <FormControlGroup
              name="phone"
              label={labels.phone}
              placeholder={placeholders.phone}
              validation={validation.phone}
            />
            <FormControlGroup
              name="birthDate"
              label={labels.birthDate}
              placeholder={placeholders.birthDate}
              validation={validation.date}
            />
            <FormControlGroup
              name="address"
              label={labels.address}
              placeholder={placeholders.address}
              // validation={validation.required}
            />
          </SimpleGrid>
          <SubmitSection
            submitButton={submitButton}
            isLoading={isLoading}
            note="Pre zmenu údaju rozkliknite príslušný riadok. Po kliknutí na tlačidlo “Zmeniť údaje” sa údaje aktualizujú."
          />
        </Stack>
      </form>
    </FormProvider>
  );
};
