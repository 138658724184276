import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import { ArrowLink, ArrowLinkProps } from '../Elements/ArrowLink/ArrowLink';
import {
  PropertyAttributes,
  PropertyAttributesProps,
} from './PropertyAttributes/PropertyAttributes';
import { PropertyPriceLabel } from './PropertyPriceLabel';

export interface PropertyBannerProps extends BoxProps {
  title: string;
  items: string[];
  attributes: PropertyAttributesProps;
  price: string;
  cta: ArrowLinkProps;
}

export const PropertyBanner = (props: PropertyBannerProps) => {
  const { title, items, attributes, price, cta, ...restOfProps } = props;
  return (
    <Box
      bg="rgba(255, 255, 255, 0.78)"
      boxShadow={'lg'}
      mb={12}
      {...restOfProps}
    >
      <Stack p={{ base: 4, md: 4 }}>
        <Box fontWeight="600" fontSize={{ base: 'lg', md: 'lg' }}>
          {title}
        </Box>
        {/* <TickList items={items} /> */}
        <Flex
          w="full"
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={'center'}
          pt={2}
        >
          <PropertyAttributes
            {...attributes}
            w={{ base: 'full', md: 80 }}
            iconSize={40}
          />
          <Flex
            justifyContent={'space-between'}
            alignItems="center"
            mt={{ base: 4, sm: 6, md: 0 }}
            ml={{ md: 6 }}
            w={{ base: 'full', md: 'auto' }}
          >
            <PropertyPriceLabel label={price} mr={3} w={{ base: 36 }} />
            <ArrowLink
              href={cta.href}
              label={cta.label}
              buttonProps={{
                color: 'black.500',
                fontSize: { base: 'lg', md: 'lg' },
              }}
            />
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};
