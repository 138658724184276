import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { ArrowLink } from '../../../Elements/ArrowLink/ArrowLink';

export interface BlogCardProps extends BoxProps {
  title: string;
  subtitle: string;
  description: string;
  image?: string;
  href: string;
  external?: boolean;
}

export const BlogCard = (props: BlogCardProps) => {
  const {
    title,
    subtitle,
    description,
    image,
    href,
    external = false,
    ...restOfProps
  } = props;

  return (
    <Link href={href}>
      <a
        target={external ? '_blank' : '_self'}
        rel={external ? 'noreferrer' : 'same-origin'}
      >
        <Flex
          {...restOfProps}
          w="full"
          bg="grey.100"
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          boxShadow="lg"
          _hover={{ boxShadow: 'xl', transition: 'all ease-in-out 300ms' }}
          fontSize={'sm'}
        >
          {image && (
            <Box
              position="relative"
              h={{ base: 60, sm: 80, md: 48 }}
              flex={{ base: 'auto', md: 1 }}
            >
              <Image
                src={image}
                alt={title}
                layout="fill"
                objectFit="cover"
                quality={10}
              />
            </Box>
          )}
          <Flex
            justifyContent="space-between"
            flexDir="column"
            w={{ base: 'full', md: '68%' }}
            p={{ base: 4, md: 4 }}
          >
            <Box>
              <Box
                fontWeight="600"
                textTransform="uppercase"
                fontSize={{ base: 'lg', md: 'xl' }}
              >
                {title}
              </Box>
              <Box
                mb={{ base: 1, md: 1 }}
                color="grey.500"
                fontWeight={{ base: 'xs', md: 'md' }}
              >
                {subtitle}
              </Box>
              <Text textAlign={'left'} fontSize="md" noOfLines={4}>
                {description}
              </Text>
            </Box>
            <Box alignSelf={'flex-end'} mt={2}>
              <ArrowLink href={href} label="Celý článok" external={external} />
            </Box>
          </Flex>
        </Flex>
      </a>
    </Link>
  );
};
