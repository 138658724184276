import { Button, Note } from '@brick/ui-components';
import { Box, Flex, SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlGroup } from '../../../components/FormControls/FormControlGroup';
import { validation } from '../../../const/validation/validation';
import {
  defaultErrorsMessage,
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  defaultSuccessMessage,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const ForgotPasswordForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitFormMutation,
  } = props;
  const methods = useForm<FormValues>({});
  const toast = useToast();
  const router = useRouter();
  const { mutateAsync, isLoading } = submitFormMutation();

  const onSubmit = async (data: FormValues) => {
    try {
      await mutateAsync({ email: data.identifier });
      methods.reset();
      toast(defaultSuccessMessage);
    } catch (e) {
      // @ts-ignore this error message is not shown to the user for security reasons
      if (e?.message === 'This email does not exist') {
        toast(defaultSuccessMessage);
      } else {
        toast(defaultErrorsMessage);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }} maxW={'md'} mx="auto">
          <SimpleGrid
            columns={{ md: 1, lg: 1 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4, md: 2, lg: 4 }}
          >
            <FormControlGroup
              name="identifier"
              label={labels.identifier}
              placeholder={placeholders.identifier}
              validation={validation.email}
            />
          </SimpleGrid>
          <Flex
            justifyContent={'space-between'}
            gridGap={{ base: 4, md: 2 }}
            flexDir={{ base: 'column', md: 'row' }}
            alignItems="center"
          >
            <Note
              ml={{ base: 0, md: -8 }}
              text="Po kliknutí na tlačidlo “Obnova hesla” Vám pošleme e-mail s linkom na obnovu hesla."
            />
            <Box width={{ base: 'full', md: 'auto' }}>
              <Button
                type="submit"
                width={'full'}
                colorScheme={submitButton.colorScheme}
                isLoading={isLoading}
                loadingText={submitButton.loadingText}
                alignSelf={{ base: 'center', md: 'flex-end' }}
              >
                {submitButton.text}
              </Button>
            </Box>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  );
};
