import { Box, BoxProps, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from '../../Elements/Button/Button';
import { TickList } from '../../Elements/TickList/TickList';
import {
  TinyAgentCard,
  TinyAgentCardProps,
} from '../../Elements/TinyAgentCard/TinyAgentCard';
import {
  PropertyAttributes,
  PropertyAttributesProps,
} from '../PropertyAttributes/PropertyAttributes';

export interface PropertyAgentBannerProps extends BoxProps {
  image?: string;
  title: string;
  subtitleKey: string;
  subtitleValue?: string;
  listItems: string[];
  attributes: PropertyAttributesProps;
  agent?: TinyAgentCardProps;
  href: string;
  external?: boolean;
  buttonText?: string;
  showButton?: boolean;
}

export const PropertyAgentBanner = (props: PropertyAgentBannerProps) => {
  const {
    image,
    title,
    subtitleKey,
    subtitleValue,
    agent,
    listItems,
    external = false,
    attributes,
    href,
    buttonText = 'Vstúpiť do licitácie',
    showButton = true,
    ...restOfProps
  } = props;
  return (
    <Link href={href}>
      <a
        target={external ? '_blank' : '_self'}
        rel={external ? 'noreferrer' : 'same-origin'}
      >
        <Box
          {...restOfProps}
          boxShadow={'md'}
          pb={{ base: 4, md: 4, lg: 6 }}
          pt={{ base: 0, md: 4, lg: 6 }}
          px={{ base: 0, md: 4, lg: 7 }}
          borderWidth={'1px'}
          borderColor={'grey.100'}
        >
          <HStack
            flexDir={{ base: 'column', md: 'row' }}
            gridGap={{ base: 4, md: 6 }}
          >
            <Stack w="full" spacing={4}>
              <Flex
                w="full"
                flexDir={{ base: 'column', md: 'row' }}
                gridGap={{ base: 4, md: 6 }}
                flex={1}
              >
                {image && (
                  <Box
                    bg="grey.500"
                    position="relative"
                    h={{ base: 48, sm: 64, md: 'auto' }}
                    w={{ base: 'full', md: '44%' }}
                  >
                    <Image
                      src={image}
                      layout="fill"
                      objectFit="cover"
                      objectPosition={'100% 100%'}
                      quality={10}
                    />
                  </Box>
                )}
                <Box flex={1} px={{ base: 4, md: 0 }}>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight={500}>
                    {title}
                  </Text>
                  {subtitleKey && (
                    <Flex fontSize={{ base: 'sm', md: 'md' }} gridGap={1}>
                      <Box>{subtitleKey}:</Box>
                      <Box color="grey.500">{subtitleValue}</Box>
                    </Flex>
                  )}
                  {listItems && <TickList items={listItems} pt={2} />}
                  {attributes && <PropertyAttributes {...attributes} pt={3} />}
                </Box>
              </Flex>
              {showButton && (
                <Flex
                  w={{ base: 'full', md: '44%' }}
                  justifyContent={'center'}
                  display={{ base: 'none', md: 'flex' }}
                >
                  <Button colorScheme={'black'}>{buttonText}</Button>
                </Flex>
              )}
            </Stack>
            {agent && (
              <TinyAgentCard
                {...agent}
                w={{ base: 'full', md: 64 }}
                display={{ sm: 'none', lg: 'block' }}
              />
            )}
            {showButton && (
              <Button
                colorScheme={'black'}
                w="90%"
                mx={4}
                display={{ md: 'none' }}
              >
                {buttonText}
              </Button>
            )}
          </HStack>
        </Box>
      </a>
    </Link>
  );
};
