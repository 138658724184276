import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface TimelineCircleProps extends BoxProps {
  icon: ReactNode;
}

export const TimelineCircle = (props: TimelineCircleProps) => {
  const { icon, ...restOfProps } = props;
  return (
    <Box
      color="white"
      
      bg="primary.500"
      p={{ base: 5, md: 6 }}
      rounded={'full'}
      boxShadow="lg"
      {...restOfProps}
    >
      {icon}
    </Box>
  );
};
