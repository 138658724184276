import { LicitationStatuses } from './../model/licitation.d';
export const BIDS_REFETCH_INTERVAL = 1000;
export const BIDS_AGENT_REFETCH_INTERVAL = 1000;
export const ACTIVE_TIMEOUT_SEC = 10;
export const USERS_AGENT_REFETCH_INTERVAL = 10000;

export const LICITATION_TERMS_AGREEMENT_LC_KEY = 'LICITATION_TERMS_AGREEMENT';
export const AGENT_ROLE_ID = 3;
export const ADMIN_ROLE_ID = 4;

