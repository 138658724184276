import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { Socials } from '../../Elements/Socials/Socials';
import { useSocialShareItems } from './hooks/useSocialShareItems';

export interface ShareLinksProps extends BoxProps {
  title?: string;
}

export const ShareLinks = (props: ShareLinksProps) => {
  const { title = 'Zdieľanie:', color = 'black.500', ...restOfProps } = props;
  const socialShareItems = useSocialShareItems();

  return (
    <Box color={color} {...restOfProps}>
      <Socials socialLinks={socialShareItems} title={title} />
    </Box>
  );
};
