import {
  ageRegex,
  dateRegex,
  emailRegex,
  floatRegex,
  integerRegex,
  passwordRegex,
  phoneRegex,
  streetAndNumberRegex,
} from './regex';

export const validation = {
  required: {
    required: {
      value: true,
      message: 'Toto pole je povinné.',
    },
  },
  age: {
    pattern: {
      value: ageRegex,
      message: 'Zadajte prosím Váš skutočný vek.',
    },
    required: {
      value: true,
      message: 'Zadajte prosím Váš vek.',
    },
  },
  integer: {
    pattern: {
      value: integerRegex,
      message: 'Zadajte prosím číslo.',
    },
    required: {
      value: true,
      message: 'Toto pole je povinné.',
    },
  },
  float: {
    pattern: {
      value: floatRegex,
      message: 'Zadajte prosím číslo bez medzery.',
    },
    required: {
      value: true,
      message: 'Toto pole je povinné.',
    },
  },
  email: {
    pattern: {
      value: emailRegex,
      message: 'Zadajte prosím Váš skutočný email.',
    },
    required: {
      value: true,
      message: 'Zadajte prosím Váš email.',
    },
  },
  phone: {
    pattern: {
      value: phoneRegex,
      message: 'Telefónne číslo je v nesprávnom tvare.',
    },
    required: {
      value: true,
      message: 'Zadajte prosím Vaše telefónne čislo.',
    },
  },
  streetAndNumber: {
    required: {
      value: true,
      message: 'Zadajte prosím adresu.',
    },
    pattern: {
      value: streetAndNumberRegex,
      message: 'Zadajte prosím aj popisné číslo.',
    },
  },
  termsAgreement: {
    required: {
      value: true,
      message: 'Musíte súhlasiť s podmienkami.',
    },
  },
  date: {
    required: {
      value: true,
      message: 'Zadajte prosím dátum.',
    },
    pattern: {
      value: dateRegex,
      message: 'Prosím zadajte dátum vo formáte DD.MM.YYYY',
    },
  },
  // auth
  password: {
    required: {
      value: true,
      message: 'Zadajte prosím Vaše heslo.',
    },
    pattern: {
      value: passwordRegex,
      message:
        'Heslo musí obsahovať aspoň 8 znakov, číslo a veľké a malé písmeno.',
    },
  },
};

export const errors = {
  passwordsDoNotMatch: { message: 'Heslá sa nezhodujú.' },
  userExists: {
    message: 'Používateľ s týmto emailom už existuje.',
  },
};
