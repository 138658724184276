import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { NoDataNotice } from '../../Elements/NoDataNotice/NoDataNotice';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';
import {
  PropertyAgentBanner,
  PropertyAgentBannerProps,
} from './PropertyAgentBanner';
import { PropertyAgentBannerSkeleton } from './PropertyAgentBannerSkeleton';

export interface PropertyAgentBannersLisitingProps extends BoxProps {
  banners: PropertyAgentBannerProps[];
  sectionHeading?: SectionHeadingProps;
  isLoading?: boolean;
}

export const PropertyAgentBannersListing = (
  props: PropertyAgentBannersLisitingProps
) => {
  const { sectionHeading, banners, isLoading, ...restOfProps } = props;

  return (
    <Box {...restOfProps}>
      <Container>
        <Box w="full">
          {sectionHeading && (
            <SectionHeading {...sectionHeading} mb={{ base: 6, md: 8 }} />
          )}
          <Stack spacing={{ base: 6, md: 10 }}>
            {isLoading ? (
              <PropertyAgentBannerSkeleton />
            ) : banners.length > 0 ? (
              banners.map((b) => (
                <PropertyAgentBanner
                  {...b}
                  key={b.href}
                  _hover={{
                    boxShadow: 'xl',
                    transition: 'all ease-in-out 100ms',
                  }}
                />
              ))
            ) : (
              <NoDataNotice
                text="Momentálne neprebieha žiadna licitácia"
                entityName=""
              />
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};
