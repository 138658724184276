import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import {
  BsCheckCircle,
  BsExclamationCircle,
  BsInfoCircle,
} from 'react-icons/bs';

export interface NoteProps extends BoxProps {
  text: string | ReactNode | ReactNode[];
  icon?: ReactNode;
  type?: 'info' | 'success' | 'error';
  noOfLines?: number;
}

export const noteTypeToStyleMap = {
  info: {
    icon: <BsInfoCircle />,
    color: 'primary.500',
  },
  success: {
    icon: <BsCheckCircle />,
    color: 'green.500',
  },
  error: {
    icon: <BsExclamationCircle />,
    color: 'primary.500',
  },
};

export const Note = (props: NoteProps) => {
  const {
    text,
    type = 'info',
    icon = noteTypeToStyleMap[type].icon,
    noOfLines,
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps} color={noteTypeToStyleMap[type].color}>
      <Flex gridColumnGap={2} alignItems="center">
        <Box fontSize="2xl">{icon}</Box>
        <Text
          fontSize={{ base: 'xs', sm: 'sm' }}
          color="grey.500"
          noOfLines={noOfLines}
        >
          {text}
        </Text>
      </Flex>
    </Box>
  );
};
