import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import { ContentRenderer } from '../../Elements/ContentRenderer/ContentRenderer';
import { Heading } from '../../Elements/Heading/Heading';
import { TimelineItemProps } from '../../Elements/Timeline/components/TimelineItem';
import { Timeline } from '../../Elements/Timeline/Timeline';
import { Container } from '../../Layout/Container/Container';

export interface CollaborationPhaseSectionProps extends BoxProps {
  timelineItems: TimelineItemProps[];
  timelineLeft?: boolean;
  title?: string;
  content: string;
}

export const CollaborationPhaseSection = (
  props: CollaborationPhaseSectionProps
) => {
  const {
    timelineItems,
    content,
    title,
    timelineLeft = false,
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps}>
      <Container>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Stack
            w={{ md: '45%', lg: '45.5%' }}
            pr={{ base: 0, md: timelineLeft ? 0 : 6 }}
            pl={{ base: 0, md: timelineLeft ? 6 : 0 }}
            spacing={0}
            order={{ md: timelineLeft ? 1 : 'auto' }}
          >
            <Heading mb={{ base: 4, md: 6 }}>{title}</Heading>
            <ContentRenderer fontSize={'sm'} useTickForUl>
              {content}
            </ContentRenderer>
          </Stack>
          <Timeline flex={1} items={timelineItems} textLeft={timelineLeft} />
        </Flex>
      </Container>
    </Box>
  );
};
