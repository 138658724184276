import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  firstName: 'Meno',
  lastName: 'Priezvisko',
  email: 'E-mail',
  phone: 'Tel. č.',
  birthDate: 'Váš dátum narodenia',
  address: 'Poštová adresa',
};

export const defaultPlaceholders: FormTexts = {
  firstName: 'meno',
  lastName: 'priezvisko',
  email: 'email',
  phone: 'tel. č.',
  birthDate: 'dátum narodenia (deň, mesiac, rok)',
  address: 'poštová adresa',
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Zmeniť údaje',
  loadingText: 'Ukladám',
  colorScheme: 'black',
};
