import { Box, BoxProps, Text } from '@chakra-ui/react';
import React from 'react';
import { IconText, IconTextProps } from '../IconText/IconText';
import { TickList } from '../TickList/TickList';

export interface FullParagraphProps extends BoxProps {
  title?: string;
  iconText?: IconTextProps;
  description?: string;
  listItems?: string[];
}

export const FullParagraph = (props: FullParagraphProps) => {
  const { title, iconText, description, listItems, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      {title && (
        <Box fontSize={{ base: 'lg', md: '2xl' }} fontWeight={500}>
          {title}
        </Box>
      )}
      {iconText && <IconText {...iconText} />}
      {listItems && <Text pt={2}>{description}</Text>}
      {listItems && <TickList items={listItems} pt={2} />}
    </Box>
  );
};
