import { Box, BoxProps, Stack } from '@chakra-ui/react';
import React from 'react';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';

export interface MapProps extends BoxProps {
  heading?: SectionHeadingProps;
  gMapsEmbedLink: string;
}

export const Map = (props: MapProps) => {
  const { gMapsEmbedLink, heading, h = 400, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Stack spacing={8}>
        {heading && (
          <Container>
            <SectionHeading {...heading} />
          </Container>
        )}
        <Box overflowY="hidden" h={h}>
          <iframe
            src={gMapsEmbedLink}
            width="100%"
            height="105%" // hide bottom text
            loading="lazy"
          ></iframe>
        </Box>
      </Stack>
    </Box>
  );
};
