import { Box, BoxProps } from '@chakra-ui/react';

export interface FetchingErrorProps extends BoxProps {}

export const FetchingError = (props: FetchingErrorProps) => {
  const { ...restOfProps } = props;
  return <div></div>;
  return (
    <Box {...restOfProps} py={400} bg="red.200" textAlign="center">
      <Box fontSize="6xl">Fetching data</Box>
      <Box fontSize="3xl">SHOULD NOT BE HAPPENING!</Box>
      <Box fontSize="xl">Check PREFETCHING!</Box>
    </Box>
  );
};
