import { Box, BoxProps, Flex, HStack } from '@chakra-ui/react';
import { IconButton } from '../../../Elements/IconButton/IconButton';
import { IconButtonLink } from '../../../Elements/IconButtonLink/IconButtonLink';
import { SocialIcons } from '../../../Elements/SocialIcons/SocialIcons';
import { TinyDivider } from '../../../Elements/TinyDivider/TinyDivider';
import { Container } from '../../Container/Container';
import { useLayoutItems } from '../../LayoutItemsProvider';
import { HeaderTopLink } from './components/HeaderTopLink/HeaderTopLink';

export interface HeaderTopProps extends BoxProps {}

export const HeaderTop = (props: HeaderTopProps) => {
  const { ...restOfProps } = props;
  const items = useLayoutItems()?.headerTop;

  return (
    <Box {...restOfProps} h={12} borderBottom={'1px'} borderColor={'grey.500'}>
      <Container>
        <Flex alignItems={'center'} h="full" w="full" justify={'space-between'}>
          <Box className="header-left" w={{ base: 4 / 10, xl: 3 / 10 }}>
            {/* USER ROUTES */}
            {items?.userRoutes && (
              <HStack spacing={3}>
                {items.userRoutes.map((cta, i) => {
                  if ('onClick' in cta) {
                    return <IconButton {...cta} key={i} />;
                  }
                  if ('href' in cta) {
                    return (
                      <IconButtonLink
                        key={i}
                        external={cta.external}
                        href={cta.href}
                        buttonProps={{
                          icon: cta.icon,
                          label: cta.label,
                        }}
                      />
                    );
                  }
                })}
              </HStack>
            )}
          </Box>
          <Flex
            className="header-right"
            h="full"
            w="full"
            justify={'space-between'}
            alignItems={'center'}
          >
            {/* CTA ROUTES */}
            {items?.ctaRoutes && (
              <HStack spacing={2} display={{ base: 'none', xl: 'flex' }}>
                {items.ctaRoutes.map((r, i) => (
                  <HStack spacing={2} key={i}>
                    <HeaderTopLink label={String(r.label)} href={r.href} />
                    {i + 1 != items?.ctaRoutes?.length && <TinyDivider />}
                  </HStack>
                ))}
              </HStack>
            )}

            {/* CONTACT LINKS */}
            <HStack spacing={4} flex={1} justifyContent={'flex-end'}>
              {items?.contactLinks?.map((link) => (
                <IconButtonLink
                  key={link.href}
                  href={link.href}
                  external={link.external}
                  buttonProps={{
                    icon: link.icon,
                    label: link.label,
                  }}
                />
              ))}

              {/* SOCIAL LINKS */}
              {items?.socialLinks && (
                <SocialIcons socialLinks={items.socialLinks} />
              )}
            </HStack>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
