import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';
import Image from 'next/image';

export interface ImageSectionHeaderProps extends BoxProps {
  title: string;
  description?: string;
  image: string;
}

export const ImageSectionHeader = (props: ImageSectionHeaderProps) => {
  const { title, description, image, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      minH={{
        base: 120,
        sm: 120,
        md: 130,
        lg: 140,
        xl: 140,
      }}
      position="relative"
    >
      {/* BG IMAGE */}
      <Box position="absolute" h="full" w="full">
        <Box position="relative" h="full" w="full">
          <Image src={image} layout="fill" quality={60} objectFit="cover" />
        </Box>
      </Box>
      {/* CONTENT */}
      <Stack
        position="absolute"
        w="full"
        h="full"
        justifyContent={'center'}
        textAlign={'center'}
        color="white"
        py={6}
        px={{ base: 2, md: 4, lg: 6 }}
        spacing={0}
      >
        <Text
          as="h3"
          fontWeight={500}
          textTransform={'uppercase'}
          fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
        >
          {title}
        </Text>
        <Text as="p" fontSize={{ base: 'xs', sm: 'sm', md: 'sm', lg: 'md' }}>
          {description}
        </Text>
      </Stack>
    </Box>
  );
};
