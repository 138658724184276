import {
  AspectRatio,
  Box,
  BoxProps,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import Image from 'next/image';
import { ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

export interface IframeModalProps extends BoxProps {
  thumbnail: string;
  url: string;
  icon?: ReactNode;
  label?: string;
}

export const IframeModal = (props: IframeModalProps) => {
  const { url, thumbnail, label, icon, ...restOfProps } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box {...restOfProps}>
      <AspectRatio
        ratio={1.776}
        w={'full'}
        position="relative"
        onClick={onOpen}
        cursor="pointer"
      >
        <Box>
          <Image src={thumbnail} layout="fill" quality={48} objectFit="cover" />
          <Flex
            padding={7}
            justifyContent="center"
            alignItems={'center'}
            flexDir={'column'}
            h="full"
            w="full"
            gridGap={2}
            bg="#00000066"
            position={'absolute'}
          >
            <Box>{icon}</Box>
            <Box fontWeight={500} color="white" fontSize={{ base: 'xl' }}>
              {label}
            </Box>
          </Flex>
        </Box>
      </AspectRatio>
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow={'none'}>
          <Box
            fontSize="4xl"
            bg="#00000066"
            p={0.5}
            zIndex={50}
            color="white"
            cursor={'pointer'}
            onClick={onClose}
            position={'fixed'}
            top={6}
            right={6}
          >
            <FiX />
          </Box>
          <AspectRatio
            ratio={1.776}
            position="fixed"
            top={'62%'}
            left={'50%'}
            transform="translateX(-50%) translateY(-64%)"
            w={{ base: 'full', sm: '90vw', xl: 'container.xl' }}
          >
            <iframe
              src={url + '?autoplay=1'}
              width="100%"
              height="100%"
              loading="lazy"
              allow="autoplay; encrypted-media"
            ></iframe>
          </AspectRatio>
        </ModalContent>
      </Modal>
    </Box>
  );
};
