import { NextSeo, NextSeoProps } from 'next-seo';

export interface SeoProps extends NextSeoProps {
  pageName?: string;
}

export const Seo = (props: SeoProps) => {
  const { pageName, ...restOfProps } = props;
  let title = 'BRICK reality';
  if (pageName) {
    title = `${pageName} | ${title}`;
  }

  const seo: NextSeoProps = {
    title: title,
    description:
      'Sme realitná kancelária, ktorá láme predsudky voči realitnej činnosti. Vyťažíme z každej situácie maximum a hájime Vaše záujmy.',
    openGraph: {
      title: title,
      description:
        'Sme realitná kancelária, ktorá láme predsudky voči realitnej činnosti. Vyťažíme z každej situácie maximum a hájime Vaše záujmy.',
      site_name: 'BRICK reality',
      images: [
        {
          url: '/logo.png',
          alt: 'BRICK reality',
        },
      ],
    },
    twitter: {
      cardType: 'summary',
      site: '@brickreality',
      handle: `@brickreality`,
    },
    ...restOfProps,
  };

  return <NextSeo {...seo} />;
};
