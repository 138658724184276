import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export interface TimelineConnectorProps extends BoxProps {}

export const TimelineConnector = (props: TimelineConnectorProps) => {
  const { ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      h="full"
      w={'3px'}
      bg="primary.500"
      boxShadow={'xl'}
    ></Box>
  );
};
