import { Box, HStack, StackProps } from '@chakra-ui/react';
import Link, { LinkProps } from 'next/link';
import { MdChevronRight } from 'react-icons/md';

export interface ArrowLinkProps extends LinkProps {
  label: string;
  external?: boolean;
  buttonProps?: StackProps;
}

export const ArrowLink = (props: ArrowLinkProps) => {
  const { label, buttonProps, external = false, ...restOfProps } = props;
  return (
    <Link {...restOfProps}>
      <a
        target={external ? '_blank' : '_self'}
        rel={external ? 'noreferrer' : 'same-origin'}
      >
        <HStack
          fontWeight={600}
          color="grey.500"
          spacing={0}
          alignItems={'center'}
          p={2}
          m={-2}
          {...buttonProps}
        >
          <Box>{label}</Box>
          <Box>
            <MdChevronRight />
          </Box>
        </HStack>
      </a>
    </Link>
  );
};
