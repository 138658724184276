import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  identifier: 'E-mail',
  password: 'Heslo',
};

export const defaultPlaceholders: FormTexts = {
  identifier: 'Váš E-mail',
  password: 'Vaše heslo',
};

export const defaultErrorsMessage: UseToastOptions = {
  title: 'Nesprávne prihlasovacie údaje',
  description: 'Potvrdili ste Vašu emailovú adresu?',
  status: 'error',
  duration: 6000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Prihlásiť sa',
  loadingText: 'Prihlasujem',
  colorScheme: 'black',
};
