import { Box, BoxProps, Flex, Stack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { TimelineCircle } from './TimelineCircle';
import { TimelineConnector } from './TimelineConnector';

export interface TimelineItemProps extends BoxProps {
  icon: ReactNode;
  title: string;
  description?: string;
  textLeft?: boolean;
  connect?: boolean;
}

export const TimelineItem = (props: TimelineItemProps) => {
  const {
    icon,
    title,
    description,
    textLeft = false,
    connect = false,
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps}>
      <Flex
        w="full"
        gridGap={{ base: 4, md: 6 }}
        alignItems={{ base: 'center', md: 'flex-end' }}
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Flex
          h={{ base: 28, md: connect ? 40 : 'auto' }}
          flexDir={'column'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          order={{ md: textLeft ? 1 : 'auto' }}
        >
          {connect && (
            <TimelineConnector display={{ base: 'none', md: 'block' }} />
          )}
          <Box>
            <TimelineCircle icon={icon} />
          </Box>
        </Flex>
        <Stack
          spacing={0}
          pb={2}
          textAlign={{ base: 'center', md: textLeft ? 'right' : 'left' }}
        >
          <Text
            fontWeight={600}
            fontSize={{ base: 'lg', md: 'xl' }}
            color="black.500"
          >
            {title}
          </Text>
          {description && (
            <Text color="grey.500" fontSize={{ base: 'sm', md: 'md' }}>
              {description}
            </Text>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};
