import { LinkModel, SocialLink } from '@brick/model';
import { LayoutItems, SvgIcon } from '@brick/ui-components';
import { FiMail, FiPhone } from 'react-icons/fi';
import emailIcon from '../assets/icons/email-icon.svg';
import locationIcon from '../assets/icons/location-icon.svg';
import phoneIcon from '../assets/icons/phone-icon.svg';
import { useNewsLetterSignUpMutation } from '../lib/features/news-letter-sign-up/hooks/useNewsLetterSignUpMutation';
import {
  authRoutes,
  clientZoneRoutes,
  ctaRoutes,
  menuRoutes,
} from '../lib/routes/routes';

export const headerContactLinks = [
  {
    label: '+421 948 504 607',
    href: 'tel:+421948504607',
    external: true,

    icon: <FiPhone />,
  },
  {
    label: 'info@brickreality.sk',
    href: 'mailto:info@brickreality.sk',
    external: true,

    icon: <FiMail />,
  },
];

export const contactLinks: LinkModel[] = [
  {
    label: '+421 948 504 607',
    href: 'tel:+421948504607',
    external: true,
    icon: <SvgIcon src={phoneIcon} size={28} />,
    note: '- Pia: 09:30 - 18:30',
  },
  {
    label: 'info@brickreality.sk',
    href: 'mailto:info@brickreality.sk',
    icon: <SvgIcon src={emailIcon} size={28} />,
    external: true,
  },
  {
    label: 'Drieňová 31, 821 01 Bratislava',
    external: true,

    href: 'https://www.google.com/maps/place/Drie%C5%88ov%C3%A1+31,+821+01+Bratislava/data=!4m2!3m1!1s0x476c8ed6f773391d:0x?sa=X&ved=2ahUKEwi_j7743pX1AhU757sIHfasDnoQ8gF6BAgREAE',
    icon: <SvgIcon src={locationIcon} size={28} />,
  },
];

export const socialLinks: SocialLink[] = [
  {
    type: 'facebook',
    href: 'https://www.facebook.com/brickreality.sk/',
    external: true,
  },
  {
    type: 'linkedin',
    href: 'https://www.linkedin.com/company/brick-reality/',
    external: true,
  },
  {
    type: 'youtube',
    href: 'https://www.youtube.com/channel/UC4HU4-kejIWRHB1ui0AeLEA',
    external: true,
  },
  {
    type: 'instagram',
    href: 'https://www.instagram.com/brickreality.sk/',
    external: true,
  },
];

const aboutLinks: LinkModel[] = [
  menuRoutes.properties,
  menuRoutes.news,
  ctaRoutes.career,
  menuRoutes.contact,
];

const importantLinks: LinkModel[] = [
  {
    label: 'Chcem predať',
    href: ctaRoutes.sell.href,
  },

  {
    label: 'Chcem kúpiť',
    href: ctaRoutes.buy.href,
  },
  {
    label: 'Hypotekárna kalkulačka',
    href: `${menuRoutes.financialService.href}#hypotekarna-kalkulacka`,
  },
  clientZoneRoutes.licitation,
];

const documentLinks: LinkModel[] = [
  {
    label: 'Ochrana osobných údajov',
    href: '/files/ochrana-osobnych-udajov.pdf',
    external: true,
  },
  {
    label: 'Všeobecné obchodné podmienky',
    href: '/files/vseobecne-obchodne-podmienky.pdf',
    external: true,
  },
  {
    label: 'Reklamačný poriadok',
    href: '/files/reklamacny-poriadok.pdf',
    external: true,
  },
  {
    label: 'Reklamačný protokol',
    href: '/files/reklamacny-protokol.pdf',
    external: true,
  },
];

export const layoutItems: LayoutItems = {
  headerTop: {
    userRoutes: Object.values(authRoutes),
    ctaRoutes: Object.values(ctaRoutes),
    contactLinks: headerContactLinks,
    socialLinks: socialLinks,
  },
  menuRoutes: Object.values(menuRoutes),
  footer: {
    socialLinks: socialLinks,
    contactLinks: contactLinks,
    aboutLinks: aboutLinks,
    importantLinks: importantLinks,
    documentLinks: documentLinks,
    subscribeFormMutation: useNewsLetterSignUpMutation,
  },
};
