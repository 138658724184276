import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { PerkCard, PerkCardProps } from './PerkCard';

export interface PerksGridProps extends BoxProps {
  perks: PerkCardProps[];
}

export const PerksGrid = (props: PerksGridProps) => {
  const { perks, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <SimpleGrid
        columns={{ base: 1, sm: 3, md: 3, lg: 4 }}
        gridGap={{ base: 8, md: 8 }}
      >
        {perks.map((perk, i) => (
          <PerkCard {...perk} key={i} />
        ))}
      </SimpleGrid>
    </Box>
  );
};
