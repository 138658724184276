import { Box, BoxProps, Skeleton, Stack } from '@chakra-ui/react';

export interface TableSkeletonProps extends BoxProps {
  rows: number;
}

export const TableSkeleton = (props: TableSkeletonProps) => {
  const { rows, ...restOfProps } = props;

  return (
    <Box {...restOfProps} w="full">
      <Skeleton width={'full'} height={6} mb={'9px'} />

      <Stack spacing={1}>
        {[...Array(rows)].map((i) => (
          <Skeleton width={'full'} height={'42px'} />
        ))}
      </Stack>
    </Box>
  );
};
