import { Box, BoxProps, Stack } from '@chakra-ui/react';
import {
  FullParagraph,
  FullParagraphProps,
} from '../../Elements/FullParagraph/FullParahraph';
import { ImageContentCard } from '../../Elements/ImageContentCard/ImageContentCard';
import {
  SectionHeading,
  SectionHeadingProps,
} from '../../Elements/SectionHeading/SectionHeading';
import { Container } from '../../Layout/Container/Container';

// @ts-ignore

export interface RealityImageContentCardSectionProps extends BoxProps {
  heading: SectionHeadingProps;
  image: string;
  fullParagraphs: FullParagraphProps[];
}

export const RealityImageContentCardSection = (
  props: RealityImageContentCardSectionProps
) => {
  const { heading, fullParagraphs, image, ...restOfProps } = props;
  return (
    <Box {...restOfProps} bg={'grey.100'} py={{ base: 8, md: 12 }}>
      <Container>
        <SectionHeading {...heading} mb={{ base: 4, md: 6 }} />
        <ImageContentCard image={image} showBorder={false}>
          <Stack spacing={4}>
            {fullParagraphs.map((p, i) => (
              <FullParagraph {...p} key={i} />
            ))}
          </Stack>
        </ImageContentCard>
      </Container>
    </Box>
  );
};
