import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { TimelineItem, TimelineItemProps } from './components/TimelineItem';

export interface TimelineProps extends BoxProps {
  items: TimelineItemProps[];
  textLeft?: boolean;
}

export const Timeline = (props: TimelineProps) => {
  const { items, textLeft = false, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      {items.map((item, i) => (
        <TimelineItem {...item} key={i} connect={i != 0} textLeft={textLeft} />
      ))}
    </Box>
  );
};
