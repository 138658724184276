import { Box, BoxProps } from '@chakra-ui/react';
import Image from 'next/image';

export interface SvgIconProps extends BoxProps {
  src: string;
  size?: number;
}

export const SvgIcon = (props: SvgIconProps) => {
  const { src, size = 48, ...restOfProps } = props;
  return (
    <Box h={`${size}px`} w={`${size}px`} {...restOfProps}>
      <Image src={src} height={size} width={size} alt={'icon'}></Image>
    </Box>
  );
};
