import { Box, BoxProps } from '@chakra-ui/react';

export interface FormInfoProps extends BoxProps {
  title: string;
  content: string;
}

export const FormInfo = (props: FormInfoProps) => {
  const { title, content, ...restOfProps } = props;
  return (
    <Box {...restOfProps} textAlign={{ base: 'center', sm: 'left' }}>
      <Box
        fontSize={{ base: 'lg', md: 'lg' }}
        fontWeight={500}
        mx="auto"
        maxW={{ base: 64, sm: 'none' }}
        color="grey.500"
      >
        {title}
      </Box>
      <Box fontSize={{ base: 'sm', md: 'md' }} mt={{ base: 2, md: 0 }}>
        {content}
      </Box>
    </Box>
  );
};
