import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  fullName: 'Meno a priezvisko',
  phone: 'Mobil',
  address: 'Adresa nehnuteľnosti',
  propertyCategory: 'Typ nehnuteľnosti',
  isNewBuilding: 'Novostavba',
  email: 'Email',
  note: 'Vaša správa',
};

export const defaultPlaceholders: FormTexts = {
  fullName: 'meno a priezvisko',
  phone: 'mobil',
  address: 'ulica a popisné číslo',
  propertyCategory: 'vyberte zo zoznamu',
  email: 'e-mail',
  note: 'sem môžete napísať obsah Vašej správy',
};

export const defaultSuccessMessage: UseToastOptions = {
  title: 'Ďakujeme za Vašu objednávku.',
  description: 'Budeme Vás kontaktovať čo najskôr.',
  status: 'success',
  duration: 9000,
  position: 'top',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Oceniť moju nehnuteľnosť',
  loadingText: 'Odosielam',
  colorScheme: 'black',
};

export const NEW_BUILDING_ID = 3;
