import { FormSubmitButton } from '../../../../model/forms';
import { FormTexts } from '../model/form-model';

export const defaultLabels: FormTexts = {
  vopAgreement: 'Áno, súhlasím so Všeobecnými obchodnými podmienkami',
  licitationAgreement: 'Áno, súhlasím s Podmienkami lictácie nehnuteľnosti',
};

export const defaultPlaceholders: FormTexts = {
  vopAgreement: '',
  licitationAgreement: '',
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Vstúpiť do licitácie',
  loadingText: 'Vstúpiť do licitácie',
  colorScheme: 'black',
};

export const note = 'Po kliknutí na tlačidlo...';

export const vopContent = `
## I. Úvodné ustanovenia



1.1. Realitná kancelária BRICK reality, s. r. o. so sídlom Ožvoldíková 2005/6, 841 02 Bratislava -mestská časť Dúbravka,   IČO:   51 072 793, zapísaná v obchodnom registri Okresného súdu  Bratislava I, v oddiele Sro, vložka číslo 122168/B (ďalej len ako „realitná kancelária“ alebo ako „sprostredkovateľ“) je právnickou osobou, ktorá sa   v   rámci   svojej   podnikateľskej   činnosti   venuje   najmä   sprostredkovaniu   kúpy, predaja a prenájmu nehnuteľností.



1.2 Tieto VOP upravujú pravidlá činnosti realitnej kancelárie - sprostredkovateľa pri   sprostredkovaní   kúpy,   predaja   a   prenájmu   nehnuteľností   a   upravujú   všetky právne vzťahy vznikajúce medzi sprostredkovateľom, klientmi a tretími osobami a platia pre všetky záväzkové vzťahy, pokiaľ nie sú konkrétnou zmluvou výslovne zmenené   alebo   vylúčené.   Klientom   sa   rozumie   akákoľvek   fyzická   či   právnická osoba,   ktorej   realitná   kancelária   poskytuje  realitné  služby   (ďalej   aj   „klient“)   na základe   Sprostredkovateľskej   zmluvy,   Zmluvy   o   sprostredkovaní
predaja/prenájmu/kúpy   nehnuteľností   a   poskytovaní   služieb   spojených
s predajom/prenájmom/kúpou   nehnuteľností   alebo   na   základe   obdobnej   zmluvy
súvisiacej s činnosťou realitnej kancelárie (ďalej aj „sprostredkovateľská zmluva“).
VOP   sa   vzťahujú   k   právneho   vzťahu   vzniknutého   na   základe   Zmluvy   o
sprostredkovaní predaja nehnuteľností a poskytovaní služieb spojených s predajom
nehnuteľností, prípadne obdobnej zmluvy súvisiacej s činnosťou sprostredkovateľa.



1.3 Právne vzťahy medzi realitnou kanceláriou a klientom, ktoré nie sú upravené
v uzatvorenej   zmluve   a v týchto   VOP   sa   riadia   právnym   poriadkom   Slovenskej
republiky,   najmä   príslušnými   ustanoveniami   Občianskeho   zákonníka   v   platnom
znení.


1.4 Realitná   kancelária     zabezpečuje   metodické,   materiálne,   finančné   aj
personálne vedenie a usmerňovanie realitných maklérov, vrátane zabezpečovania
školení, vzdelávania a hodnotenia osôb, ktoré vystupujú a konajú v mene realitnej
kancelárie   pri   uzatváraní   sprostredkovateľských   zmlúv   a realizácii   obhliadok
nehnuteľností ( ďalej len „realitný maklér“).



1.5 Realitní  makléri sú na základe uzatvorenej  zmluvy s realitnou kanceláriou
samostatnými   subjektmi   realitnej   kancelárie   a   na   základe   poverenia   poskytujú
klientom služby v mene a na účet realitnej kancelárie.



1.6 Realitná kancelária predkladá svojim klientom a tretím osobám (záujemcom)
typové návrhy zmlúv, ktorých zmeny je možné vykonať len po výslovnom súhlase
zmluvných strán.
 
## II. Úprava práv a povinností medzi realitnou kancelárioua klientom 



2.1 Realitná kancelária vykonáva sprostredkovateľskú činnosť pre klienta, ktorý
prejaví záujem o sprostredkovanie kúpy, predaja alebo prenájmu nehnuteľností na
základe sprostredkovateľskej zmluvy.



2.2 Sprostredkovateľská zmluva je uzatvorená v zmysle ustanovenia § 642 a nasl.
Obchodného   zákonníka   zákon   č.   513/1991   Zb.   Obchodného   zákonníka   v   znení
neskorších predpisov a upravuje vzájomné práva a povinnosti klienta a realitnej
kancelárie.



2.3 Práva   a   povinnosti   zmluvných   strán,   ktoré   nie   sú   výslovne   upravené   v
sprostredkovateľskej   zmluve   sa   spravujú   príslušnými   právnymi   predpismi,   a to
prednostne ustanoveniami Občianskeho zákonníka v platnom znení.


2.4 Realitná   kancelária   vykonáva   sprostredkovateľskú   činnosť   s   odbornou
starostlivosťou   v   súlade   s   ustanoveniami   sprostredkovateľskej   zmluvy   ako   aj   v
súlade s príslušnými právnymi predpismi.



2.5 Sprostredkovateľskou   zmluvou   sa   realitná   kancelária   zaväzuje   obstarať   s
odbornou starostlivosťou súvisiacou s realitnou činnosťou (inzercia, obhliadky) pre
klienta   za   odmenu   uzavretie   kúpnej   alebo   nájomnej   zmluvy   na   nehnuteľnosť   s
cieľom dosiahnuť maximálnu spokojnosť klienta.



2.6 Realitná kancelária nezodpovedá klientovi za splnenie záväzkov tretích osôb
(záujemcov), z uzavretých zmluvných vzťahov.



2.7 Realitná kancelária   je povinná v čase uzatvorenia zmluvy s klientom alebo
tretími osobami preveriť aktuálny stav nehnuteľností na príslušnom liste vlastníctva
a informovať   tretie   osoby   ako   záujemcov   o kúpnu   nehnuteľností   klienta
sprostredkovateľa (ďalej len „záujemcovia“) o prípadných ťarchách a plombách na
liste   vlastníctve..   Realitná   kancelária   nezodpovedá   za   škodu   spôsobenú
nesprávnymi   alebo   neúplnými   informáciami   získanými   od   klientov   alebo   od
záujemcov.



2.8  Realitná   kancelária   nezodpovedá   za   záväzky,   vyhlásenia   a práva,   ktoré
vyplývajú z uzatvorených zmlúv medzi klientmi a záujemcami.

2.9  Klient   je   povinný   informovať   realitnú   kanceláriu   bez   zbytočného   odkladu,
najneskôr do 2 pracovných dní o všetkých zmenách súvisiacich s nehnuteľnosťou,
a to najmä o právnych zmenách ktoré nastali od uzatvorenia sprostredkovateľskej
zmluvy.

## III. Prvotná obhliadka



3.1 Ak klient prejaví záujem o sprostredkovanie predaja, prenájmu, alebo kúpy
nehnuteľnosti  a  realitná  kancelária  akceptuje  predmetný realitný  obchod,  môže
dôjsť k uzatvoreniu sprostredkovateľskej zmluvy. 


3.2 Pri prvej obhliadke nehnuteľnosti a uzatvorenia sprostredkovateľskej zmluvy
na nehnuteľnosť a zaradenia nehnuteľnosti do ponuky realitnej kancelárie oboznámi
realitný   maklér   klienta   s   pravidlami   zabezpečenia   predaja   nehnuteľnosti
prostredníctvom sprostredkovateľa.


3.3 Sprostredkovanie   kúpy/predaja/nájmu   nehnuteľností   vykonáva   realitná
kancelária   pre   svojich   klientov   na   základe   neexkluzívnych   a   exkluzívnych
(výhradných) zmlúv.


3.4 Po   dobu   trvania   zmluvného   vzťahu   založeného   exkluzívnou   (výhradnou)
zmluvou sa klient zaväzuje, že nebude priamo alebo nepriamo kontaktovať tretie
osoby   za   účelom   rokovania   o   uzavretí   akejkoľvek   zmluvy   vedúcej   k   prevodu
vlastníckeho   práva   k   predmetnej   nehnuteľnosti   v   prospech   ktorejkoľvek   tretej
osoby,   resp.   nájmu/podnájmu   predmetnej   nehnuteľnosti;   neuzavrie   s   tretími
osobami,   s   ktorými   mu   realitná   kancelária   navrhovala   uzavretie   zmluvy,   ani   s
akýmikoľvek inými tretími osobami zmluvu vedúcu k prevodu vlastníckeho práva k
predmetnej nehnuteľnosti, resp. nájmu/podnájmu predmetnej nehnuteľnosti, bez
vedomia realitnej kancelárie, ani nebude vyvíjať žiadne kroky, ktoré by viedli k
uzatvoreniu   takejto   zmluvy   bez   vedomia   realitnej   kancelárie;   neuzavrie
sprostredkovateľskú   alebo   obdobnú   zmluvu   s   inou   fyzickou   alebo   právnickou
osobou. V prípade porušenia tohto bodu VOP sa klient zaväzuje uhradiť realitnej
kancelárii paušálnu náhradu výdavkov vo výške 80% dojednanej odmeny (provízie),
a to   bez   ohľadu   na   to,   či   realitná   kancelária   naplnila   všetky   svoje   povinnosti
vyplývajúce jej z príslušnej sprostredkovateľskej zmluvy (právne služby, poplatky
a pod.).   Klient   uzatvorením   sprostredkovateľskej   zmluvy   vyhlasuje,   že   výška
paušálnej náhrady výdavkov je vzhľadom na zvýšené výdavky a aktivitu realitnej
kancelárie pri výhradnom sprostredkovaní primeraná, a to predovšetkým s ohľadom
na to, že realitná kancelária vynakladá vyššie výdavky na inzerciu a s tým súvisiace
služby   v   porovnaní   s   výdavkami   pri   neexkluzívnej   zmluve.   V   individuálnych
zmluvných   dojednaniach   obsiahnutých   v sprostredkovateľskej   zmluve   môže   byť
paušálna náhrada výdavkov (zmluvná pokuta) dojednaná vo vyššom percentuálnom
vyjadrení   ako   80%   a v takom   prípade   sa   za   rozhodné   považujú   ustanovenia
sprostredkovateľskej zmluvy.


3.5 Realitná   kancelária     resp.   realitný   maklér   pri   vykonaní   dokumentačnej
obhliadky   spravidla   spíše   záznam,   v   ktorom   bude   uvedená   špecifikácia
nehnuteľnosti,   výmera   podlahovej   plochy,   popis   jednotlivých   častí   a   ich
príslušenstva, technický stav, prípadné nedostatky a vady nehnuteľnosti, lokalita,
poloha,   infraštruktúra   a   zabezpečí   fotodokumentáciu.   Informácie   obsiahnuté   v
zázname môžu byť použité pri propagácií  a inzercií nehnuteľnosti.


3.6 Realitný   maklér   vykonáva   obhliadku   vždy   v   súčinnosti   s   vlastníkom
nehnuteľnosti a za jeho prítomnosti, ak nie je dohodnuté inak.


3.7 Všetky   fotografie,   videá,   pôdorysy,   vizualizácie   a   texty   sú   duševným
vlastníctvom realitnej kancelárie a nie je možné ich kopírovať bez predchádzajúceho
písomného   súhlasu   realitnej   kancelárie   a ani   požadovať   ich   vydanie.   V   prípade
krádeže dušeného vlastníctva realitnej kancelárie   pri jednej nehnuteľnosti a pri
každom jednotlivom porušení si môže realitná kancelária uplatňovať nárok v sume
300 EUR (za každú fotografiu) a v prípade jej neuhradenia bude realitná kancelária
túto sumu vymáhať právnou cestou prostredníctvom spolupracujúcej advokátskej
kancelárie.

## IV. Propagácia a inzercia


4.1 Realitná  kancelária  v  rámci  sprostredkovacej  činnosti   spracuje  ponuku  na
predaj,   alebo   prenájom   nehnuteľnosti   s   odbornou   starostlivosťou   a   v   miere
potrebnej na jej úspešnú propagáciu.


4.2 Realitná kancelária v rámci sprostredkovateľskej činnosti uverejňuje ponuky
na predaj, kúpu alebo nájom nehnuteľností v elektronických a tlačených médiách
podľa vlastného výberu.


4.3 Realitná kancelária pri uverejňovaní ponuky na predaj, alebo prenájom zadá
do inzercie popis nehnuteľností, fotodokumentáciu, prípadne video, pričom dbá na
to, aby bolo chránené súkromie a anonymita klienta.

4.4 Realitná   kancelária   môže   pri   propagácií   nehnuteľnosti   využiť   riadkovú,
prípadne obrázkovú inzerciu v tlačených médiách podľa vlastného výberu.


4.5 Ak nastane zmena údajov zadaných v pôvodnej ponuke, realitná kancelária
zabezpečí ich aktualizáciu.


4.6 Náklady   spojené   s   inzerciou   ponuky   na   predaj,   kúpu   alebo   prenájom
nehnuteľností znáša realitná kancelária. 

## V. Obhliadka a rezervácia


5.1 Realitná   kancelária   zabezpečí   pre   záujemcu,   ktorý   prejaví   záujem   o
uzatvorenie kúpnej alebo nájomnej zmluvy na nehnuteľnosť ponúkanú realitnou
kanceláriou, vstup na nehnuteľnosť za účelom obhliadky.


5.2 Prístup na nehnuteľnosť zabezpečí realitná kancelária po dohode s vlastníkom
nehnuteľností (ak toto nevyplýva priamo zo sprostredkovateľskej zmluvy), ako aj so
záujemcom o kúpu alebo nájom nehnuteľnosti.


5.3 Realitná   kancelária   vyvinie   úsilie,   aby   počas   obhliadky   nevznikla   na
nehnuteľnosti škoda.

5.4 Sprostredkovateľ   sa   v   prípade   prevzatia   kľúčov   od   nehnuteľnosti   a pri
obhliadke nehnuteľnosti bez prítomnosti klienta zaväzuje:

  \t kľúče starostlivo opatrovať a chrániť pred stratou, poškodením, odcudzením
        alebo zničením,

        \t pri návšteve nehnuteľnosti dbať o poriadok a pokoj v nehnuteľnosti,

        \t navštevovať   nehnuteľnosť   iba   v   súvislosti   s   vykonávaním   činností   podľa sprostredkovateľskej zmluvy alebo vzájomnej dohody, 
pričom je povinný dbať na   to,   aby   počas   obhliadky   nevznikla   na   nehnuteľnosti   a/alebo   majetku klienta žiadna škoda,

\t pri   odchode   starostlivo   nehnuteľnosť   uzamknúť   a   zabezpečiť   ju   proti
neoprávnenému zásahu.

5.5 Prevzatie kľúčov potvrdí realitná kancelária a klient v preberacom protokole.

5.6 V prípade, ak má záujemca o kúpu nehnuteľnosti vážny záujem, je možné
túto nehnuteľnosť rezervovať a nepredávať ďalej až do doby podpísania kúpnej
zmluvy. V takom prípade záujemca o kúpu nehnuteľnosti zloží realitnej kancelárii
rezervačnú zálohu a realitná kancelária upraví nehnuteľnosť v príslušnej inzercii ako
rezervovanú. Stiahnutie nehnuteľnosti z inzercie vykoná realitná kancelária až po
zaplatení celej kúpnej ceny, najneskôr do ku dňu povolenia vkladu vlastníckeho
práva k nehnuteľnosti na záujemcu.

5.7 Predaj   nehnuteľnosti   je   možný   aj   prostredníctvom   dobrovoľnej   licitácie
určenej pre vážnych záujemcov, a to za účelom dosiahnutia vyššej predajnej ceny
než je cena  uvedená v sprostredkovateľskej zmluve. V takom prípade sa na priebeh
licitácie uplatňujú Licitačné podmienky spolu s týmito VOP.

5.8 V   prípade,   ak   záujemca   o   kúpu   nehnuteľnosti   nebude   ochotný   zložiť   v
realitnej kancelárií celú výšku rezervačnej zálohy, predávaná nehnuteľnosť bude
inzerovaná ako voľná až do doby zloženia celej rezervačnej zálohy záujemcom.

## VI. Odmena a náklady

6.1 Za   poskytovanie   realitných   služieb   prináleží   realitnej   kancelárii   odmena
(provízia).   Odmena   môže   byť   dojednaná   v sprostredkovateľskej   zmluve   ako
paušálna, percentom z predajnej/kúpnej ceny alebo ako rozdiel medzi predajnou
cenou   a   cenou   požadovanou   klientom,   prípadne   ako   kombinácia   z vyššie
uvedených. Ak je realitná kancelária platcom DPH, k odmene sa pripočíta DPH podľa
platných právnych predpisov.


6.2 Záujemca o kúpu nehnuteľnosti uhradí rezervačnú zálohu bezhotovostným
prevodom   na   bankový   účet   realitnej   kancelárie,   pričom   rezervačná   záloha   sa
započítava do kúpnej ceny nehnuteľnosti.


6.3 Realitná   kancelária   má   nárok   na   odmenu   po   dobu   jedného   roka,   aj   za
predpokladu,   že   k   uzatvoreniu   kúpnej   zmluvy   alebo   nájomnej   zmluvy,   ktorej
uzatvorenie   bolo   predmetom   sprostredkovateľskej   zmluvy,   došlo   po   skončení
platnosti tejto sprostredkovateľskej zmluvy, ak k uzavretiu kúpnej zmluvy alebo
nájomnej zmluvy došlo v priamej súvislosti s činnosťou realitnej kancelárie.

6.4 Realitná kancelária má nárok na odmenu aj v prípade, ak klient uzavrie bez
ďalšej súčinnosti realitnej kancelárie kúpnu zmluvu (alebo inú scudzovaniu zmluvu)
alebo nájomnú zmluvu s treťou osobou, ktorú mu uviedla realitná kancelária ako
osobu vhodnú na uzavretie kúpnej zmluvy alebo nájomnej zmluvy (napr. tak, že s
určenou osobou prišiel realitný maklér na ohliadku nehnuteľnosti).


6.5 V odmene sprostredkovateľa sú zahrnuté všetky náklady realitnej kancelárie
spojené   s   výkonom   jej   činnosti,   a   to   najmä:   obhliadka   nehnuteľnosti,
fotodokumentácia, inzercia, právny servis, poplatky u notára za overenie podpisov
predávajúcich a základný poplatok na katastri vo výške 66,- eur, prípadne ďalšie
nadštandardné služby, ktoré budú uvedené v  sprostredkovateľskej zmluve alebo v
zázname   z   obhliadky   ako:   znalecký   posudok,   sťahovacie   práce,   upratovanie
nehnuteľnosti , poplatok za notársku alebo bankovú úschovu a iné.
 
## VII. Záverečné ustanovenia


7.1 Každý návrh smerujúci k uzatvoreniu zmluvy so záujemcom, ktorý vyhovuje
požiadavkám   klienta   je   sprostredkovateľ   povinný   bezodkladne   prerokovať   s
klientom. 


7.2 Sprostredkovateľ   sa   zaväzuje   pre   potreby   klienta   a   záujemcu   o   kúpu
nehnuteľností uschovať všetky doklady a kópie kúpnych zmlúv, ktoré nadobudol v
súvislosti so svojou činnosťou.


7.3 Všetky vzájomné spory vyplývajúce alebo vzniknuté zo zmluvného vzťahu
medzi   subjektmi   pôsobiacimi   v   rámci   realitného   obchodovania   medzi   realitnou
kanceláriou  a   klientom/klientmi,   resp.   záujemcom/záujemcami   sa   budú   prioritne
riešiť vzájomnými rokovaniami a dohodou. Na rozhodovanie prípadných súdnych
sporov je vždy príslušný súd v Slovenskej republike.


7.4   Tieto   VOP   sú   zverejnené   na   webovom   sídle   realitnej   kancelárie
www.brickreality.sk  spolu s reklamačným formulárom a ochranou osobných údajov
a vzťahujú   sa   na   všetky   právne   vzťahy,   ktoré   vzniknú   medzi   klientom   alebo
záujemcom a realitnou kanceláriou.

Tieto  VOP nadobúdajú účinnosť dňa 01.05.2022.
`;

export const licitationTermsContent = `
## I.Úvodné ustanovenia. Výklad pojmov


1.1 Realitná kancelária BRICK reality, s. r. o. so sídlom Ožvoldíková 2005/6,
841   02   Bratislava   -mestská   časť   Dúbravka,     IČO:    51 072   793,   zapísaná   v
obchodnom registri Okresného súdu   Bratislava I, v oddiele Sro, vložka číslo
122168/B (ďalej len ako „realitná kancelária“ alebo ako „sprostredkovateľ“) je
právnickou   osobou,   ktorá   sa   v   rámci   svojej   podnikateľskej   činnosti   venuje
najmä sprostredkovaniu kúpy, predaja a prenájmu nehnuteľností.

1.2 Tieto   LP   upravujú   práva   a povinnosti   tretích   osôb   (ďalej   len
„záujemca“),  ktoré   prejavia   vážny   záujem   o kúpu   nehnuteľností
prostredníctvom   licitačného   systému   sprostredkovateľa   a   upravujú   právne
vzťahy   vznikajúce   medzi   sprostredkovateľom   a   záujemcami   pri   licitácii
nehnuteľností prostredníctvom webovej stránky sprostredkovateľa. 

1.3 Predmetom   licitácie   a záujmu   záujemcu   o kúpu   je   konkrétna
nehnuteľnosť  alebo nehnuteľnosti,  ktoré  sa  nachádzajú   na webovej  stránke
sprostredkovateľa   –   https://www.brickreality.sk   (ďalej   aj   „webové   stránka
sprostredkovateľa“)   a sú   určené   na   predaj   formou   licitácie   (ďalej   len
„licitovaná nehnuteľnosť“).

1.4  Licitáciou nehnuteľností sa rozumie záväzná ponúknutá kúpna cena za
licitovanú   nehnuteľnosť   zo   strany   záujemcu   o kúpu   nehnuteľnosti,   ktorej
predaj sprostredkovateľ sprostredkúva (ďalej len „licitácia“), a to cez webové
rozhranie   na   webovej   stránke   sprostredkovateľa   po   registrácii   záujemcu   a
následnom prihlásení. 

1.5 Licitácia   trvá   počas   určeného   časového   obdobia   a   začína   spustením
licitácie   spočívajúce   v   zahájení   odpočítavania   času   do   skončenia   licitácie
(ďalej   aj   „doba   licitácie“).   Odpočívanie   času   prebieha   cez   webovú   stránku
sprostredkovateľa  a je dostupné po zadaní prihlasovacích údajov pridelených
záujemcovi.

1.6 Právne vzťahy medzi realitnou kanceláriou  a záujemcom, ktoré nie sú
upravené   v Licitačnej   príručke   dostupnej   na   webovej   stránke
sprostredkovateľa   (ďalej   aj   „Licitačná   príručka“)   a v týchto   LP   sa   riadia
právnym   poriadkom   Slovenskej   republiky,   najmä   Občianskym   zákonníkom
v platnom znení.


## II. Podmienky účasti na licitácii

2.1 Záujemcom môže  byť fyzická,  aj právnická osoba,    ako aj zahraničná
osoba. Predmetom licitácie sú výlučne nehnuteľnosti, ktorých súčasťou môže
byť   príslušenstvo   a hnuteľný   majetok,  ktorý   predstavuje   zariadenie
a vybavenie nehnuteľností.

2.2 Licitácie sa môžu zúčastniť výlučne osoby, ktoré sú plne spôsobilé na
právne úkony a na výzvu sprostredkovateľa je záujemca povinný preukázať
sprostredkovateľovi   túto   skutočnosť   a na   požiadanie   sprostredkovateľa
predložiť   doklad   totožnosti.   Účasťou   na   licitácii   záujemca   vyhlasuje,   že   má
dostatok   finančných   prostriedkov   na   úhradu   ponukovej   ceny   za   licitovanú
nehnuteľnosť   a je   v jeho   finančných   možnostiach   túto   v celej   výške   uhradiť
najneskôr do 60 dní od skončenia licitácie.

2.3  Licitácie   sa   môžu   zúčastniť   výlučne   osoby   -   záujemcovia   schválené
sprostredkovateľom.   Licitácia   nie   je   verejná   a nemožno   si   účasť   na   licitácii
žiadnym   spôsobom   vynucovať.   Umožniť   účasť   na   licitácii   je   právom
sprostredkovateľa, nie jeho povinnosťou.

2.5 Záujemca,   ktorý   je   v manželstve   vyhlasuje,   že   má   súhlas   druhého
manžela/manželky   s účasťou   na   licitácii   a s kúpou   licitovanej   nehnuteľnosti
alebo   pred   licitáciou   preukázal,   že   bolo   ich   bezpodielové   spoluvlastníctvo
manželov   zúžené   alebo   zrušené,   prípadne,   že   finančné   prostriedky,   ktoré
chce   na   kúpu   licitovanej   nehnuteľnosti   použiť   nepatria   do   bezpodielového
spoluvlastníctva manželov. 

2.6 Na   licitácii   sa   nemôžu   zúčastniť   osoby,   ktoré   z akéhokoľvek   dôvodu
nemôžu nadobúdať vlastnícke právo.   Na licitácii sa nemôžu zúčastniť osoby
voči   ktorým   prebieha   súdne   konanie,   exekučné   konanie,   ani   osoby,     na
ktorých majetok bol vyhlásený konkurz. 

2.7 Na licitácii sa nemôže zúčastniť vlastník licitovanej nehnuteľnosti, blízke
osoby  vlastníka  licitovanej   nehnuteľnosti,  ako aj   osoby,  ktoré  sú  majetkovo
prepojené s vlastníkom licitovanej nehnuteľnosti a tiež zamestnanci a makléri
sprostredkovateľa.   Na   licitácii   sa   nemôže   zúčastniť   spoločník,   štatutárny
orgán,   člen   dozornej   rady,   akcionár,   prokurista,   člen   predstavenstva,   člen
družstva   právnickej   osoby,   ktorá   má   vlastnícke   právo   k   licitovanej
nehnuteľnosti. 

2.8   Licitácie   sa   môžu   zúčastniť   záujemcovia   výlučne   osobne,   zastúpenie
nie   je   prípustné.   Prihlasovacie   údaje   na   webovú   stránku   sprostredkovateľa
potrebnú   pre   účasť   na   licitácii   nie   je   záujemca   oprávnený   prezradiť   tretej
osobe. Akákoľvek ponuková cena za licitovanú nehnuteľnosť sa považuje za
cenu navrhnutú záujemcom.


## III. Úprava práv a povinností záujemcu 

3.1 Sprostredkovateľ   vykonáva   sprostredkovateľskú   činnosť   pre   vlastníka
licitovanej nehnuteľnosti – klienta (ďalej aj „vlastník“) na základe uzatvorenej
sprostredkovateľskej zmluvy a záujemca má záujem túto nehnuteľnosť kúpiť
za kúpnu cenu, ktorú za nehnuteľnosť ponúkne počas licitácie.

3.2 Licitovaná   nehnuteľnosť   je   špecifikovaná   na   webovej   stránke
sprostredkovateľa a záujemca má záujem túto kúpiť za ním ponúknutú cenu
formou   licitácie   (ďalej   aj   „ponuková   cena“)   podľa   popisu   licitovanej
nehnuteľnosti a túto ponukovú cenu považuje za licitovanú nehnuteľnosť za
primeranú. Návrhom ponukovej ceny záujemca vyhlasuje, že má záujem kúpiť
licitovanú nehnuteľnosť za ponukovú cenu, aj v prípade ak sa nezúčastnil jej
osobnej obhliadky a túto ponukovú cenu považuje za záväznú. Ponuková cena
zo strany záujemcu je pre záujemcu záväzná. 

3.3 Záujemca   vyhlasuje,   že   ním   ponúkaná   kúpna   cena   za   licitovanú
nehnuteľnosť   je   záväzná   a v prípade,   ak   bude   táto   najvyššou   ponukovou
cenou za licitovanú nehnuteľnosť po skončení licitácie, zaväzuje sa uzatvoriť
s vlastníkom   licitovanej   nehnuteľnosti   najneskôr   do   5   pracovných   dní   od
ukončenia licitácie zmluvu o uzatvorení budúcej kúpnej zmluvy alebo kúpnu
zmluvu na licitovanú nehnuteľnosť za kúpnu cenu totožnú s ponukovou cenou
záujemcu, ktorú navrhol počas priebehu licitácie.

3.4 Záujemca   je   povinný   bezodkladne   po   výzve   sprostredkovateľa
preukázať, že je v jeho finančných možnostiach uhradiť ním ponúkanú cenu za
licitovanú  nehnuteľnosť v celej výške v lehote  60 dní od skončenia licitácie.
V prípade,   ak   túto   skutočnosť   záujemca   sprostredkovateľovi   dostatočne
nepreukáže, sprostredkovateľ môže záujemcu vylúčiť z účasti na licitácii.

## IV. Priebeh licitácie

4.1 Čas  licitácie,  dĺžku trvania licitácie, spôsob prihlásenia sa na webovej
stránke sprostredkovateľa oznámi záujemcom sprostredkovateľ spravidla po
osobnej obhliadke záujemcu na licitovanej nehnuteľnosti. 

4.2 Uplynutím odpočítavania sa licitácia končí. Odpočítavanie času licitácie
sa   môže   opakovanie   predlžovať,   a to   za   podmienok   uvedených   v Licitačnej
príručke,  ktorej   úplné   znenie   je   dostupné   na   webovej   stránke
sprostredkovateľa.

4.3 V prípade,  ak   víťaz   licitácie   neuzatvorí   s vlastníkom   licitovanej
nehnuteľnosti najneskôr do lehote do 5 pracovných dní od skončenia licitácie
zmluvu o uzatvorení budúcej kúpnej zmluvy alebo kúpnu zmluvu na licitovanú
nehnuteľnosť     za   kúpnu   cenu   totožnú   s   ponukovou   cenou   víťaza   licitácie,
sprostredkovateľ   ponúkne   licitovanú   nehnuteľnosť  záujemcovi,   ktorý   dal   na
licitovanú nehnuteľnosť druhú najvyššiu ponukovú cenu, ak tento odmietne,
tak   ďalšiemu   v poradí.   Pokiaľ   víťaz   licitácie   neuzatvorí   v lehote   do   5
pracovných   dní   od   skončenia   licitácie   zmluvu   o uzatvorení   budúcej   kúpnej
zmluvy   alebo   kúpnu   zmluvu   na   licitovanú   nehnuteľnosť   s vlastníkom
licitovanej   nehnuteľnosti   je   sprostredkovateľ   po   dohode   s vlastníkom
licitovanej   nehnuteľnosti   oprávnený   neponúknuť   licitovanú   nehnuteľnosť
ďalšiemu   záujemcovi   v   poradí   a zároveň   môže   spustiť   novú   licitáciu
predmetnej licitovanej nehnuteľnosti.

4.4 Každému   záujemcovi   bude   zo   strany   sprostredkovateľa   po   skončení
licitácie   odoslaný   e-mail   o výsledkoch   licitácie.   Výsledok   licitácie   bude
odoslaný   na   e-mailovú   adresu,   ktorú   uviedol   záujemca   pri   registrácii   na
webovej stránke sprostredkovateľa o výsledkoch licitácie. 

4.5 Podmienky licitácie pri konkrétnych licitovaných nehnuteľnostiach môžu
byť   rôzne   a sprostredkovateľ   si   vyhradzuje   právo   na   určenie   týchto
podmienok   po   dohode   s vlastníkom   licitovanej   nehnuteľnosti.   Pokiaľ   bude
jednou   z podmienok   pre   účasť   na   licitácii   zloženie   zálohy   za   licitovanú
nehnuteľnosť (ďalej aj „záloha“), tak záujemca dostane prístup do licitačného
systému   na   webovej   stránke   sprostredkovateľa   až   po   pripísaní   zálohy   na
bankový   účet   podľa   platobných   podmienok   uvedených   v podmienkach
licitácie.   Po   skončení   licitácie   bude   všetkým   neúspešným   záujemcom
o licitovanú   nehnuteľnosť   záloha   vrátená   v plnej   výške   najneskôr   do   5
pracovných dní od skončenia licitácie a v prípade úspešného záujemcu (ďalej
aj „víťaz licitácie“), ktorý počas trvania licitácie ponúkol najvyššiu ponukovú
cenu za licitovanú  nehnuteľnosť (ďalej len „kúpna cena“) sa zložená záloha
zaráta na celkovú kúpnu cenu za licitovanú nehnuteľnosť.

4.6  Každá ponuková cena počas licitácie musí byť vyššia ako aktuálna cena
za   licitovanú   nehnuteľnosť,   okrem   prvej   ponukovej   ceny,   ktorá   môže   byť
totožná s vyvolávacou cenou za licitovanú nehnuteľnosť.

4.7 Víťaz licitácie je povinný zaplatiť celú výšku kúpnej ceny za licitovanú
nehnuteľnosť   alebo   rozdiel   medzi   ním   zloženou   zálohou   a kúpnou   cenou.
Skončením licitácie sa najvyššia ponuková cena považuje za kúpnu cenu za
licitovanú nehnuteľnosť.

4.8 Po skončení licitácie si okrem kúpnej ceny ostatné podmienky prevodu
vlastníckeho   práva   k licitovanej   nehnuteľnosti   dohodne   vlastník   licitovanej
nehnuteľnosti   so   záujemcom   v zmluve   o uzatvorení   kúpnej   zmluvy   alebo
priamo v kúpnej zmluve.
4.9 Pokiaľ   nebolo   počas   doby   licitácie   uskutočnené   ani   jedno  podanie   na
licitovanú   nehnuteľnosť   alebo   nastali   iné   skutočnosti   kvôli,   ktorým   nedošlo
k uzatvoreniu   zmluvy   medzi   vlastníkom   licitovanej   nehnuteľnosti
a záujemcom za podmienok dohodnutých v týchto LP, je sprostredkovateľ po
dohode   s vlastníkom   nehnuteľnosti   oprávnený   licitáciu   opakovať,   pričom
podmienky licitácie, ako aj vyvolávacia cena sa môžu zmeniť.

## V. Záverečné ustanovenia

5.1 Sprostredkovateľ nezodpovedá za právne vzťahy, ktoré vzniknú medzi
vlastníkom   licitovanej   nehnuteľnosti   a záujemcom,   ani   za   plnenie,   práva
a povinnosti im vyplývajúce z týchto právnych vzťahov

5.2 Sprostredkovateľ  sprostredkúva  uzatvorenie   zmluvy  medzi  vlastníkom
licitovanej   nehnuteľnosti   a záujemcom   prostredníctvom   licitácie   na   webovej
stránke sprostredkovateľa a záujemca, vlastník licitovanej nehnuteľnosti, ani
tretie   osoby   nemajú   voči   sprostredkovateľovi   žiadny   právny   nárok   titulom
licitácie,   a to   najmä     požadovať   od   sprostredkovateľa   plnenie   súvisiace
s prejavom   vôle   tretej   osoby,   napr.   uzatvorenie   zmluvy   na   licitovanú
nehnuteľnosť s vlastníkom licitovanej nehnuteľnosti alebo záujemcom.

5.3 Sprostredkovateľ   nezodpovedá   za   konanie   a záväzky   vlastníka
licitovanej nehnuteľnosti, záujemcu alebo tretích osôb, ktoré prejavia záujem
o licitovanú nehnuteľnosť alebo o účasť na licitácii.

5.4 Licitácia   sa   nespravuje   ustanoveniami   právnych   predpisov
o dobrovoľných  dražbách  nehnuteľností. Všetky právne vzťahy, ktoré nie sú
upravené   v týchto   LP   sa   spravujú   ustanoveniami   Občianskeho   zákonníka
v platnom znení.

5.5 Záujemca   je   pred   tým,   ako   mu   je   umožnený   prístup   k licitácii
oboznámený   s týmito   LP   a zaväzuje   sa   ich   dodržovať,   inak   zodpovedá   za
škodu,   ktorá   porušením   týchto   LP   vznikne   sprostredkovateľovi   alebo   tretím
osobám.

5.6 Tieto   LP   sú   zverejnené   na   webovom   sídle   sprostredkovateľa   po
prihlásení   záujemcu   do   systému   licitácie   spolu   s Licitačnou   príručkou
a záujemca účasťou na licitácii súhlasí s nimi v celom rozsahu a vzťahujú sa
na   všetky   právne   vzťahy,  ktoré   vzniknú   medzi   záujemcom
a sprostredkovateľom pri licitácii nehnuteľnosti.  Záujemca účasťou na licitácii
súhlasí so spracovaní svojich osobných údajov v súlade s platnými právnymi
predpismi a pred účasťou na licitácii si prečítal celé znenie ochrany osobných
údajov,   ktoré   je   zverejnené   na   webovom   sídle   sprostredkovateľa   v časti
ochrana osobných údajov.

Tieto LP nadobúdajú účinnosť dňa 01.05.2022.
`;
