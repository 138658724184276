import { ContactForm } from '@brick/forms';
import { Box, BoxProps, SimpleGrid, Stack } from '@chakra-ui/react';
import { Map, MapProps } from '../../Elements/Map/Map';
import { SectionHeading } from '../../Elements/SectionHeading/SectionHeading';
import {
  SocialAndContactLinks,
  SocialAndContactLinksProps,
} from '../../Elements/SocialAndContactLinks/SocialAndContactLinks';
import { Container } from '../../Layout/Container/Container';
import { BillingInfo, BillingInfoProps } from './components/BillingInfo';

export interface ContactSectionProps extends BoxProps {
  socialAndContactLinks: SocialAndContactLinksProps;
  billingInfo: BillingInfoProps;
  map?: MapProps;
  submitMutation: any;
}

export const ContactSection = (props: ContactSectionProps) => {
  const {
    socialAndContactLinks,
    billingInfo,
    map,
    submitMutation,
    ...restOfProps
  } = props;
  return (
    <Box {...restOfProps}>
      <Container>
        <SimpleGrid
          columns={{ md: 2 }}
          gridGap={{ base: 10, md: 16, lg: 20, xl: 32 }}
        >
          <Stack spacing={{ base: 4, md: 6 }} order={{ base: 2, md: 0 }}>
            <SectionHeading
              title={'NAPÍŠTE NÁM'}
              textAlign="left"
              alignItems={'flex-start'}
              alignSelf="flex-start"
            />
            <ContactForm submitMutation={submitMutation} />
          </Stack>
          <Stack spacing={{ base: 10, md: 14 }}>
            <SocialAndContactLinks  {...socialAndContactLinks} />
            <BillingInfo {...billingInfo} />
          </Stack>
        </SimpleGrid>
      </Container>
      {map && <Map {...map} mt={{ base: 10, md: 16 }} />}
    </Box>
  );
};
