import { Button } from '@brick/ui-components';
import { Box, Flex, SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { GdprAgreement } from '../../../components/Agreements/GdprAgreement';
import { FormControlGroup } from '../../../components/FormControls/FormControlGroup';
import { errors, validation } from '../../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const RegisterForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitFormMutation,
  } = props;
  const methods = useForm<FormValues>({ mode: 'onBlur' });
  const toast = useToast();
  const { mutateAsync, isLoading } = submitFormMutation();

  const onSubmit = async (data: FormValues) => {
    const { gdprAgreement, confirmPassword, ...input } = data;

    // check password match
    if (input.password != confirmPassword) {
      methods.setError('confirmPassword', errors.passwordsDoNotMatch);
      return;
    }

    try {
      await mutateAsync({ ...input, username: input.email });
    } catch (e) {
      // @ts-ignore
      if (e.message === '') {
      }
      console.log(e);
      methods.setError('email', errors.userExists);
      return;
    }
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 4 }} maxW={'md'} mx="auto">
          <SimpleGrid
            columns={{ md: 1, lg: 1 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4, md: 2, lg: 4 }}
          >
            <FormControlGroup
              name="firstName"
              label={labels.firstName}
              placeholder={placeholders.firstName}
              validation={validation.required}
            />
            <FormControlGroup
              name="lastName"
              label={labels.lastName}
              placeholder={placeholders.lastName}
              validation={validation.required}
            />

            <FormControlGroup
              name="email"
              label={labels.email}
              placeholder={placeholders.email}
              validation={validation.email}
            />
            <FormControlGroup
              name="phone"
              label={labels.phone}
              placeholder={placeholders.phone}
              validation={validation.phone}
            />
            <FormControlGroup
              name="birthDate"
              label={labels.birthDate}
              placeholder={placeholders.birthDate}
              validation={validation.date}
            />
            <FormControlGroup
              name="password"
              type="password"
              label={labels.password}
              placeholder={placeholders.password}
              validation={validation.password}
            />
            <FormControlGroup
              name="confirmPassword"
              type="password"
              label={labels.confirmPassword}
              placeholder={placeholders.confirmPassword}
              validation={validation.required}
            />
          </SimpleGrid>
          <Stack>
            <GdprAgreement />
            <Flex justifyContent={'space-between'} alignItems="center">
              <Box></Box>
              <Button
                mt={1}
                type="submit"
                colorScheme={submitButton.colorScheme}
                isLoading={isLoading}
                loadingText={submitButton.loadingText}
                alignSelf={{ base: 'center', md: 'flex-end' }}
                w={{ base: 'full', sm: 'auto' }}
              >
                {submitButton.text}
              </Button>
            </Flex>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
