import { Box, BoxProps, HStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { TimerNumber } from './components/TimerNumber';
import { TimerSeparator } from './components/TimerSeparator';
import { getTimerItems, isTimerAboutToFinish } from './utils/timer.utils';

export interface TimerLabels {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const defaultTimerLabels = {
  days: 'Deň',
  hours: 'Hodina',
  minutes: 'Minúta',
  seconds: 'Sekunda',
};

export interface TimerProps extends BoxProps {
  expiryTime: Date;
  onExpire?: () => void;
  onTimerAboutToFinish?: () => void;
  aboutToFinishSec?: number;
  labels?: TimerLabels;
}

export const Timer = (props: TimerProps) => {
  const {
    expiryTime,
    onExpire,
    aboutToFinishSec,
    labels = defaultTimerLabels,
    onTimerAboutToFinish,
    ...restOfProps
  } = props;

  const timer = useTimer({
    expiryTimestamp: expiryTime,
    onExpire,
  });

  useEffect(() => {
    timer.restart(expiryTime, true);
  }, [expiryTime]);

  if (
    aboutToFinishSec &&
    onTimerAboutToFinish &&
    isTimerAboutToFinish(timer, aboutToFinishSec)
  ) {
    console.log(
      'TIMER ABOUT TO FINISH',
      aboutToFinishSec,
      onTimerAboutToFinish
    );
    onTimerAboutToFinish();
  }

  const spacing = { base: 1.5, sm: 3, md: 4, xl: 5 };

  const timerItems = getTimerItems(timer, labels, aboutToFinishSec);
  return (
    <Box {...restOfProps}>
      <HStack spacing={spacing}>
        {timerItems.map((timerItem, i) => (
          <HStack spacing={spacing} key={i}>
            {i != 0 && <TimerSeparator />}
            <TimerNumber {...timerItem} />
          </HStack>
        ))}
      </HStack>
    </Box>
  );
};
