import { BoxProps, Center } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface PrintAndShareItemProps extends BoxProps {
  children: ReactNode;
}

export const PrintAndShareItem = (props: PrintAndShareItemProps) => {
  const { children, ...restOfProps } = props;
  return (
    <Center borderWidth={1} h={14} borderColor="grey.500" {...restOfProps}>
      {children}
    </Center>
  );
};
