import { UseToastOptions } from '@chakra-ui/react';
import { FormSubmitButton, FormTexts, FormValues } from '../model/form-model';

export const defaultLabels: FormTexts = {
  propertyValue: 'Hodnota nehnuteľnosti (€)',
  amount: 'Výška hypotéky (€)',
  dueYears: 'Splatnosť hypotéky (roky)',
  interest: 'Úroková sadzba (%)',
  fees: 'Jednorázové poplatky (€)',
  fixationPeriod: 'Fixačné obdobie (roky)',
};

export const defaultPlaceholders: FormTexts = {
  propertyValue: 'Hodnota nehnuteľnosti (€)',
  amount: 'Výška hypotéky (€)',
  dueYears: 'Splatnosť hypotéky (roky)',
  interest: 'Úroková sadzba (%)',
  fees: 'Jednorázové poplatky (€)',
  fixationPeriod: 'Fixačné obdobie (roky)',
};

export const defaultValues: FormValues = {
  propertyValue: 100000,
  amount: 80000,
  dueYears: 20,
  interest: '1.9',
  fees: 400,
  fixationPeriod: 5,
};

export const defaultSuccessMessage: UseToastOptions = {
  title: 'Vypočítané!',
  // description: '',
  status: 'success',
  duration: 3000,
  position: 'bottom-left',
  isClosable: true,
};

export const defaultSubmitButton: FormSubmitButton = {
  text: 'Vypočítať',
  loadingText: 'Prepočítavam',
  colorScheme: 'black',
};
