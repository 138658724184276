import { useUser } from 'apps/client/components/Licitation/Providers/UserProvider';
import {
  ADMIN_ROLE_ID,
  AGENT_ROLE_ID,
} from '../../licitation/const/licitation.const';

export const useIsCurrentUserAgent = () => {
  const user = useUser();
  // admin is also an agent
  return user?.role.id === AGENT_ROLE_ID || user?.role.id === ADMIN_ROLE_ID;
};
