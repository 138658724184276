import { BoxProps, Flex } from '@chakra-ui/react';

export type TimerNoteType = 'info' | 'danger' | 'success';

export interface TimerNoteProps extends BoxProps {
  type: TimerNoteType;
  text: string;
}

const typeBgMap = {
  info: 'primary.500',
  danger: 'red.500',
  success: 'green.500',
};

const typeColorMap = {
  info: 'black.500',
  danger: 'white',
  success: 'whtie',
};

export const TimerNote = (props: TimerNoteProps) => {
  const { type, text, ...restOfProps } = props;
  return (
    <Flex
      flexDir={'column'}
      justifyContent="center"
      {...restOfProps}
      bg={typeBgMap[type]}
      color={typeColorMap[type]}
      px={{ base: 2, md: 4 }}
      h={{ base: 12, md: 8 }}
      textAlign="center"
      fontSize={{ base: 'xs', md: 'md' }}
      fontWeight={{ base: 400, md: 500 }}
    >
      {text}
    </Flex>
  );
};
