import { Button } from '@brick/ui-components';
import { Box, Flex, SimpleGrid, Stack, useToast } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, useForm } from 'react-hook-form';
import { GdprAgreement } from '../../components/Agreements/GdprAgreement';
import { FormControlGroup } from '../../components/FormControls/FormControlGroup';
import { FormControlGroupSelect } from '../../components/FormControls/FormControlGroupSelect';
import { FormControlGroupTextarea } from '../../components/FormControls/FormControlGroupTextarea';
import { validation } from '../../const/validation/validation';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
  defaultSuccessMessage,
} from './const/defaults';
import { FormProps, FormValues } from './model/form-model';

export const CareerForm = (props: FormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    submitMutation,
    districtOptions,
    experienceOptions,
    educationOptions,
    questions,
  } = props;

  const toast = useToast();
  const methods = useForm<FormValues>({});

  const { mutateAsync, isLoading } = submitMutation();

  const onSubmit = async (data: FormValues) => {
    let submissionInput = { ...data };
    submissionInput.questions = data.questions.map((q, i) => ({
      question: questions[i].question,
      answer: q.answer,
    }));
    submissionInput.age = Number(submissionInput.age);
    await mutateAsync({ input: submissionInput });
    toast(defaultSuccessMessage);
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={{ base: 4, md: 6 }}>
          <SimpleGrid
            columns={{ sm: 2, lg: 4 }}
            columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
            rowGap={{ base: 4 }}
          >
            <FormControlGroup
              name="fullName"
              label={labels.fullName}
              placeholder={placeholders.fullName}
              validation={validation.required}
            />
            <FormControlGroup
              name="email"
              label={labels.email}
              placeholder={placeholders.email}
              validation={validation.email}
            />
            <FormControlGroup
              name="phone"
              label={labels.phone}
              placeholder={placeholders.phone}
              validation={validation.phone}
            />
            <FormControlGroupSelect
              options={districtOptions}
              name="district"
              label={labels.district}
              placeholder={placeholders.district}
              validation={validation.required}
            />
            <FormControlGroup
              name="birthDate"
              label={labels.birthDate}
              placeholder={placeholders.birthDate}
              validation={validation.required}
            />
            {/* <Box>
              <DatePicker
                selected={new Date()}
                onChange={(date) => console.log(date)}
              />
            </Box> */}
            <FormControlGroup
              name="age"
              label={labels.age}
              placeholder={placeholders.age}
              validation={validation.age}
            />
            <FormControlGroupSelect
              options={educationOptions}
              name="education"
              label={labels.education}
              placeholder={placeholders.education}
              validation={validation.required}
            />
            <FormControlGroupSelect
              options={experienceOptions}
              name="experience"
              label={labels.experience}
              placeholder={placeholders.experience}
              validation={validation.required}
            />
          </SimpleGrid>
          <Stack spacing={{ base: 4 }}>
            {questions.map((q, i) => {
              return (
                <FormControlGroupTextarea
                  name={`questions[${i}].answer`}
                  label={q.question}
                  placeholder={q.answer}
                  validation={validation.required}
                  key={i}
                />
              );
            })}
          </Stack>

          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            gridGap={{ base: 4, xl: 7 }}
            alignItems={'center'}
          >
            <Box order={{ base: 1, md: 0 }}>
              <Button
                type="submit"
                colorScheme={submitButton.colorScheme}
                isLoading={isLoading}
                loadingText={submitButton.loadingText}
                alignSelf={{ base: 'center', md: 'flex-end' }}
              >
                {submitButton.text}
              </Button>
            </Box>
            <GdprAgreement />
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  );
};
