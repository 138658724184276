import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { ArrowLink } from '../../../Elements/ArrowLink/ArrowLink';
import { PropertyDetailItem } from './PropertyDetailItem';

export interface PropertyInstallmentInfoProps extends BoxProps {}

export const PropertyInstallmentInfo = (
  props: PropertyInstallmentInfoProps
) => {
  const { ...restOfProps } = props;

  const installmentInfoItems = [
    {
      label: 'Hypotéka:',
      value: '80% financovanie z kúpnej ceny',
    },
    {
      label: 'Vlastné zdroje:',
      value: ' 20% z kúpnej',
    },
    {
      label: 'Splatnosť:',
      value: ' 30 rokov',
    },
    {
      label: 'Úroková sadzba:',
      value: ' 2,5% p.a.',
    },
  ];

  return (
    <Box {...restOfProps}>
      <Stack>
        <Stack spacing={1}>
          {installmentInfoItems.map((i) => (
            <PropertyDetailItem {...i} key={i.value} fontSize={'sm'} />
          ))}
        </Stack>

        <Box alignSelf={'flex-end'} pt={1}>
          <ArrowLink
            buttonProps={{
              color: 'primary.500',
            }}
            label={'Upraviť parametre'}
            href={'/financny-servis#hypotekarna-kalkulacka'}
          />
        </Box>
      </Stack>
    </Box>
  );
};
