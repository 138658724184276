import { BoxProps, Center } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface CircleIconProps extends BoxProps {
  children: ReactNode;
}

export const CircleIcon = (props: CircleIconProps) => {
  const { children, ...restOfProps } = props;
  return (
    <Center h={7} w={7} rounded={'full'} border="2px" p={1} {...restOfProps}>
      {children}
    </Center>
  );
};
