import { Box, BoxProps, Flex } from '@chakra-ui/react';

export interface TimerSubtitleProps extends BoxProps {
  title?: string;
  value?: string;
}

export const TimerSubtitle = (props: TimerSubtitleProps) => {
  const { title, value, ...restOfProps } = props;

  return (
    <Box {...restOfProps}>
      <Flex gridGap={1} fontWeight={500} fontSize={{ base: 'sm', sm: 'md' }}>
        {title && <Box>{title}</Box>}
        {value && <Box color="grey.500">{value}</Box>}
      </Flex>
    </Box>
  );
};
