import { SocialLink } from '@brick/model';
import { Box, BoxProps, HStack } from '@chakra-ui/react';
import { FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { RiFacebookFill } from 'react-icons/ri';
import { IconButtonLink } from '../IconButtonLink/IconButtonLink';

export interface SocialIconsProps extends BoxProps {
  socialLinks: SocialLink[];
}

const socialTypeIconMap = {
  facebook: (
    <Box mb={0.5} mr={-1.5}>
      <RiFacebookFill />
    </Box>
  ),
  linkedin: <FaLinkedinIn />,
  youtube: (
    <Box mb={-0.5}>
      <FaYoutube />
    </Box>
  ),
  instagram: <FaInstagram />,
  mail: <FiMail />,
};

export const SocialIcons = (props: SocialIconsProps) => {
  const { socialLinks, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <HStack spacing={1.5}>
        {socialLinks.map((link) => (
          <IconButtonLink
            ariaLabel={link.type}
            key={link.type}
            external={link.external}
            href={link.href}
            buttonProps={{
              icon: socialTypeIconMap[link.type],
            }}
          />
        ))}
      </HStack>
    </Box>
  );
};
